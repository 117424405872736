import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem, Breadcrumb } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Employees.css";
import { API, Storage } from "aws-amplify";
import EnhancedTable from "./EnhancedTable";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import UserCard from "./UserCard";


import loaderIcon from'../images/qkeyz.gif';





const rowHeaders = [
  { id: 'photo', numeric: false, center: true, disablePadding: true, label: '' },
  { id: 'name', numeric: false, center: false, disablePadding: true, label: 'Name' },
  { id: 'email', numeric: false, center: false, disablePadding: true, label: 'Email' },
  { id: 'contactphone', numeric: false, center: false, disablePadding: true, label: 'Phone Number' },
  { id: 'admin', numeric: false, center: true, disablePadding: true, label: 'Administrator' },
  { id: 'reg', numeric: false, center: true, disablePadding: true, label: 'Registered' },
  { id: 'created', numeric: false, center: false, disablePadding: false, label: 'Created' },
  { id: 'updated', numeric: false, center: false, disablePadding: false, label: 'Updated' },
];

export default class Employees extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isDeleting: false,
      alertOpen: false,
      currentEmp: null,
      noDeleteAlertOpen: false,
      qwikView: false,
      employees: []
    };
  }
async componentDidMount() {
  //console.log(this.props);
  const isAdmin = this.props.userOrg.userRoles.indexOf('ADMIN') >= 0;
  if (!this.props.isAuthenticated || !this.props.org || !isAdmin) {
    this.props.history.push("/");
    return;
  }

  try {
    const eResult = await this.getOrgEmployees();
    const employees = eResult.Items;
    const rows = await this.getStructuredEmployeeList(employees);
    this.setState({ employees, rows });
  } catch (e) {
    alert(e);
  }

  this.setState({ isLoading: false });
}

async getStructuredEmployeeList(employees) {
  var rows = [];
  for (var i = 0; i < employees.length; ++i) {
    let e = employees[i];
    const {sk, binaryImage, userRoles, email, contactPhone, created, updated} = e;
    const isRegistered = sk.indexOf(email) == -1;
    const name = e.firstName + ' ' + e.lastName;
    let admin = false;
    if (userRoles.indexOf('ADMIN') >= 0) {
        admin = true;
    }
    let number = '';
    if (contactPhone) {
      number = contactPhone;
    }
    let rowCols = [
      { id: 'photo', numeric: false, center: true, disablePadding: true, value: binaryImage, type: 'image'},
      { id: 'name', numeric: false, center: false, disablePadding: true, value: name, type: 'text' },
      { id: 'email', numeric: false, center: false, disablePadding: true, value: email, type: 'text' },
      { id: 'contactphone', numeric: false, center: false, disablePadding: true, value: number, type: 'text' },
      { id: 'admin', numeric: false, center: true, disablePadding: false, value: admin, type: 'icon_check' },
      { id: 'reg', numeric: false, center: true, disablePadding: false, value: isRegistered, type: 'icon_check' },
      { id: 'created', numeric: false, center: false, disablePadding: false, value: (new Date(created)).toLocaleString(), type: 'date' },
      { id: 'updated', numeric: false, center: false, disablePadding: false, value: (new Date(updated)).toLocaleString(), type: 'date' },
    ];
    rows.push({id: i + 1, photo: binaryImage, name: name , email: email, contactphone: number, admin: admin, created, updated, key: e.sk, cols: rowCols})
  }
  return rows
}


getOrgEmployees() {
    return API.get("qkeyz", `/organisations/${this.props.org.pk}/EMP`);
}
deleteEmployees() {
  //console.log(this.state);
  const idList = this.state.selected;
  if (idList.length == 1) {
    const body = {ids: idList};
    //const body = {empId: idList[0]};
    //console.log(body);
    //return API.del("qkeyz", `/organisations/${this.props.org.pk}/users`, {body: body});
    return API.del("qkeyz", `/organisations/${this.props.org.pk}/EMP`, {body: body});
  }
}

handleCrumbClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}

getEmployeeIdFromSk(sk) {
  return sk.substr(4);
}

 renderEmployeesList(employees) {
  return [{}].concat(employees).map(
    (employee, i) =>
      i !== 0
        ? <ListGroupItem
            key={employee.sk}
            href={`/employees/${this.getEmployeeIdFromSk(employee.sk)}`}
            onClick={this.handleEmployeeClick}
            header={employee.sk}
          >
            {"Created: " + new Date(employee.created).toLocaleString()}
          </ListGroupItem>
        : <ListGroupItem
            key="new"
            href={"/employees/new"}
            onClick={this.handleEmployeeClick}
          >
            <h4>
              <b>{"\uFF0B"}</b> New Employee
            </h4>
          </ListGroupItem>
  );
}
handleAlertClose = () => {
    this.setState({ alertOpen: false, noDeleteAlertOpen: false, qwikView: false});
};
handleEmployeeClick = (event, employeeSk) => {
  event.preventDefault();
  const user = this.state.employees.find(o => o.sk === employeeSk);
  this.setState({
    currentEmp: user,
    qwikView: true
  });
}
handleEmployeeEditClick = (event, selected) => {
  event.preventDefault();
  console.log(selected);
  this.props.history.push({
  pathname: '/employees/edit',
  state: { employeeId: this.getEmployeeIdFromSk(selected[0]) }
  });
}
handleCreateEmployeeClick = event => {
  event.preventDefault();
  this.props.history.push("/employees/new");
}
handleDeleteEmployeeClick = async (event, selected) => {
  event.preventDefault();
  let count = selected.length;
  if (count > 0) {
    let eId = this.getEmployeeIdFromSk(selected[0]);
    if (!eId.includes('@')) {
      this.setState({
        alertOpen: false,
        noDeleteAlertOpen: true,
      });
    } else {
      this.setState({
        alertOpen: true,
        noDeleteAlertOpen: false,
        selected: selected
      });
    }
  }
}
 handleDeleteEmployees = async (event) => {
   console.log(this.state);
   console.log('LEN0: ', this.state.rows.length);
  this.setState({ isDeleting: true, alertOpen: false });
  console.log('aaa');
  let isSuccess = false
  try {
    const result = await this.deleteEmployees();
    console.log(result);
    isSuccess = true;
  } catch (e) {
    alert(e);
  }

  console.log('ddd');
  const employees = await this.getOrgEmployees();
  const rows = await this.getStructuredEmployeeList(employees);
  console.log('LEN: ', rows.length);
  this.setState({ isDeleting: false, employees: employees, rows: rows });
}

/*
<MenuItem disabled={numSelected > 0} onClick={handleCreateItemClick}>Create new...</MenuItem>
<MenuItem disabled={numSelected != 1} onClick={event => handleEditItemClick(event, selected)}>Edit...</MenuItem>
<MenuItem disabled={numSelected == 0} onClick={event => actionsItemClickEvent(event, selected, handleDeleteItemClick)}>Delete</MenuItem>
*/


/*
async deleteProjects() {
  console.log('bbb');
  try {
    await this.deleteProjects();
  } catch (e) {
    alert(e);
  }
  console.log('ccc');

}
*/
renderLander() {
    return (
      <div className="lander">
        <h1>Team</h1>
        <p>Add your first employee here</p>
          <LoaderButton
            bsStyle="primary"
            bsSize="large"
            isLoading={this.state.isLoading}
            onClick={this.handleCreateEmployeeClick}
            text="Create Employee"
            loadingText="Create Employee"
          />


      </div>
    );
}


/*

      <div className="items">
        <PageHeader>{this.props.org ? this.props.org.orgName + ':' : 'My'} Projects</PageHeader>

*/
/*

handleEditItemClick={this.handleProjectEditClick}
handleCreateItemClick={this.handleCreateProjectClick}
handleDeleteItemClick={this.handleDeleteProjectClick}



*/

renderEmployees() {
  const menuItems = [
    { id: 0, label: 'Create new...', lt: Number.MAX_VALUE, gt: 0, isOrOperator: false, action: this.handleCreateEmployeeClick, divider: false, type: 'create'},
    { id: 1, label: 'Edit...', lt: 1, gt: 1, isOrOperator: true, action: this.handleEmployeeEditClick, divider: false, type: 'edit' },
    { id: 2, label: 'Delete', lt: 1, gt: 1, isOrOperator: true, action: this.handleDeleteEmployeeClick, divider: false, type: 'delete' }
  ];
    return (
        <div>
          {!this.state.isLoading && <EnhancedTable
                                        logosKey={this.state.key}
                                        title='Employees'
                                        tooltip=''
                                        items={this.state.rows}
                                        menuItems={menuItems}
                                        handleItemClick={this.handleEmployeeClick}
                                        rowHeaders={rowHeaders}
                                        orderBy='name'
                                        order='asc'
                                        />}
          </div>
    );
  }

  renderLoader() {
    const label = this.state.isDeleting ? 'deleting...' : 'loading...';
    return(

      <div id="splash" style={{paddingTop: 250, zIndex:999}}>

    		<center id="splash">
    			<img src={loaderIcon}/>
          <Typography style={{padding: '4px'}} variant="caption">{label}</Typography>
    		</center>
    	</div>



    )
  }

  render() {
    return (
    <div>
        <Breadcrumb className="breadcrumb" style={{top: 58, left: 66, right: 0, position: 'absolute', zIndex:999, backgroundColor: '#FFF'}}>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Employees</Breadcrumb.Item>
        </Breadcrumb>

      <div className="Employees" style={{position: 'absolute', left:80, right:80,top: 80, paddingBottom:50}}>
        {this.state.isLoading || this.state.isDeleting ? this.renderLoader() : this.state.employees.length > 0 ? this.renderEmployees() : this.renderLander()}
      </div>

      <Dialog
          open={this.state.alertOpen}
          onClose={this.handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Employee"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you wish to delete the selected employee? <br/><br/>This cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAlertClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.handleDeleteEmployees} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
            open={this.state.noDeleteAlertOpen}
            onClose={this.handleAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Delete Visitor"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This employee cannot be deleted as they have already registered. Only unregistered employees may be deleted. <br/><br/>You can disable the employees access by suspending their account.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleAlertClose} color="secondary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
              open={this.state.qwikView}
              onClose={this.handleAlertClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
              { this.state.currentEmp &&
              <UserCard
                registered={this.state.currentEmp.sk.indexOf(this.state.currentEmp.email) == -1}
                logo={this.state.currentEmp.binaryImage}
                title={this.state.currentEmp.firstName + " " + this.state.currentEmp.lastName}
                subtitle={this.state.currentEmp.userRoles.indexOf('ADMIN') >= 0 ? "Administrator" : "Employee"}
                info1={"email: " + this.state.currentEmp.email}
                info2={"phone: " + (this.state.currentEmp.contactPhone ? this.state.currentEmp.contactPhone : '')}
                notes={this.state.currentEmp.notesAdmin}
                created={"created by " + this.state.currentEmp.createdByName + " on " + (new Date(this.state.currentEmp.created)).toLocaleString()}
                updated={"last updated by " + this.state.currentEmp.updatedByName + " on " + (new Date(this.state.currentEmp.updated)).toLocaleString()}
                colour={'#FAFcFA'}
                />
              }
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleAlertClose} color="secondary">
                  Ok
                </Button>
              </DialogActions>
            </Dialog>

      </div>
    );
  }
}
