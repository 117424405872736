import uuid from "uuid";
import React, { Component } from "react";
import { Glyphicon, InputGroup, FormGroup, FormControl, ControlLabel , Breadcrumb } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./NewOrg.css";
import { API } from "aws-amplify";
import { s3UploadBlob } from "../libs/awsLib";
import Paper from '@material-ui/core/Paper';


export default class NewOrg extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      name: "",
      contactPhone: "",
      category: "",
      category_short: "UNI",
      categories: [{value: 'UNI', title: 'University'},
              {value: 'COM', title: 'Commercial'},
              {value: 'NGO', title: 'Non-Government Organisation'},
              {value: 'CHA', title: 'Charity'},
              {value: 'GOV', title: 'Government'}
            ],
      logo: null
    };
  }

  validateForm() {
    return this.state.name.length > 0 && this.state.category_short.length;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

/*
  handleFileChange = event => {
    this.file = event.target.files[0];
  }
  */

  handleFileChange = async event => {
    event.preventDefault();
    this.file = event.target.files[0];
    if (this.file && this.file.type.indexOf("image") == -1) {
      this.file = null;
      this.inputEl.value='';
      this.setState({
        alertOpen: true,
        alertTitle: 'Invalid Image Format',
        alertMessage: 'Please choose a valid image for the property logo.'
      });
    } else {
      if (this.file) {
        const base64 = await this.getReducedImageAsBase64(this);
        this.setState({ logo: base64});
      }
    }
  }

handleSubmit = async event => {
  event.preventDefault();

  if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
    alert("Please pick a file smaller than 5MB");
    return;
  }

  this.setState({ isLoading: true });

  try {
  //  const attachment = this.file
  //    ? await s3Upload(this.file)
  //    : null;

    const newOrgId = uuid.v1();
    /*
    let attachment = null;
    if (this.file) {
      const blob = await this.getReducedImageAsBlob(this);
      attachment = await s3UploadBlob(newOrgId, this.file, blob);
      console.log(attachment);
      console.log("***");
    }
*/


    let cat = this.state.categories.find(o => o.value === this.state.category_short);


    await this.createOrg({
      orgId: newOrgId,
      orgName: this.state.name,
      contactPhone: this.state.contactPhone,
      category: cat.title,
      category_short: this.state.category_short,
      binaryImage: this.state.logo ? this.state.logo : null
    });
    this.props.history.push("/organisations");
  } catch (e) {
    alert(e);
    this.setState({ isLoading: false });
  }
}
getReducedImageAsBase64(context) {
  var reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(null);
    };
    reader.onload = () => {
      var img = new Image();
      img.onload = function() {
          console.log('IMG: ', img.height, img.width);
          let h = 200;
          let w = 200;
          if (img.height > img.width) {
            w = w * (img.width / img.height);
          } else {
            h = h * (img.height / img.width);
          }
          var oc = document.createElement('canvas');
          oc.width = w;
          oc.height = h;
          var ctx = oc.getContext('2d');
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, w, h);
          ctx.drawImage(img, 0, 0, w, h);
          var dataurl = oc.toDataURL('image/jpeg'); //context.file.type
          resolve(dataurl);
        }
      img.src = reader.result
      };
    reader.readAsDataURL(context.file);
  });
}

/*
getReducedImageAsBlob(context) {
  var reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(null);
    };
    reader.onload = () => {
      var img = new Image();
      img.onload = function() {
          console.log('IMG: ', img.height, img.width);
          let h = 200;
          let w = 200;
          if (img.height > img.width) {
            w = w * (img.width / img.height);
          } else {
            h = h * (img.height / img.width);
          }
          var oc = document.createElement('canvas');
          oc.width = w;
          oc.height = h;
          oc.getContext('2d').drawImage(img, 0, 0, w, h);
          var dataurl = oc.toDataURL(context.file.type);
          //console.log(dataurl);
          var blob = context.dataURItoBlob(dataurl, context.file.type);
          resolve(blob);
        }
      img.src = reader.result
      };
    reader.readAsDataURL(context.file);
  });
}

dataURItoBlob(dataURI, fileType) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: fileType});
}
*/

createOrg(org) {
  org.userName = this.props.user.firstName + " " + this.props.user.lastName;
  return API.post("qkeyz", "/organisations", {
    body: org
  });
}
handleCrumbClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}
  render() {
    return (
      <div className="NewOrg">
        <Breadcrumb>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/account">Account</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/organisations">Organisations</Breadcrumb.Item>
          <Breadcrumb.Item active>Add New</Breadcrumb.Item>
        </Breadcrumb>
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="name">
          <ControlLabel>Organisation Name</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.name}
              componentClass="input"
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="category_short">
          <ControlLabel>Category</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="category"
              onChange={this.handleChange}
              value={this.state.category_short}
              >
                {
                  this.state.categories.map((cat, index) => {
                    return (<option key={index} value={cat.value}>{cat.title}</option>)
                  })
                }

            </FormControl>


          </FormGroup>

          <FormGroup controlId="contactPhone">
          <ControlLabel>Contact Phone Number</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.contactPhone}
              componentClass="input"
              type="text"
            />
          </FormGroup>


          <FormGroup>
            <ControlLabel>Organisation Logo</ControlLabel>
          </FormGroup>
          <Paper className={"recList"}>
          <FormGroup>
            {this.state.logo &&
            <FormControl.Static >
              <img  src={this.state.logo} style={{maxWidth: '400px'}} height='80px' alt="Visitor photo"/>
            </FormControl.Static>
            }
          </FormGroup>

                   <FormGroup controlId="file">
                   <InputGroup>
                   <label htmlFor='file_input' className='label_as_button' ><Glyphicon glyph="folder-open" /> &nbsp;Choose logo</label>
                     <FormControl id='file_input' inputRef={ el => this.inputEl=el } accept="image/*" onChange={this.handleFileChange} type="file" style={{display: 'none'}}/>
                       </InputGroup>







                       </FormGroup>



          </Paper>






          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
        </form>
      </div>
    );
  }
}
