import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem, Breadcrumb } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Jobs.css";
import { API, Storage } from "aws-amplify";
import EnhancedTable from "./EnhancedTable";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';


import loaderIcon from'../images/qkeyz.gif';





const rowHeaders = [
  { id: 'jobid', numeric: false, center: false, disablePadding: true, label: 'Job ID' },
  { id: 'property', numeric: false, center: false, disablePadding: true, label: 'Property' },
  { id: 'locus', numeric: false, center: false, disablePadding: true, label: 'Locus' },
  { id: 'category', numeric: false, center: false, disablePadding: true, label: 'Category' },
  { id: 'description', numeric: false, center: false, disablePadding: false, label: 'Details' },
  { id: 'visitor', numeric: false, center: false, disablePadding: true, label: 'Contractor / Visitor' },
  { id: 'feedback', numeric: false, center: true, disablePadding: true, label: 'Feedback Present' },
  { id: 'startdate', numeric: false, center: false, disablePadding: false, label: 'Start Date' },
  { id: 'enddate', numeric: false, center: false, disablePadding: false, label: 'End Date' },
  { id: 'status', numeric: false, center: false, disablePadding: false, label: 'Status' },
];

export default class Jobs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isDeleting: false,
      alertOpen: false,
      jobs: []
    };
  }
async componentDidMount() {
  //console.log(this.props);
  if (!this.props.isAuthenticated || !this.props.org) {
    this.props.history.push("/");
    return;
  }

  try {
    const result = await this.getOrgJobs();
    const jobs = result.Items;
    const rows = await this.getStructuredJobList(jobs);


    console.log("JOBS");
    console.log(jobs);

/*
    var key = '';
    if (url) {
      let pos = url.indexOf('?AWSAccessKeyId');
      key = url.substring(pos);
    }
*/


    this.setState({ jobs, rows });
  } catch (e) {
    alert(e);
  }

  this.setState({ isLoading: false });
}

async getStructuredJobList(jobs) {
  var rows = [];
  for (var i = 0; i < jobs.length; ++i) {
    let j = jobs[i];
    const {photos, recordings, notesContractor, code, locus, title, description, category, categoryDesc, firstName, lastName, orgName, keyIds, startDate, endDate, currentStatus} = j;
    let feedback = false;
    if ((photos && photos.length > 0) || (recordings && recordings.length > 0) || (notesContractor && notesContractor.length > 0)) {
      feedback = true;
    }


    const keys = keyIds;
    let keyCount = 0;
    if (keys) {
      keyCount = keys.length;
    }
    let visitor = 'unassigned';
    if (firstName || lastName) {
      visitor = firstName + " " + lastName;
      if (orgName) {
        visitor += " (" + orgName + ")"
      }
    }
    let start = '';
    let startOrder = '';
    if (startDate) {
      start = (new Date(startDate)).toLocaleString();
      startOrder = startDate;
    }
    let end = '';
    let endOrder = '';
    if (endDate) {
      end = (new Date(endDate)).toLocaleString();
      endOrder = endDate;
    }
    let status = "";
    if (currentStatus) {
      status = currentStatus;
    }


    let rowCols = [
      { id: 'jobid', numeric: false, center: false, disablePadding: true, value: code, type: 'text' },
      { id: 'property', numeric: false, center: false, disablePadding: true, value: title, type: 'text' },
      { id: 'locus', numeric: false, center: false, disablePadding: true, value: locus, type: 'text' },
      { id: 'category', numeric: false, center: false, disablePadding: true, value: categoryDesc, type: 'text' },
      { id: 'description', numeric: false, center: false, disablePadding: false, value: description, type: 'text' },
      { id: 'visitor', numeric: false, center: false, disablePadding: true, value: visitor, type: 'text' },
      { id: 'feedback', numeric: false, center: true, disablePadding: false, value: feedback, type: 'icon_check' },
      { id: 'startdate', numeric: false, center: false, disablePadding: false, value: start, type: 'date' },
      { id: 'enddate', numeric: false, center: false, disablePadding: false, value: end, type: 'date' },
      { id: 'status', numeric: false, center: false, disablePadding: false, value: status, type: 'text' },
    ];
    rows.push({id: i + 1, jobid: code, property: title, locus, category: categoryDesc, description, visitor, feedbackj: keyCount, startdate: startOrder, enddate: endOrder, status, key: j.sk, cols: rowCols})
  }
  return rows
}


getOrgJobs() {
  //const body = {entity: 'OBS'};
  //return API.get("qkeyz", `/organisations/${this.props.org.pk}/JOB`);
  return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|JOB|NIL?asc=false&arch=false`);
}
deleteJobs() {
  //console.log(this.state);
  const idList = this.state.selected;
  if (idList.length > 0) {
    const body = {ids: idList};
    console.log(body);
    return API.del("qkeyz", `/organisations/${this.props.org.pk}/JOB`, {body: body});
  }
}

handleCrumbClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}

getJobIdFromSk(sk) {
  return sk.substr(12);
}

 renderJobsList(jobs) {
  return [{}].concat(jobs).map(
    (job, i) =>
      i !== 0
        ? <ListGroupItem
            key={job.sk}
            href={`/jobs/${this.getJobIdFromSk(job.sk)}`}
            onClick={this.handleJobClick}
            header={job.sk}
          >
            {"Created: " + new Date(job.created).toLocaleString()}
          </ListGroupItem>
        : <ListGroupItem
            key="new"
            href={"/jobs/new"}
            onClick={this.handleJobClick}
          >
            <h4>
              <b>{"\uFF0B"}</b> New Job
            </h4>
          </ListGroupItem>
  );
}
handleAlertClose = () => {
    this.setState({ alertOpen: false });
};
handleJobClick = (event, jobSk) => {
  event.preventDefault();
  this.props.history.push({
    pathname: '/jobs/edit',
    state: { viewOnly: true, jobId: this.getJobIdFromSk(jobSk) }
  });
}
handleJobEditClick = (event, selected) => {
  event.preventDefault();
  this.props.history.push({
  pathname: '/jobs/edit',
  state: { viewOnly: false, jobId: this.getJobIdFromSk(selected[0]) }
  });
}
handleCreateJobClick = event => {
  event.preventDefault();
  this.props.history.push("/jobs/new");
}
handleDeleteJobClick = async (event, selected) => {
  event.preventDefault();
  let count = selected.length;
  if (count > 0) {
    this.setState({
      alertOpen: true,
      selected: selected
    });
  }
}
 handleDeleteJobs = async (event) => {
   console.log(this.state);
   console.log('LEN0: ', this.state.rows.length);
  this.setState({ isDeleting: true, alertOpen: false });
  console.log('aaa');
  let isSuccess = false
  try {
    const result = await this.deleteJobs();
    console.log(result);
    isSuccess = true;
  } catch (e) {
    alert(e);
  }

  console.log('ddd');
  const jResult = await this.getOrgJobs();
  const jobs = jResult.Items;
  const rows = await this.getStructuredJobList(jobs);
  console.log('LEN: ', rows.length);
  this.setState({ isDeleting: false, jobs: jobs, rows: rows });
}





/*
<MenuItem disabled={numSelected > 0} onClick={handleCreateItemClick}>Create new...</MenuItem>
<MenuItem disabled={numSelected != 1} onClick={event => handleEditItemClick(event, selected)}>Edit...</MenuItem>
<MenuItem disabled={numSelected == 0} onClick={event => actionsItemClickEvent(event, selected, handleDeleteItemClick)}>Delete</MenuItem>
*/


/*
async deleteProjects() {
  console.log('bbb');
  try {
    await this.deleteProjects();
  } catch (e) {
    alert(e);
  }
  console.log('ccc');

}
*/
renderLander() {
    return (
      <div className="lander">
        <h1>Jobs</h1>
        <p>Create your first job here</p>
          <LoaderButton
            bsStyle="primary"
            bsSize="large"
            isLoading={this.state.isLoading}
            onClick={this.handleCreateJobClick}
            text="Create Job"
            loadingText="Create job"
          />


      </div>
    );
}


/*

      <div className="items">
        <PageHeader>{this.props.org ? this.props.org.orgName + ':' : 'My'} Projects</PageHeader>

*/
/*

handleEditItemClick={this.handleProjectEditClick}
handleCreateItemClick={this.handleCreateProjectClick}
handleDeleteItemClick={this.handleDeleteProjectClick}



*/

renderJobs() {
  const menuItems = [
    { id: 0, label: 'Create new...', lt: Number.MAX_VALUE, gt: 0, isOrOperator: false, action: this.handleCreateJobClick, divider: false, type: 'create'},
    { id: 1, label: 'Edit...', lt: 1, gt: 1, isOrOperator: true, action: this.handleJobEditClick, divider: false, type: 'edit' },
    { id: 2, label: 'Delete', lt: 1, gt: -1, isOrOperator: false, action: this.handleDeleteJobClick, divider: false, type: 'delete' }
  ];
    return (
        <div>
          {!this.state.isLoading && <EnhancedTable
                                        logosKey={this.state.key}
                                        title='Jobs'
                                        tooltip=''
                                        items={this.state.rows}
                                        menuItems={menuItems}
                                        handleItemClick={this.handleJobClick}
                                        rowHeaders={rowHeaders}
                                        orderBy='title'
                                        order='asc'
                                        />}
          </div>
    );
  }

  renderLoader() {
    const label = this.state.isDeleting ? 'deleting...' : 'loading...';
    return(

      <div id="splash" style={{paddingTop: 250, zIndex:999}}>

    		<center id="splash">
    			<img src={loaderIcon}/>
          <Typography style={{padding: '4px'}} variant="caption">{label}</Typography>
    		</center>
    	</div>



    )
  }


  render() {
    //{this.state.isLoading || this.state.isDeleting ? this.renderLoader() : this.state.properties.length > 0 ? this.renderProperties() : this.renderLander()}
    return (
    <div>
        <Breadcrumb className="breadcrumb" style={{top: 58, left: 66, right: 0, position: 'absolute', zIndex:999, backgroundColor: '#FFF'}}>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Jobs</Breadcrumb.Item>
        </Breadcrumb>

      <div className="Jobs" style={{position: 'absolute', left:80, right:80,top: 80, paddingBottom:50}}>
        {this.state.isLoading || this.state.isDeleting || this.state.isSaving ? this.renderLoader() : this.state.jobs.length > 0 ? this.renderJobs() : this.renderLander()}
      </div>

      <Dialog
          open={this.state.alertOpen}
          onClose={this.handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Jobs"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you wish to delete the selected jobs? <br/><br/>This cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAlertClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.handleDeleteJobs} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}
