import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import PlaceIcon from '@material-ui/icons/PlaceRounded';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import Avatar from '@material-ui/core/Avatar';



const styles = theme => ({
  card: {
    display: 'flex',

  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    maxWidth: 300,
    height: 50,
    marginTop: 20,
    marginLeft: 20,
    marginBottom: 20,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

function renderLogo(logo, classes) {
  return(

    <CardMedia
      className={classes.cover}
      image={logo}
      title=""
    />


  )
}
function PropertyCard(props) {
  const { classes, theme, title, description, logo, maplink, contactName, contactNumber, onClick, colour} = props;
  return (
    <Card className={classes.card} style={{backgroundColor: colour}}>
    {logo &&
      <div className={classes.cover}>
      <img src={logo} height='52px'/>
    </div>
  }
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {description}
          </Typography>
        </CardContent>
        <div className={classes.controls}>

        </div>
      </div>



<Chip style={{position: 'absolute', right: '0px'}}
avatar={
        <Avatar>
          <PlaceIcon />
        </Avatar>
      }
label={maplink && maplink !== "" ? 'available' : 'not set'}
clickable={maplink && maplink !== "" ? true : false}
className={classes.chip}
color={maplink && maplink !== "" ? 'secondary' : 'default'}
onClick={onClick}
/>
<div style={{position: 'absolute', top: '70px', right: '8px'}}>
<Typography  style={{display: 'inline-block'}}  variant="body1" color="textSecondary">
  {'contact:'}
</Typography>
&nbsp;
<Typography  style={{display: 'inline-block'}}  variant="body1" color="textPrimary">
  {contactName && contactName !== "" ? contactName : "not set"}
</Typography>
&nbsp;&nbsp;
<Typography  style={{display: 'inline-block'}}  variant="body1" color="textSecondary">
  {'ph:'}
</Typography>
&nbsp;
<Typography  style={{display: 'inline-block'}}  variant="body1" color="textPrimary">
  {contactNumber && contactNumber !== "" ? contactNumber : "not set"}
</Typography>
</div>
    </Card>
  );
}

PropertyCard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PropertyCard);
