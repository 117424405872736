import React, { Component } from "react";
import { Glyphicon, InputGroup, Button, FormGroup, FormControl, ControlLabel, Breadcrumb } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./NewKey.css";
import { API, Storage } from "aws-amplify";
import { s3UploadBlob } from "../libs/awsLib";


//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import loaderIcon from'../images/qkeyz.gif';


export default class NewKey extends Component {
  constructor(props) {
    super(props);

//console.log(props);

    this.file = null;
    const isEditing = this.props.location.state && this.props.location.state.keyId;
    const property = this.props.location.state && this.props.location.state.property;
    this.state = {
      property: property,
      propertyId: this.getPropertyIdFromSk(property.sk),
      isEditing: false,
      mode: "new",
      isLoading: isEditing,
      isSaving: null,
      key: null,
      code: "",
      location: "",
      adminNotes: "",
      contractorNotes: "",
      alertOpen: false
    };
}

  async componentDidMount() {
    if (!this.props.isAuthenticated || !this.props.org) {
      this.props.history.push("/");
      return;
    }


    //var pId;
    if (this.props.location.state) {
      if (this.props.location.state.keyId) {
        this.kId = this.props.location.state.keyId;
        let mode = "edit";
        if (this.props.location.state.mode) {
          mode = this.props.location.state.mode;
        }

         try {
          const key = await this.getKey(this.kId);
          console.log(key);
          const { code, locus, notesAdmin, notesContractor } = key; //key[0];


          this.setState({
            isEditing: mode != "clone",
            mode: mode,
            isLoading: false,
            key: key, //key[0],
            code: code,
            location: locus,
            adminNotes: notesAdmin,
            contractorNotes: notesContractor
          });
        } catch (e) {
          console.log("ERR");
          alert(e);
        }


      }
    }




  }
  getKey(keyId) {
    console.log("GETKEY:" + keyId);
      return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|KEY|NIL/${keyId}`);
  }

  /*
  getKey(keyId) {
    console.log("GETKEY:" + keyId);
      return API.get("qkeyz", `/keys/${keyId}`);
  }
  */

  createKey(key) {
    key.id = "PRP|KEY|NIL|" + key.propertyId; // key id created in lambda function
    key.userName = this.props.user.firstName + " " + this.props.user.lastName;
    return API.post("qkeyz", "/items", {
      body: key
    });
  }
  /*
  createKey(key) {
    key.userName = this.props.user.firstName + " " + this.props.user.lastName;
    return API.post("qkeyz", "/keys", {
      body: key
    });
  }
  */

  updateKey(keyId, body) {
    try{
      body.userName = this.props.user.firstName + " " + this.props.user.lastName;
      return API.put("qkeyz", `/organisations/${this.props.org.pk}/items/PRP|KEY|NIL|${keyId}`, {body: body});
    } catch(e) {
      console.log(e);
    }
  }
  /*
  updateKey(keyId, body) {
    try{
      body.userName = this.props.user.firstName + " " + this.props.user.lastName;
      return API.put("qkeyz", `/organisations/${this.props.org.pk}/keys/${keyId}`, {body: body});
    } catch(e) {
      console.log(e);
    }
  }
  */

validateChangesOnly() {
  let result = this.validateForm() && (this.state.code != this.state.key.code || this.state.location != this.state.key.locus || this.state.adminNotes != this.state.key.notesAdmin|| this.state.contractorNotes != this.state.key.notesContractor);
  return result;
}

  validateForm() {

    const t = this.state.code.trim();
    const a = this.state.location.trim();

    return a.length > 0 && t.length > 0;

  }

handleReset = event => {
  event.preventDefault();
  this.setState({
    code: this.state.key.code,
    location: this.state.key.locus,
    adminNotes: this.state.key.notesAdmin,
    contractorNotes: this.state.key.notesContractor
  });


}


  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
/*
  handleFileChange = event => {
    this.file = event.target.files[0];
    if (this.file && this.file.type.indexOf("image") == -1) {
      this.file = null;
      this.inputEl.value='';
      this.setState({
        alertOpen: true,
        alertTitle: 'Invalid Image Format',
        alertMessage: 'Please choose a valid image for the property logo.'
      });
    } else {
      this.setState({

      });
    }
  }
*/

getPropertyIdFromSk(sk) {
  return sk.substr(8);
}

handleSubmit = async event => {
  event.preventDefault();

/*
  if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
    this.setState({
      alertOpen: true,
      alertTitle: 'Invalid Image File Size',
      alertMessage: 'Please choose a file smaller than 5MB.'
    });
    return;
  }
*/
  this.setState({ isSaving: true });

  try {
    console.log("+++");

    /*
    let attachment = null;
    if (this.file) {
      const blob = await this.getReducedImageAsBlob(this);
      let orgId = this.props.org.pk; //'';
      //if (this.props.org) {
      //  orgId = this.props.org.pk;
      //} else {
      //  orgId = this.props.user.pk;
      //}

      attachment = await s3UploadBlob(orgId, this.file, blob);
      console.log(attachment);
      console.log("***");
    }
*/



    //const attachment = this.file
    //  ? await this.uploadReducedImage(this, this.file)
    //  : null;
      console.log("---");
    if (this.state.isEditing) {
      console.log(this.kId);
      console.log(this.state.code.trim());
      console.log(this.state.location);
      console.log(this.state.adminNotes);
      console.log(this.state.contractorNotes);





      await this.updateKey(this.kId, {
        location: this.state.location,
        notesAdmin: this.state.adminNotes ? this.state.adminNotes : null,
        notesContractor: this.state.contractorNotes ? this.state.contractorNotes : null
      });
    } else {
      console.log(this.state.property);
      let result = await this.createKey({
        orgId: this.props.org.pk,
        propertyId: this.state.propertyId,
        title: this.state.property.title,
        code: this.state.code.trim(),
        location: this.state.location ,
        notesAdmin: this.state.adminNotes ? this.state.adminNotes : null,
        notesContractor: this.state.contractorNotes ? this.state.contractorNotes : null
      });

      if (result.status === 'failed') {
        if (result.reason === 'exists') {
          this.setState({
            alertOpen: true,
            alertTitle: 'Key Code Exists',
            alertMessage: 'The code you have entered has already been used for another key. Please enter a unique key code.',
            isSaving: false
          });
          return;
        }
      }


    }
    this.props.history.push({
      pathname: '/properties/property/keys',
      state: { propertyId: this.state.propertyId}
    });
  } catch (e) {
    console.log(e);
    this.setState({
      alertOpen: true,
      alertTitle: 'Error',
      alertMessage: e.message,
      isSaving: false
    });
  }
}




handleAlertClose = () => {
    this.setState({ alertOpen: false });
};
handleCrumbClick = event => {
  event.preventDefault();
  const href = event.currentTarget.getAttribute("href");







  if (href === "/properties/property/keys") {
    this.props.history.push({
      pathname: '/properties/property/keys',
      state: { propertyId: this.state.propertyId }
    });
  } else {
    this.props.history.push(href);
  }
}

renderFields() {
  return(
    <div className="NewKey" style={{position: 'absolute', left:80, right:80,top: 110}}>
       <form onSubmit={this.handleSubmit}>
         <FormGroup controlId="code">
         <ControlLabel>Key Code</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.code}
             componentClass="input"
             disabled={this.state.isEditing}
             type="text"
           />
         </FormGroup>


         <FormGroup controlId="location">
         <ControlLabel>Key Locus</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.location}
             componentClass="textarea"
           />
         </FormGroup>






         <FormGroup controlId="adminNotes">
         <ControlLabel>Notes for Admin</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.adminNotes}
             componentClass="textarea"
           />
         </FormGroup>
         <FormGroup controlId="contractorNotes">
         <ControlLabel>Notes for Contractor</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.contractorNotes}
             componentClass="textarea"
           />
         </FormGroup>



         {this.state.isEditing ?
           <div>
                     <LoaderButton

           bsStyle="primary"
           bsSize="large"
           disabled={!this.validateChangesOnly()}
           type="submit"
           isLoading={this.state.isSaving}
           text="Save"
           loadingText="Saving…"
         />

         <LoaderButton


           bsSize="large"
           disabled={!this.validateChangesOnly()}
           isLoading={false}
           text="Cancel"
           onClick={this.handleReset}
           loadingText="Cancelling…"
         />
         </div>
         :
         <LoaderButton
           bsStyle="primary"
           bsSize="large"
           disabled={!this.validateForm()}
           type="submit"
           isLoading={this.state.isSaving}
           text="Create"
           loadingText="Creating new key…"
         />}






       </form>
     </div>
  )
}
  renderLoader() {
    const label = 'loading...';
    return(

      <div id="splash" style={{paddingTop: 250, zIndex:999}}>

        <center id="splash">
          <img src={loaderIcon}/>
          <Typography style={{padding: '4px'}} variant="caption">{label}</Typography>
        </center>
      </div>



    )
  }


  render() {
    return (
      <div>
         <Breadcrumb style={{top: 58, left: 66, right: 0, position: 'fixed', zIndex:999, backgroundColor: '#FFF'}}>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/properties">Properties</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/properties/property/keys">{this.state.property.title}</Breadcrumb.Item>
          <Breadcrumb.Item active>{this.state.isLoading ? '' : this.state.key && this.state.key.code && this.state.mode == 'edit' ? 'edit key: ' + this.state.key.code : this.state.mode == 'clone' ? 'clone key: ' + this.state.key.code : 'create new key'}</Breadcrumb.Item>
        </Breadcrumb>

        {this.state.isLoading ? this.renderLoader() : this.renderFields()}


      <Dialog
          open={this.state.alertOpen}
          onClose={this.handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.state.alertTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.alertMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAlertClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}
