import React, { Component } from "react";
import { Glyphicon, InputGroup, Button, FormGroup, FormControl, ControlLabel, Breadcrumb } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./NewVisitor.css";
import { API } from "aws-amplify";
//import { s3UploadBlobAsJpeg, s3UploadBlob } from "../libs/awsLib";


//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';


import loaderIcon from'../images/qkeyz.gif';

import Webcam from "react-webcam";

import CameraIcon from '@material-ui/icons/CameraRounded';



export default class NewVisitor extends Component {
  setRef = webcam => {
    this.webcam = webcam;
  };
  constructor(props) {
    super(props);

console.log(props);

    this.file = null;
    const isEditing = this.props.location.state && this.props.location.state.visitorId;
    this.state = {
      isEditing: false,
      isLoading: isEditing,
      isSaving: null,
      visitor: null,
      email: "",
      firstName: "",
      lastName: "",
      category: "VIS_OTHER",
      categoryDesc: "",
      address: "",
      contactPhone: "",
      notesAdmin: "",
      orgName: "",
      categories: [{value: 'VIS_OTHER', title: 'Visitor - Other'},
              {value: 'CON_AIRCON', title: 'Contractor - Air Conditioning'},
              {value: 'CON_CLEAN', title: 'Contractor - Cleaner'},
              {value: 'CON_ELEC', title: 'Contractor - Electrical'},
              {value: 'CON_FIRE', title: 'Contractor - Fire Systems'},
              {value: 'CON_GROUND', title: 'Contractor - Groundsman'},
              {value: 'CON_PLUMB', title: 'Contractor - Plumber'},
              {value: 'CON_GENERAL', title: 'Contractor - Handyman'},
              {value: 'CON_PAINT', title: 'Contractor - Painter'},
              {value: 'CON_ROOF', title: 'Contractor - Roof Works'},
              {value: 'CON_DATA', title: 'Contractor - Data Works'},
              {value: 'CON_GLASS', title: 'Contractor - Glass Works'},
              {value: 'CON_OTHER', title: 'Contractor - Other'}
            ],

      status: "ACTIVE",
      binaryImage: null,
      alertOpen: false,
      cameraOpen: false
    };
}

  async componentDidMount() {
    if (!this.props.isAuthenticated || !this.props.org) {
      this.props.history.push("/");
      return;
    }
    if (this.props.location.state) {
      if (this.props.location.state.visitorId) {
        this.pId = this.props.location.state.visitorId

         try {
          const visitor = await this.getVisitor(this.pId);
          //console.log("^^^^^^^^^^^^^^^^^^^^^^^^");
          //console.log(vResult);
          //const visitor = vResult.Items;
          const { binaryImage, firstName, lastName, email, orgName, currentStatus, category, categoryDesc, address, contactPhone, notesAdmin } = visitor;
          this.setState({
            isEditing: true,
            isLoading: false,
            visitor,
            firstName: firstName,
            lastName: lastName,
            email: email,
            orgName: orgName,
            status: currentStatus,
            category: category,
            categoryDesc: categoryDesc,
            address: address,
            contactPhone: contactPhone,
            notesAdmin: notesAdmin,
            binaryImage: binaryImage

          });
        } catch (e) {
          alert(e);
        }
      }
    }
  }
  getVisitor(visitorId) {
    //return API.get("qkeyz", `/organisations/${this.props.org.pk}/visitors/${visitorId}`);
    return API.get("qkeyz", `/organisations/${this.props.org.pk}/VIS/${visitorId}`);
  }


  updateVisitor(visitorId, body) {
    try{
      body.userName = this.props.user.firstName + " " + this.props.user.lastName;
      return API.put("qkeyz", `/organisations/${this.props.org.pk}/visitors/${visitorId}`, {body: body});
    }catch(e) {
      console.log(e);
    }
  }

validateChangesOnly() {
  return this.validateForm() && (this.state.firstName !== this.state.visitor.firstName || this.state.lastName !== this.state.visitor.lastName || this.state.binaryImage != this.state.visitor.binaryImage || this.state.email !== this.state.visitor.email || this.state.category !== this.state.visitor.category || this.state.orgName !== this.state.visitor.orgName || this.state.address !== this.state.visitor.address || this.state.status !== this.state.visitor.currentStatus|| this.state.contactPhone !== this.state.visitor.contactPhone || this.state.notesAdmin !== this.state.visitor.notesAdmin);
}

  validateForm() {
    const t = this.state.firstName.trim();
    const a = this.state.lastName.trim();
    const e = this.state.email.trim();

    return a.length > 0 && t.length > 0 && e.length > 0;
  }

handleReset = event => {
  event.preventDefault();
  this.file = null;
  this.inputEl.value='';
  this.setState({
    firstName: this.state.visitor.firstName,
    lastName: this.state.visitor.lastName,
    email: this.state.visitor.email,
    orgName: this.state.visitor.orgName,
    category: this.state.visitor.category,
    categoryDesc: this.state.visitor.categoryDesc,
    status: this.state.visitor.currentStatus,
    address: this.state.visitor.address,
    contactPhone: this.state.visitor.contactPhone,
    notesAdmin: this.state.visitor.notesAdmin,
    binaryImage: this.state.visitor.binaryImage
  });


}


  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFileChange = async event => {
    event.preventDefault();
    this.file = event.target.files[0];
    if (this.file && this.file.type.indexOf("image") == -1) {
      this.file = null;
      this.inputEl.value='';
      this.setState({
        alertOpen: true,
        alertTitle: 'Invalid Image Format',
        alertMessage: 'Please choose a valid image for the visitor pic.'
      });
    } else {
      if (this.file) {
        const base64 = await this.getReducedImageAsBase64(this);
        console.log('base64:' + base64);
        this.setState({ binaryImage: base64});
      }
    }

  }

handleSubmit = async event => {
  event.preventDefault();

  if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
    this.setState({
      alertOpen: true,
      alertTitle: 'Invalid Image File Size',
      alertMessage: 'Please choose a file smaller than 5MB.'
    });
    return;
  }

  this.setState({ isSaving: true });

  try {
    /*
    console.log("+++");
    let attachment = null;
    let blob = null;
    let binaryImage = null;
    if (this.file) {
      blob = await this.getReducedImageAsBlob(this);

      //let orgId = this.props.org.pk;
      attachment = await s3UploadBlob('users', this.file, blob, 'photos');
      console.log(attachment);
      console.log("***");
    } else if (this.state.cameraImage && !this.state.photo) {
      blob = this.base64toBlob(this.state.cameraImage);
      binaryImage = this.state.cameraImage;

      console.log(this.state.cameraImage);

      //let orgId = this.props.org.pk;
      //attachment = await s3UploadBlobAsPng('users', blob, 'photos');
      attachment = await s3UploadBlobAsJpeg('users', blob, 'photos');
    }
    */


    let cat = this.state.categories.find(o => o.value === this.state.category);
    if (this.state.isEditing) {
      await this.updateVisitor(this.pId, {
        firstName: this.state.firstName.trim(),
        lastName: this.state.lastName.trim(),
        email: this.state.email.trim(),
        orgName: this.state.orgName ? this.state.orgName : null,
        status: this.state.status ? this.state.status : null,
        category: this.state.category ? this.state.category : null,
        categoryDesc: cat.title ? cat.title : null,
        address: this.state.address ? this.state.address : null,
        contactPhone: this.state.contactPhone ? this.state.contactPhone : null,
        notesAdmin: this.state.notesAdmin ? this.state.notesAdmin : null,
        binaryImage: this.state.binaryImage ? this.state.binaryImage : null
      });
      this.props.history.push("/visitors");
    } else {
      //console.log('attachment:');
      //console.log(attachment);
      let createResult = await this.createVisitor({
        orgId: this.props.org.pk ,
        entity: 'VIS',
        category: 'VIS',
        categoryDesc: 'Visitor',
        firstName: this.state.firstName.trim(),
        lastName: this.state.lastName.trim(),
        email: this.state.email.trim(),
        orgName: this.state.orgName ? this.state.orgName : null,
        status: this.state.status ? this.state.status : null,
        category: this.state.category ? this.state.category : null,
        categoryDesc: cat.title ? cat.title : null,
        address: this.state.address ? this.state.address : null,
        contactPhone: this.state.contactPhone ? this.state.contactPhone : null,
        notesAdmin: this.state.notesAdmin ? this.state.notesAdmin : null,
        binaryImage: this.state.binaryImage ? this.state.binaryImage : null
      });
      if (createResult.status == false && createResult.error == 'exists') {
        this.setState({
          alertOpen: true,
          alertTitle: 'User Exists',
          alertMessage: 'A user with this email already exists as a visitor.',
          isSaving: false
        });
      } else {
        this.props.history.push("/visitors");
      }
    }
  } catch (e) {
    this.setState({
      alertOpen: true,
      alertTitle: 'Error',
      alertMessage: e.message,
      isSaving: false
    });
  }
}

getReducedImageAsBase64(context) {
  var reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(null);
    };
    reader.onload = () => {
      var img = new Image();
      img.onload = function() {
          console.log('IMG: ', img.height, img.width);
          let h = 200;
          let w = 200;
          if (img.height > img.width) {
            w = w * (img.width / img.height);
          } else {
            h = h * (img.height / img.width);
          }
          var oc = document.createElement('canvas');
          oc.width = w;
          oc.height = h;
          var ctx = oc.getContext('2d');
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, w, h);
          ctx.drawImage(img, 0, 0, w, h);
          var dataurl = oc.toDataURL('image/jpeg'); //context.file.type
          resolve(dataurl);
        }
      img.src = reader.result
      };
    reader.readAsDataURL(context.file);
  });
}

/*
dataURItoBlob(dataURI, fileType) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: fileType});
}
*/

createVisitor(visitor) {
  visitor.userName = this.props.user.firstName + " " + this.props.user.lastName;
  return API.post("qkeyz", "/organisations/users", {
    body: visitor
  });
}

handleAlertClose = () => {
    this.setState({ alertOpen: false });
};
handleCameraClose = () => {
    this.setState({ cameraOpen: false });
};
handleCrumbClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}
loadMapLink = event => {
  let url = "https://www.google.com/maps";
  if (this.state.mapLink != "") {
    url = this.state.mapLink;
  }
  window.open(url, "_blank");
}
handleShowCamera = event => {
  event.preventDefault();
  this.setState({ cameraOpen: true });
}

handleCaptureImage = () => {
  const imageSrc = this.webcam.getScreenshot();
  this.setState({ binaryImage: imageSrc, cameraOpen: false});
  this.file = null;
  this.inputEl.value='';

};






renderFields() {
  return(
    <div className="NewVisitor" style={{position: 'absolute', left:80, right:80,top: 110}}>
       <form onSubmit={this.handleSubmit}>
       <FormGroup controlId="email">
       <ControlLabel>Email*</ControlLabel>
         <FormControl
           onChange={this.handleChange}
           value={this.state.email}
           disabled={this.state.isEditing}
           componentClass="input"
           type="text"
         />
       </FormGroup>
       <FormGroup controlId="firstName">
       <ControlLabel>First Name*</ControlLabel>
         <FormControl
           onChange={this.handleChange}
           value={this.state.firstName}
           componentClass="input"
           type="text"
         />
       </FormGroup>
       <FormGroup controlId="lastName">
       <ControlLabel>Last Name*</ControlLabel>
         <FormControl
           onChange={this.handleChange}
           value={this.state.lastName}
           componentClass="input"
           type="text"
         />
       </FormGroup>


       <FormGroup controlId="category">
       <ControlLabel>Category</ControlLabel>
         <FormControl
           componentClass="select"
           placeholder="category"
           onChange={this.handleChange}
           value={this.state.category}
           >
             {
               this.state.categories.map((cat, index) => {
                 return (<option key={index} value={cat.value}>{cat.title}</option>)
               })
             }

         </FormControl>


       </FormGroup>




       <FormGroup controlId="orgName">
       <ControlLabel>Company Name</ControlLabel>
         <FormControl
           onChange={this.handleChange}
           value={this.state.orgName}
           componentClass="input"
           type="text"
         />
       </FormGroup>

         <FormGroup controlId="address">
         <ControlLabel>Address</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.address}
             componentClass="textarea"
           />
         </FormGroup>

         <FormGroup controlId="contactPhone">
         <ControlLabel>Phone Number</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.contactPhone}
             componentClass="input"
             type="text"
           />
         </FormGroup>





<FormGroup>
  <ControlLabel>Visitor Photo</ControlLabel>
</FormGroup>
<Paper className={"recList"}>
<FormGroup>
  {this.state.binaryImage &&
  <FormControl.Static >
    <img  src={this.state.binaryImage} style={{maxWidth: '400px'}} height='80px' alt="Visitor photo"/>
  </FormControl.Static>
  }
</FormGroup>

         <FormGroup controlId="file">
         <InputGroup>
           <FormControl inputRef={ el => this.inputEl=el }  onChange={this.handleFileChange} type="file"/>
             </InputGroup>



         </FormGroup>
                  <FormGroup controlId="takePic">

                        <Button  onClick={this.handleShowCamera}><CameraIcon /> Take Pic</Button>

                  </FormGroup>


</Paper>











         <FormGroup controlId="notesAdmin">
         <ControlLabel>Notes</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.notesAdmin}
             componentClass="textarea"
           />
         </FormGroup>



         {this.state.isEditing ?
           <div>
                     <LoaderButton

           bsStyle="primary"
           bsSize="large"
           disabled={!this.validateChangesOnly()}
           type="submit"
           isLoading={this.state.isSaving}
           text="Save"
           loadingText="Saving…"
         />

         <LoaderButton


           bsSize="large"
           disabled={!this.validateChangesOnly()}
           isLoading={false}
           text="Cancel"
           onClick={this.handleReset}
           loadingText="Cancelling…"
         />
         </div>
         :
         <LoaderButton
           bsStyle="primary"
           bsSize="large"
           disabled={!this.validateForm()}
           type="submit"
           isLoading={this.state.isSaving}
           text="Create"
           loadingText="Creating new visitor…"
         />}






       </form>
     </div>
  )
}


renderCamera() {
  const videoConstraints = {
     width: 200,
     height: 200,
     facingMode: "user"
   };
  return(
    <div>
       <Webcam
         audio={false}
         height={200}
         ref={this.setRef}
         screenshotFormat="image/jpeg"
         width={200}
         videoConstraints={videoConstraints}
         />
     </div>
  );
}



  renderLoader() {
    const label = 'loading...';
    return(

      <div id="splash" style={{paddingTop: 250, zIndex:999}}>

        <center id="splash">
          <img src={loaderIcon}/>
          <Typography style={{padding: '4px'}} variant="caption">{label}</Typography>
        </center>
      </div>



    )
  }


  render() {
    let fullName = null;
    if (this.state.visitor && this.state.visitor.firstName && this.state.visitor.lastName) {
      fullName = this.state.visitor.firstName + " " + this.state.visitor.lastName;
    }

    return (
      <div>
         <Breadcrumb style={{top: 58, left: 66, right: 0, position: 'fixed', zIndex:999, backgroundColor: '#FFF'}}>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/visitors">Visitors</Breadcrumb.Item>
          <Breadcrumb.Item active>{this.state.isLoading ? '' : fullName ? 'Edit: ' + fullName : 'Create new'}</Breadcrumb.Item>
        </Breadcrumb>

        {this.state.isLoading ? this.renderLoader() : this.renderFields()}











        <Dialog
            open={this.state.alertOpen}
            onClose={this.handleAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{this.state.alertTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.alertMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleAlertClose} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>


          <Dialog
              open={this.state.cameraOpen}
              onClose={this.handleAlertClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Take Pic of Visitor</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description" >

                </DialogContentText>
                {this.state.cameraOpen ? this.renderCamera() : null}
              </DialogContent>
              <DialogActions>
              <Button onClick={this.handleCaptureImage} color="primary">
                Take Pic
              </Button>
              <Button onClick={this.handleCameraClose} color="secondary">
                Cancel
              </Button>
              </DialogActions>
            </Dialog>


      </div>

    );
  }
}
