import React from 'react';
import { Glyphicon } from "react-bootstrap";

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
//import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/DoneRounded';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import CloudDownloadIcon from '@material-ui/icons/CloudDownloadRounded';
import CreateIcon from '@material-ui/icons/AddRounded';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import EditIcon from '@material-ui/icons/CreateRounded';
import ViewListIcon from '@material-ui/icons/ViewListRounded';
import CloneIcon from '@material-ui/icons/FileCopyRounded';
import ViewQRCodes from '@material-ui/icons/LineStyleRounded';
import PrintIcon from '@material-ui/icons/PrintRounded';
import PDFIcon from '@material-ui/icons/PictureAsPdfRounded';
import ArchiveIcon from '@material-ui/icons/ArchiveRounded';

import TextField from "@material-ui/core/TextField";
import ClearIcon from '@material-ui/icons/ClearRounded';
import SearchIcon from '@material-ui/icons/SearchRounded';
import InputAdornment from '@material-ui/core/InputAdornment';


//import {Dropdown, Search } from 'semantic-ui-react';


let counter = 0;

function desc(a, b, orderBy) {
  //console.log(a,b, orderBy);
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  //console.log('stableSort: ', array.length);
  const stabilizedThis = array.map((el, index) => [el, index]);
  //console.log('stabilizedThis');
  //console.log(stabilizedThis);
  stabilizedThis.sort((a, b) => {
    //console.log(a,b);
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  //console.log('getSorting');
  //console.log(order, orderBy);
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
function getIcon(type) {
    //console.log(col.value);
    switch(type) {
      case 'delete':
        return (<DeleteIcon/> );
      case 'edit':
        return (<EditIcon/> );
      case 'create':
        return (<CreateIcon/> );
      case 'view':
        return (<ViewListIcon/> );
      case 'qrcodes':
        return (<Glyphicon style={{paddingLeft:'5px'}} glyph="qrcode"/>); //<ViewQRCodes/>
      case 'download':
        return (<CloudDownloadIcon/> );
      case 'clone':
        return (<CloneIcon/> );
      case 'log':
        return (<ViewListIcon/> );
      case 'print':
        return (<PrintIcon/> );
      case 'archive':
        return (<ArchiveIcon/>);
      case 'pdf':
        return (<PDFIcon/>);
      default:
        return (<DoneIcon/>);
    }

  }

   function handleDropDownChange(event, data) {
    console.log(event);
    //this.setState({
      //userId: data.value
    //});
  }


class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };




  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, rows} = this.props;
    //console.log("KFGYKHFGKHJFGKJHG");
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                style={row.center && row.id == 'title' ? {textAlign: 'center', width:'200px'} : row.id == 'title' ? {width:'200px'} : row.center ? {textAlign: 'center'} : {}}

              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel

                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },

});
/*


  <MenuItem disabled={numSelected > 0} onClick={handleCreateItemClick}>Create new...</MenuItem>
  <MenuItem disabled={numSelected != 1} onClick={event => handleEditItemClick(event, selected)}>Edit...</MenuItem>
  <MenuItem disabled={numSelected == 0} onClick={event => actionsItemClickEvent(event, selected, handleDeleteItemClick)}>Delete</MenuItem>
*/



let EnhancedTableToolbar = props => {
  const { menuItems, title, tooltip, numSelected, selected, classes, anchorEl, actionsClickEvent, actionsCloseEvent, actionsItemClickEvent, actionsSearchEvent} = props;

  let inputProps = {
    endAdornment: null,
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon color="primary"/>
      </InputAdornment>
    )  };
  if (props.searchText != "") {
    inputProps.endAdornment = (
      <InputAdornment position="end">
        <ClearIcon onClick={() => actionsSearchEvent("")}/>
      </InputAdornment>
    );
  }

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {title}{' '}

                      {menuItems.map(i => {
                        return(


                          <React.Fragment key={i.id}>
                          {!i.isOrOperator && !(numSelected < i.lt && numSelected > i.gt) ?

                          <Tooltip title={i.label} >
                            <IconButton color="primary" disabled={i.isOrOperator ? (numSelected < i.lt || numSelected > i.gt) : (numSelected < i.lt && numSelected > i.gt)} onClick={event => actionsItemClickEvent(event, selected, i.action)}>
                              {getIcon(i.type)}
                            </IconButton>
                          </Tooltip>

          : <></>}
          </React.Fragment>

                        )
                      })}

          </Typography>
        )}
      </div>


      <div className={classes.spacer} />
      {props.allowSearch &&
        <>
        <TextField

         id="outlined-size-small"
         size="small"


         value={props.searchText}
         InputProps={inputProps}
         onChange={(event) => actionsSearchEvent(event.target.value)}
         />


        <div className={classes.title}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        </>
      }

      <div className={classes.actions}>
          <Tooltip title={tooltip}>
            <Button variant="outlined" color="primary" className={classes.button} onClick={actionsClickEvent} aria-owns={anchorEl ? 'simple-menu' : null}
                  aria-haspopup="true">
                        Actions
                        <ArrowDownIcon/>
            </Button>
          </Tooltip>
         <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={actionsCloseEvent}
        >
        {menuItems.map(i => {

          return(
            <MenuItem divider={i.divider} key={i.id} disabled={i.isOrOperator ? (numSelected < i.lt || numSelected > i.gt) : (numSelected < i.lt && numSelected > i.gt)} onClick={event => actionsItemClickEvent(event, selected, i.action)}>


            <ListItemIcon style={{padding:'0px'}} className={classes.icon}>
                        {getIcon(i.type)}
                      </ListItemIcon>
                      <ListItemText style={{padding:'0px'}} classes={{ primary: classes.primary }} inset primary={i.label} />

</MenuItem>


          )
        })}
        </Menu>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,


  },
  tableWrapper: {
    overflowY: 'hidden',
    overflowX: 'scroll',
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  }
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    const { order, orderBy } = this.props;
    this.state = {
      order: order,
      orderBy: orderBy,
      selected: [],
      page: 0,
      rowsPerPage: 5,
      anchorEl: null,
      searchText: ""
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    //console.log(orderBy, order);

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: this.props.items.map(n => n.key) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    event.stopPropagation();
    //console.log('CHECK');
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleActionsMenuClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleMoreClick = event => {
    //this.setState({ anchorEl: event.currentTarget });
  };

  handleActionsMenuClose = () => {
    this.setState({ anchorEl: null });
  };
  handleActionsMenuItemClick = (event, selected, f) => {
    const sel = selected;
    f(event, sel);
    this.setState({ anchorEl: null, selected: []});
  };
  handleActionsSearchChange = (text) => {
    console.log(text);
    this.setState({ searchText: text});
    if (text == "") {

    } else {

    }
  };


getCell(col) {
  //console.log(col.value);
  switch(col.type) {
    case 'text':
      return col.value;
    case 'paragraph':
      return col.value;
    case 'date':
      //return (new Date(col.value)).toLocaleString();
      return col.value;
    case 'date_green':
      return (<p style={{color: "#1E8449"}}>{col.value}</p>);
    case 'date_red':
      return (<p style={{color: "#CB4335"}}><b>{col.value}</b></p>);
    case 'icon_check':
      return (col.value ? <DoneIcon/> : '');
    case 'image':
      if (col.value) {
        return (<img src={col.value} style={{maxWidth: "80px", paddingRight: "8px"}} height="30px" alt="Logo"/>);
      } else {
        return ('');
      }
    case 'large_image':
      if (col.value) {
        return (<img src={col.value} style={{maxWidth: "160px", paddingRight: "8px", paddingTop: "4px", paddingBottom: "4px"}} height="90px" alt="Logo"/>);
      } else {
        return ('');
      }
  }

}


  render() {
    const { menuItems, title, tooltip, classes, handleItemClick, rowHeaders, items, type } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);
    const { anchorEl } = this.state;
    const more = this.props.more;
    const lcSearchText = this.state.searchText.toLowerCase();
    const FILTER_MAP = {
      none: () => true,
      propkeys: item => item.code.toLowerCase().includes(lcSearchText) || item.locus.toLowerCase().includes(lcSearchText),
      proplog: item => item.desc.toLowerCase().includes(lcSearchText) || item.event.toLowerCase().includes(lcSearchText)
    };

console.log(type);

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
            title={title}
            tooltip={tooltip}
            numSelected={selected.length}
            menuItems={menuItems}
            selected={selected}
            actionsClickEvent={this.handleActionsMenuClick}
            actionsCloseEvent={this.handleActionsMenuClose}
            actionsItemClickEvent={this.handleActionsMenuItemClick}
            actionsSearchEvent={this.handleActionsSearchChange}
            anchorEl={anchorEl}
            searchText={this.state.searchText}
            allowSearch={type !== undefined}
          />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={items.length}
              rows={rowHeaders}
            />
            <TableBody>
              {stableSort(items, getSorting(order, orderBy)).filter(FILTER_MAP[type === undefined ? "none" : type])
                .map(n => {
                  const isSelected = this.isSelected(n.key);
                  return (
                    <TableRow
                      hover
                      onClick={event => handleItemClick(event, n.key)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.key}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isSelected} onClick={event => this.handleClick(event, n.key)}/>
                      </TableCell>
                      {n.cols.map(c => {
                        return (
                          <TableCell
                            key={c.id}
                            align={c.numeric ? 'right' : 'left'}
                            padding={c.disablePadding ? 'none' : 'default'}
                            style={c.center && c.id == 'title' ? {textAlign: 'center', width:'200px'} : c.id == 'title' ? {width:'200px'} : c.center ? {textAlign: 'center'} : {}}
                          >
                          {c.type == 'paragraph' ? this.getCell(c).split('\n').map((item, key) => {
                         return <span key={key}>{item}<br/></span>
                       }) : this.getCell(c)}
                          </TableCell>);
                      })}


                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {more &&
        <div style={{position: "absolute", right: "0"}}>
        <Tooltip title='Get more records'>
          <Button variant="outlined" color="default" className={classes.button} onClick={event => handleItemClick(event, 'more')} >
                      More...
          </Button>
        </Tooltip>
        <br/><br/><br/>
        </div>
      }


      </Paper>
    );
  }
}
/*




                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
scope="row" component="th"

        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />

*/
EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);
