import React, { Component } from "react";
import {Glyphicon, PageHeader, ListGroup, FormGroup, ControlLabel, ListGroupItem, Breadcrumb, Table } from "react-bootstrap";
//import { FormGroup, FormControl, ControlLabel, ListGroup, ListGroupItem, Breadcrumb } from "react-bootstrap";

import LoaderButton from "../components/LoaderButton";
import "./Home.css";
import { API } from "aws-amplify";
//import FTCLogo from "../images/qkeyz_logo_q.png"; //qkeyz_logo_q.png
import FTCLogo from "../images/bg05.jpg"; //qkeyz_logo_q.png
import FTCLogoFull from "../images/logo_750.png";
//import PropertiesIcon from "../images/projects.png";
//import AccountIcon from "../images/my_account.png";
//import UsersIcon from "../images/users.png";

import QrReader from 'react-qr-reader'


//import PlaceIcon from '@material-ui/icons/PlaceRounded';
import KeyboardIcon from '@material-ui/icons/KeyboardRounded';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';

import classNames from 'classnames';
import PropTypes from 'prop-types';




import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {Dimmer, Loader, Dropdown, Search, Grid, Header, Segment } from 'semantic-ui-react';
import Slide from '@material-ui/core/Slide';

import _ from 'lodash'




import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SvgIcon from '@material-ui/core/SvgIcon';



const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

function AccountIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z" />
    </SvgIcon>
  );
}
function UsersIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5s-3 1.34-3 3 1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V18c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05.02.01.03.03.04.04 1.14.83 1.93 1.94 1.93 3.41V18c0 .35-.07.69-.18 1H22c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5z" />
    </SvgIcon>
  );
}
function PropertiesIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M15 11V5.83c0-.53-.21-1.04-.59-1.41L12.7 2.71c-.39-.39-1.02-.39-1.41 0l-1.7 1.7C9.21 4.79 9 5.3 9 5.83V7H5c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2h-4zm-8 8H5v-2h2v2zm0-4H5v-2h2v2zm0-4H5V9h2v2zm6 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm6 12h-2v-2h2v2zm0-4h-2v-2h2v2z" />
    </SvgIcon>
  );
}
function ObsIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12 4C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 12.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
    </SvgIcon>
  );
}
function JobsIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12.09 2.91C10.08.9 7.07.49 4.65 1.67L8.28 5.3c.39.39.39 1.02 0 1.41L6.69 8.3c-.39.4-1.02.4-1.41 0L1.65 4.67C.48 7.1.89 10.09 2.9 12.1c1.86 1.86 4.58 2.35 6.89 1.48l7.96 7.96c1.03 1.03 2.69 1.03 3.71 0 1.03-1.03 1.03-2.69 0-3.71L13.54 9.9c.92-2.34.44-5.1-1.45-6.99z" />
    </SvgIcon>
  );
}

function Transition(props) {
  return <Slide direction="right" {...props} />;
}

let scanDisabled = false;


export default class Home extends Component {
  constructor(props) {
    super(props);
    const now = new Date().getTime();

    this.state = {
      isLoading: true,
      scanResult: 'No result',
      isScannerOn: false,
      manualcode: '',
      handscancode: '',
      isKeyChecking: false,
      key: null,
      userList: {},
      alertTitle: '',
      alertMessage: '',
      userId: null,
      returnDate: now,
      userSearchValue: '',
      userSearchResults: []


    };
    this.handleReturnDateChange = this.handleReturnDateChange.bind(this);
  }
async componentDidMount() {
      //console.log('#########');
      //console.log(this.props);
      //console.log('@@@@@@@@@');
  if (!this.props.isAuthenticated) {
    return;
  }

  this.setState({ isLoading: false });

       //document.addEventListener("keydown", this.onKeyDown);


}

componentWillUnmount() {
  //document.removeEventListener("keydown", this.onKeyDown);
}

/*
KeyEvent.onKeyDownListener(({ pressedKey, keyCode }) => {
 if (keyCode === 10) { // Enter button
   this.handleSubmit();
 } else {
   console.log("jhbhbchjb");
   //this.setState(({ barcode }) => ({
     //barcode: `${barcode}${pressedKey}`,
   //}));
 }
});
*/

onKeyDown = event => {
      switch( event.keyCode ) {
          case 55:
              alert('It\'s a trap!');
              break;
          default:
          console.log(event.keyCode);
              break;
      }
      return true;
  }

getOrgItem(keyId, isCode) {
  let query = "?evt=true&usr=" + this.props.user.firstName + " " + this.props.user.lastName;
  if (isCode) {
    query = query + "&index=KEY"
  }
  return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|KEY|NIL/${keyId}${query}`);
}

/*
getOrgKey(id) {
  console.log("GET_KEY:" + id);
  return API.get("qkeyz", `/organisations/${this.props.org.pk}/keys/${id}`);
}
*/

/*
updateKey(keyId, body) {
  body.userName = this.props.user.firstName + " " + this.props.user.lastName;
  return API.put("qkeyz", `/organisations/${this.props.org.pk}/keys/${keyId}`, {body: body});
}
*/

updateKey(keyId, body) {
  try {
    body.userName = this.props.user.firstName + " " + this.props.user.lastName;
    return API.put("qkeyz", `/organisations/${this.props.org.pk}/items/PRP|KEY|NIL|${keyId}`, {body: body});
  } catch(e) {
    console.log(e);
  }
}


handleScan = data => {
  if (!this.scanDisabled && !this.state.isKeyChecking && data) {
    this.scanDisabled = true;
    let arr = data.split("|");
    if (arr && arr.length == 4) {
      if (arr[0] == "k" && arr[1] == this.props.org.pk) {
        let id = arr[2] + "|" + arr[3];


        console.log("handleScan id: " + id);
        this.getKey(id, true);
      } else {
        const newState = {
          userId: null,
          key: null,
          isLoading: false,
          alertType: 'default',
          alertTitle: 'Key Request Error',
          alertMessage: 'Invalid key.'
        };
        this.setState(newState);
      }
    }
  }
}

async getKey(id, isCompoundKey) {
  if (this.state.isLoading != true && id.length > 0) {
    this.setState({ isLoading: true, isKeyChecking: true });
    let result;
    if (isCompoundKey) {
      result = await this.getOrgItem(id, false);
    } else {
      result = await this.getOrgItem(id, true);
    }
    console.log("getOrgItem", result.status, result.error);
    if (result.status == false) {
      const newState = {
        userId: null,
        key: null,
        isLoading: false,
        alertType: 'default',
        alertTitle: 'Key Request Error',
        alertMessage: result.error
      };
      this.setState(newState);
      return;
    }
    let key;
    if (result.key) {
      key = result.key;
    } else {
      key = result;
    }
    console.log(key);
    if (key && key.userId && key.userId != '') {
      let message = key.firstName + " " + key.lastName + (key.orgName ? " - " + key.orgName : "") + " (" + key.categoryDesc + ")";
      const rdate = new Date(key.endDate);
      const overdue = Date.now() - key.endDate > 0 ? " (OVERDUE)" : "";
      const options = {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
}


      const returndate = new Intl.DateTimeFormat('default', options).format(rdate) + overdue;


      const newState = {
        userId: null,
        key: key,
        isLoading: false,
        alertType: 'keyCheckIn',
        returnDate: key.endDate,
        originalReturnDate: key.endDate,
        alertTitle: key.code + ": " + key.title +  ", " + key.locus,
        alertMessage: message,
        alertMessage2: returndate
      };
      this.setState(newState);
      return;
    }
    if (key) {
      const now = new Date().getTime();
      const newState = {
        userId: null,
        key: key,
        isLoading: false,
        returnDate: now + (1 * 60 * 60 * 1000),
        alertType: 'keyCheckOut',
        alertTitle: key.code + ": " + key.title +  ", " + key.locus
      };
      if (this.state.userList.length > 0) {
        newState.alertMessage = 'Sign out key to:';
        this.setState(newState);
        /*
        this.setState({
          key: key,
          isLoading: false,
          isKeyChecking: true,
          alertType: 'keyCheck',
          alertTitle: key.key.code + ": " + key.property.title +  ", " + key.key.locus,
          alertMessage: 'Sign out key to:'
        });
        */
      } else {
        newState.alertMessage = 'Loading users...';
        this.setState(newState);
        /*
        this.setState({
          key: key,
          isLoading: false,
        isKeyChecking: true,
          alertType: 'keyCheck',
          alertTitle: key.key.code + ": " + key.property.title +  ", " + key.key.locus,
          alertMessage: 'Loading users...'
        });
        */
        let uList = [];

        //let memo = {};

        const vResult = await this.getOrgVisitors();
        console.log("888888888888888");
        console.log(vResult);
        console.log("888888888888888");
        const visitors = vResult.Items;
        //let cnt = 0;

        //let vs = [];
        for (var i = 0; i < visitors.length; ++i) {
          const v = visitors[i];
          const vBareId = this.getIdFromSk(v.sk);
          //if(v.email != vBareId) {
            let text = v.firstName + " " + v.lastName;
            if (v.orgName) {
              text += " - " + v.orgName;
            }
            text += " (" + v.categoryDesc + ")"


            //vs.push({title: text, description: "jjjjj", image: v.binaryImage, price: '999'});



            uList.push({ key: v.sk, value: vBareId, text: text, firstname: v.firstName, lastname: v.lastName, orgname: v.orgName, email: v.email, category: v.category, categorydesc: v.categoryDesc, image: { avatar: true, src: v.binaryImage }, binaryimage: v.binaryImage});
            //cnt += 1;
          //}
        }
        //let name = 'Visitors / Contractors';
        //if(vs.length > 0) {
        //  memo[name] = {name, results: vs};
        //}
        const eResult = await this.getOrgEmployees();
        console.log("7888888888888888");
        console.log(eResult);
        console.log("7888888888888888");
        const emps = eResult.Items;
        //let em = [];

        for (var i = 0; i < emps.length; ++i) {
          const e = emps[i];
          const eBareId = this.getIdFromSk(e.sk);
          //if(e.email != eBareId) {
            let text = e.firstName + " " + e.lastName;
            //if (v.orgName) {
            //  text += " - " + v.orgName;
            //}
            text += " (EMPLOYEE)"

            //em.push({title: text, description: "Hzgc jksdgjd jggskg.", image: e.binaryImage, price: '888'});

            uList.push({ key: e.sk, value: eBareId, text: text, firstname: e.firstName, lastname: e.lastName, orgname: e.orgName, email: e.email, category: 'EMPLOYEE', categorydesc: 'EMPLOYEE', image: { avatar: true, src: e.binaryImage }, binaryimage: e.binaryImage});
          //}
        }
        //name = 'Employees';
        //if(em.length > 0) {
        //  memo[name] = {name, results: em};
        //}
        //console.log('memo');
        //console.log(memo);
        let message = 'Sign out key to:';
        //if (keyList.length === 0) {
        //  message = 'There are no keys available for this property.';
        //}
        this.setState({
          alertMessage: message,
          userList: uList
        });



      }





























    } else {
      this.setState({ isLoading: false, isKeyChecking: false, key: null});
      this.scanDisabled = false;
    }


    console.log(key);



  } else {
    this.setState({ isLoading: false, isKeyChecking: false, key: null});
    this.scanDisabled = false;
  }
}

getOrgVisitors() {
    return API.get("qkeyz", `/organisations/${this.props.org.pk}/VIS`);
}
getOrgEmployees() {
    return API.get("qkeyz", `/organisations/${this.props.org.pk}/EMP`);
}


getIdFromSk(sk) {
  return sk.substr(4);
}
getKeyIdFromSk(sk) {
  return sk.substr(12);
}
handleReturnDateChange(date) {
  let end = date.getTime();
  this.setState({
    returnDate: end
  });
}
handleError = err => {
  console.log("ERROR");
  console.error(err)
}

handleManualCodeSubmit = event => {
  event.preventDefault();
  const code = this.state.manualcode.trim();
  this.setState({manualcode: ""});
  if (!this.scanDisabled && !this.state.isKeyChecking && code != '') {
    this.scanDisabled = true;
    let arr = code.split("|");
    if (arr && arr.length == 4) {
      if (arr[0] == "k" && arr[1] == this.props.org.pk) {
        let id = arr[2] + "|" + arr[3];
        var fixedId = id.replace("˜", "~");
        console.log("handleHandScanCodeSubmit id: " + fixedId);
        this.getKey(fixedId, true);
      } else {
        const newState = {
          userId: null,
          key: null,
          isLoading: false,
          alertType: 'default',
          alertTitle: 'Key Request Error',
          alertMessage: 'Invalid key.'
        };
        this.setState(newState);
      }
    } else {
      this.getKey(code, false);
    }
  }
}
handleHandScanCodeSubmit = event => {
  event.preventDefault();
  const data = this.state.handscancode.trim();
  this.setState({handscancode: ""});
  console.log(data);
  //return;
  if (!this.scanDisabled && !this.state.isKeyChecking && data) {
    this.scanDisabled = true;
    let arr = data.split("|");
    if (arr && arr.length == 4) {
      if (arr[0] == "k" && arr[1] == this.props.org.pk) {
        let id = arr[2] + "|" + arr[3];
        var fixedId = id.replace("˜", "~");
        console.log("handleHandScanCodeSubmit id: " + fixedId);
        this.getKey(fixedId, true);
      } else {
        const newState = {
          userId: null,
          key: null,
          isLoading: false,
          alertType: 'default',
          alertTitle: 'Key Request Error',
          alertMessage: 'Invalid key.'
        };
        this.setState(newState);
      }
    }
  }
}



handleScannerClick= event => {
  event.preventDefault();
  this.setState({ isScannerOn: !this.state.isScannerOn });

  //this["handscancode"].focus();

}
handleBuilderClick = event => {

  event.preventDefault();
  sessionStorage.setItem('schema_id', 'Tom');
  this.props.history.push(event.currentTarget.getAttribute("href"));
}
handlePropertiesClick = event => {
  event.preventDefault();
  this.props.history.push("/properties");
}
handleVisitorsClick = event => {
  event.preventDefault();
  this.props.history.push("/visitors");
}
handleAccountClick = event => {
  event.preventDefault();
  this.props.history.push("/account");
}
handleTeamClick = event => {
  event.preventDefault();
  this.props.history.push("/employees");
}
handleJobsClick = event => {
  event.preventDefault();
  this.props.history.push("/jobs");
}
handleObsClick = event => {
  event.preventDefault();
  this.props.history.push("/observations");
}
handleManualCodeChange = event => {
  //console.log(event.target.value);
  this.setState({
    [event.target.id]: event.target.value
  });
}
handleHandScanCodeChange = event => {

  //console.log(">>>>: " + event.target.value);
  this.setState({
    [event.target.id]: event.target.value
  });
}


handleNoteClick = event => {
  event.preventDefault();
    sessionStorage.setItem('schema_id', event.currentTarget.getAttribute("key"));
  this.props.history.push(event.currentTarget.getAttribute("href"));
}

handleSelectorDialogClose = () => {
  if (this.state.isKeyChecking) {
    this.setState({
      isLoading: false,
      key: null,
      userId: null,
      isKeyChecking: false,
      alertType: '',
      userList: {},
      alertTitle: '',
      alertMessage: ''
    });
    this.scanDisabled = false;
  }
};

handleSelectorDialogExtendSelect = async () => {
  const bareKeyId = this.getKeyIdFromSk(this.state.key.sk);
  const barePropertyId = this.state.key.propertyId; //this.getIdFromSk(this.state.key.property.sk);
  let alertTitle = '';
  this.setState({
    isLoading: true
  });
  const key = this.state.key;
  //const property = this.state.key.property;
  await this.updateKey(bareKeyId, {
    signInOut: 'extend',
    endDate: this.state.returnDate,
    firstName: this.props.user.firstName,
    lastName: this.props.user.lastName,
    propertyId: barePropertyId,
    title: key.title,
    description: key.code,
    locus: key.locus
  });
  alertTitle = "Key Return Date Extension";
  this.setState({
    isLoading: false,
    key: null,
    userId: null,
    isKeyChecking: true,
    alertType: 'default',
    userList: {},
    alertTitle: alertTitle,
    alertMessage: 'Success'
  });
  this.scanDisabled = false;
}

handleSelectorDialogSelect = async () => {
  const bareKeyId = this.getKeyIdFromSk(this.state.key.sk);
  const barePropertyId = this.state.key.propertyId; //this.getIdFromSk(this.state.key.property.sk);
  let alertTitle = '';
  this.setState({
    isLoading: true
  });

  if (this.state.alertType === 'keyCheckIn') {
    await this.updateKey(bareKeyId, {
      signInOut: 'signin',
      firstName: this.state.key.firstName,
      lastName: this.state.key.lastName,
      orgName: this.state.key.orgName,
      propertyId: barePropertyId,
      title: this.state.key.title,
      description: this.state.key.code,
      locus: this.state.key.locus
    });
    alertTitle = "Key Sign-In"
  } else if (this.state.alertType === 'keyCheckOut') {
    console.log(this.state.userId);

    let user = this.state.userList.find(o => o.value === this.state.userId);

    console.log(user);


    await this.updateKey(bareKeyId, {
      signInOut: 'signout',
      userId: this.state.userId,
      firstName: user.firstname,
      lastName: user.lastname,
      orgName: user.orgname,
      email: user.email,
      category: user.category,
      categoryDesc: user.categorydesc,
      endDate: this.state.returnDate,
      binaryImage: user.binaryimage,
      propertyId: barePropertyId,
      title: this.state.key.title,
      description: this.state.key.code,
      locus: this.state.key.locus
    });
    alertTitle = "Key Sign-Out"


  }
  this.setState({
    isLoading: false,
    key: null,
    userId: null,
    isKeyChecking: true,
    alertType: 'default',
    userList: {},
    alertTitle: alertTitle,
    alertMessage: 'Success'
  });
  this.scanDisabled = false;
};

handleDropDownChange = (event, data) => {
  console.log(event);
  this.setState({
    userId: data.value
  });
}


/*
handleUserSearchResultSelect = (e, { result }) => this.setState({ userSearchValue: result.title })

handleUserSearchChange = (e, { value }) => {
  this.setState({ isLoading: true, userSearchValue: value })
  setTimeout(() => {
    if (this.state.userSearchValue.length < 1) return this.setState({userSearchValue: '', userSearchResults: [], isLoading: false})
    const re = new RegExp(_.escapeRegExp(this.state.userSearchValue), 'i')
    const isMatch = result => re.test(result.title)
    const filteredResults = _.reduce(
      this.state.userList,
      (memo, data, name) => {
        const results = _.filter(data.results, isMatch)
        if (results.length) memo[name] = { name, results } // eslint-disable-line no-param-reassign

        return memo;
      },
      {},
    )
    this.setState({
      isLoading: false,
      userSearchResults: filteredResults,
    })
  }, 300)
}
*/

  renderLander() {
    return (
      <div style={{textAlign: 'center', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        <img src={FTCLogoFull} style={{maxWidth: '100%', height: 'auto'}}/>

      </div>
    );
  }
  //<p className="title">A fully configurable field data collection, analysis and distribution app.</p>
renderOptionProperties() {
  return (
    <div className="landerButton" onClick={this.handlePropertiesClick}>
    <PropertiesIcon color="primary"  style={{ fontSize: 64 }} />
    <p className="title"><b>Properties</b></p>
    <p className="desc">Create and manage your properties, add keys and print their qrcodes.</p>
    </div>
  );
}
renderOptionTeam() {
  return (
    <div className="landerButton" onClick={this.handleTeamClick}>
    <UsersIcon color="primary"  style={{ fontSize: 64 }} />

    <p className="title"><b>Team</b></p>
    <p className="desc">Add team members and assign them roles.</p>
    </div>
    );
}

renderOptionVisitors() {
  return (
    <div className="landerButton" onClick={this.handleVisitorsClick}>
    <UsersIcon color="primary"  style={{ fontSize: 64 }} />
    <p className="title"><b>Visitors</b></p>
    <p className="desc">Add and manage visitors who will need access to your properties.</p>
    </div>
    );
}
renderOptionObs() {
  return (
    <div className="landerButton" onClick={this.handleObsClick}>
    <ObsIcon color="primary"  style={{ fontSize: 64 }} />
    <p className="title"><b>Observations</b></p>
    <p className="desc">Add and view observed issues at your properties.</p>
    </div>
    );
}
renderOptionJobs() {
  return (
    <div className="landerButton" onClick={this.handleJobsClick}>
    <JobsIcon color="primary"  style={{ fontSize: 64 }} />
    <p className="title"><b>Jobs</b></p>
    <p className="desc">Add and manage jobs associated with your properties.</p>
    </div>
    );
}
renderQrReader() {
  return (
    <div>
    <Paper style={{padding: '4px'}}>
      <QrReader
        delay={300}
        onError={this.handleError}
        onScan={this.handleScan}
        style={{ width: '250px' }}
      />
      </Paper>
    </div>
  )
}

renderHandQrReader() {
  //console.log("renderHandQrReader");
  return (
    <div>
      <form  noValidate autoComplete="off" onSubmit={this.handleHandScanCodeSubmit}>
      <TextField
        autoFocus
        style={{ width: '100px' }}
        id="handscancode"
        label="Ready to scan"
        onChange={this.handleHandScanCodeChange}
        value={this.state.handscancode}
        margin="normal"
        variant="outlined"
      />
      </form>

    </div>
  )
}
renderQrReaderSwitch() {
  return (
    <div>

    <Chip style={{position: 'absolute', right: '0px'}}
    avatar={
            <Avatar>
            {this.state.isScannerOn ? <KeyboardIcon/> : <Glyphicon style={{paddingLeft:'1px', paddingTop:'1px'}} glyph="qrcode"/>}

            </Avatar>
          }
    label={this.state.isScannerOn ? 'switch to manual' : 'switch to scanner'}
    clickable={true}
    color={this.state.isScannerOn ? 'secondary' : 'primary'}
    onClick={this.handleScannerClick}
    />

    </div>
  )
}

renderManualCode() {
  const { classes } = this.props;
  return (
    <div>
    <form  noValidate autoComplete="off" onSubmit={this.handleManualCodeSubmit} autoFocus>
    <TextField
    autoFocus
     style={{ width: '300px' }}
      id="manualcode"
      label="Enter key code or scan qrcode"
      onChange={this.handleManualCodeChange}
      value={this.state.manualcode}
      margin="normal"
      variant="outlined"
    />
    </form>
    </div>
  );
}


  renderOptions() {
    //console.log(this.props);
    const isAdmin = this.props.userOrg && this.props.userOrg.userRoles && this.props.userOrg.userRoles.indexOf('ADMIN') >= 0;
    const isVisitor = this.props.userOrg && this.props.userOrg.entity == "VIS";
    return (
      <div>
      <div className="options" style={{display: 'flex', height: '100%', position: 'absolute', left:0, right:0,top: 0, paddingBottom:0, opacity: 0.06, backgroundImage:`url(${FTCLogo})`}}>
      {/*<div style={{ display: 'flex', width:'100%', height: '100%'}}>
      <img style={{ height:'100%', margin: 'auto', opacity: 0.05, filter: 'alpha(opacity=10)'}} src={FTCLogo}/>
      <img style={{ height:'100%', margin: 'none', opacity: 0.1}} src={FTCLogo}/>*/}
      </div>



        <Breadcrumb style={{top: 58, left: 66, right: 0, position: 'absolute', zIndex:999, backgroundColor: '#FFF0'}}>
          <Breadcrumb.Item active>Home</Breadcrumb.Item>
        </Breadcrumb>
        <div className="options" style={{display: 'flex', position: 'absolute', left:80, right:80,top: 40, paddingBottom:50}}>
          <div className="lander" style={{display: 'inline-block', width:'100%', aligntop:'0px'}}>
          {/*
            <div style={{ display: 'flex', width:'100%', height: 'calc(100vh - 198px)'}}>
            <img style={{ height:'100%', margin: 'auto', opacity: 0.05, filter: 'alpha(opacity=10)'}} src={FTCLogo}/>
            <img style={{ height:'100%', margin: 'none', opacity: 0.1}} src={FTCLogo}/>
            </div>
            */}
            <div style={{display: 'flex', position: 'absolute', left:80, right:80,top: 40, paddingBottom:50}}>
            <div className="lander" style={{display: 'inline-block', width:'100%', aligntop:'0px'}}>
            <div className="lander" style={{display: 'inline-block', width:'700px', aligntop:'0px'}}>
              {this.props.org && !isVisitor ? this.renderOptionProperties() : null}
              {this.props.org && !isVisitor && false ? this.renderOptionObs() : null}
              {this.props.org && !isVisitor && false ? this.renderOptionJobs() : null}
              {this.props.org && !isVisitor ? this.renderOptionVisitors() : null}
              <div className="landerButton" onClick={this.handleAccountClick}>
              <AccountIcon color="primary"  style={{ fontSize: 64 }} />
                <p className="title"><b>My Account</b></p>
                <p className="desc">Manage your account details.</p>
              </div>
              {this.props.org && isAdmin && !isVisitor ? this.renderOptionTeam() : null}







              </div>
              </div>
            </div>
          </div>

        </div>
        <div style={{display: 'flex', position: 'absolute', right:65,bottom: 80, paddingBottom:0}}>
           {this.props.org && isAdmin && !isVisitor ? (this.state.isScannerOn ? this.renderHandQrReader() : this.renderManualCode()) : null}
           </div>
           {/*
         <div style={{display: 'flex', position: 'absolute', right:40,bottom: 100, paddingBottom:0}}>
           {this.props.org && isAdmin && !isVisitor ? this.renderQrReaderSwitch() : null}
         </div>
         */}
      </div>
    );
  }

  render() {
    //const { isLoading, userSearchValue, userSearchResults } = this.state
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderOptions() : this.renderLander()}

        <Dialog
          open={this.state.isKeyChecking && this.state.alertType == 'default'}

          fullWidth={false}
          maxWidth = {'md'}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

                           <DialogTitle id="alert-dialog-slide-title">
            {this.state.alertTitle}
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
             {this.state.alertMessage}
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSelectorDialogClose} color="secondary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>



        <Dialog
          open={this.state.isKeyChecking && this.state.alertType == 'keyCheckIn'}

          fullWidth={false}
          maxWidth = {'md'}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

                           <DialogTitle id="alert-dialog-slide-title">
            {this.state.alertTitle}
          </DialogTitle>
          <DialogContent>
    {this.state.key &&
      <>
          <FormGroup controlId="visitorId">
          <ControlLabel>Key currently signed out to:</ControlLabel>

          <p>{this.state.alertMessage}</p>
          <br />
          <p><b>Expected return date:</b></p>
          <p>{this.state.alertMessage2}</p>

    </FormGroup>
    <FormGroup controlId="endDate">
    <ControlLabel>Extend return date:</ControlLabel>
    <div className="layoutchild">
    <DatePicker
           selected={new Date(this.state.returnDate)}
           onChange={this.handleReturnDateChange}
           showTimeSelect
           timeFormat="HH:mm"
           timeIntervals={30}
           dateFormat="dd MMM yyyy h:mm aa"
           timeCaption="time"
           className={'dateborder'}
         />
         </div>
    </FormGroup>
    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
</>
        }


          </DialogContent>
          <DialogActions>
          <Button onClick={this.handleSelectorDialogClose} color="secondary">
            Cancel
          </Button>
          <Button disabled={this.state.returnDate == this.state.originalReturnDate} onClick={this.handleSelectorDialogExtendSelect} color="primary">
            Extend return date
          </Button>
          <Button onClick={this.handleSelectorDialogSelect} color='primary'>
            Sign in key
          </Button>
          </DialogActions>
        </Dialog>



        <Dialog
          open={this.state.isKeyChecking && this.state.alertType == 'xxxxxxxx'}

          fullWidth={false}
          maxWidth = {'md'}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

                           <DialogTitle id="alert-dialog-slide-title">
            {this.state.alertTitle}
          </DialogTitle>
          <DialogContent>
{this.state.key &&
          <FormGroup controlId="visitorId">
          <ControlLabel>Key currently signed out to:</ControlLabel>

          <p>{this.state.alertMessage}</p>
          <br />
          <p><b>Expected return date:</b></p>
          <p>{this.state.alertMessage2}</p>

</FormGroup>
        }


          </DialogContent>
          <DialogActions>
          <Button onClick={this.handleSelectorDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.handleSelectorDialogSelect} color='primary'>
            Sign in key
          </Button>
          </DialogActions>
        </Dialog>






                <Dialog
                  open={this.state.isKeyChecking && this.state.alertType == 'keyCheckOut'}
                  fullWidth={true}
                  maxWidth = {'md'}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >


                                   <DialogTitle id="alert-dialog-slide-title">
                    {this.state.alertTitle}


                  </DialogTitle>
                  <DialogContent>
{!(this.state.userList.length > 0) &&
                     <b>{this.state.alertMessage}</b>
                   }






        <div>

{this.state.userList.length > 0 && this.state.key &&
<>
<FormGroup controlId="visitorId">
<ControlLabel>Sign out key to:</ControlLabel>
  <Dropdown
     placeholder='Select Employee / Visitor / Contractor'
     onChange={this.handleDropDownChange}
     fluid
     search
     deburr
     selection
     options={this.state.userList}
     value={this.state.visitorId}
   />
   </FormGroup>
   <br/>
   <FormGroup controlId="endDate">
   <ControlLabel>Return Date</ControlLabel>
   <div className="layoutchild">
   <DatePicker
           selected={new Date(this.state.returnDate)}
           onChange={this.handleReturnDateChange}
           showTimeSelect
           timeFormat="HH:mm"
           timeIntervals={30}
           dateFormat="dd MMM yyyy h:mm aa"
           timeCaption="time"
           className={'dateborder'}
         />
         </div>
   </FormGroup>
</>
}
<br/><br/><br/><br/><br/><br/>


        </div>



                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleSelectorDialogClose} color="secondary">
                      Cancel
                    </Button>
                    <Button disabled={!this.state.userId} onClick={this.handleSelectorDialogSelect} color='primary'>
                      Sign out key
                    </Button>
                  </DialogActions>





                </Dialog>

                { this.state.isLoading && this.state.isKeyChecking &&
                <Dimmer  style={{zIndex:99999}} disabled={!(this.state.isLoading && this.state.isKeyChecking)} active inverted><Loader disabled={!(this.state.isLoading && this.state.isKeyChecking)} size='small'/></Dimmer>

}


      </div>
    );
  }
}
