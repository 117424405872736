import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel, ListGroup, ListGroupItem, Breadcrumb } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import PropertyCard from "./PropertyCard";
import config from "../config";
import "./PropertyKeys.css";
import { s3Upload } from "../libs/awsLib";
import Typography from '@material-ui/core/Typography';
import loaderIcon from'../images/qkeyz.gif';
import EnhancedTable from "./EnhancedTable";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import green from '@material-ui/core/colors/green';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {Dimmer, Loader, Dropdown, Search, Grid, Header, Segment } from 'semantic-ui-react';



import QKeyzLogo from "../images/qkeyz_logo.svg"; //full_logo_40.png
import QRCode from "qrcode.react";
import ReactToPrint from "react-to-print";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



import _ from 'lodash'

//const styles = theme => ({ root: { overflow: 'visible' } });
//const styles = theme => ({ dialogPaper: { overflow: 'visible' } });

const rowHeaders = [
  { id: 'code', numeric: false, center: true, disablePadding: true, label: 'Code' },
  { id: 'locus', numeric: false, center: false, disablePadding: false, label: 'Locus' },
  { id: 'signedout', numeric: false, center: false, disablePadding: false, label: 'Signed Out' },
  { id: 'returndate', numeric: false, center: false, disablePadding: false, label: 'Due Back' },
  { id: 'created', numeric: false, center: false, disablePadding: false, label: 'Created' },
  { id: 'updated', numeric: false, center: false, disablePadding: false, label: 'Updated' }
];

export default class PropertyKeys extends Component {
  constructor(props) {
    super(props);
    const now = new Date().getTime();

    this.file = null;
    this.state = {
      mode: "normal",
      alertOpen: false,
      alertType: '',
      isLoading: true,
      isDeleting: false,
      isSaving: false,
      property: null,
      title: "",
      logo: "",
      attachmentURL: null,
      description: "",
      isDeleting: false,
      multiline: 'Controlled',

      isKeyChecking: false,
      key: null,
      userList: {},
      alertTitle: '',
      alertMessage: '',
      userId: null,
      returnDate: now

    };
    this.handleReturnDateChange = this.handleReturnDateChange.bind(this);
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated || !this.props.org) {
      this.props.history.push("/");
      return;
    }

    try {
      let attachmentURL;
      const property = await this.getProperty();
      const {binaryImage, title, photo, description } = property;


      const result = await this.getKeys(this.getPropertyIdFromSk(property.sk));
      const keys = result.Items;
      console.log("KEYZ");
      console.log(keys);



      const rows = await this.getStructuredKeyList(keys);
      this.setState({
        isLoading: false,
        property,
        title: title,
        logo: binaryImage,
        attachmentURL,
        description: description,
        rows: rows,
        keys: keys
      });
    } catch (e) {
      alert(e);
    }
  }
  async getStructuredKeyList(keys) {
    var rows = [];
    for (var i = 0; i < keys.length; ++i) {
      let k = keys[i];
      const {code, locus, userId, firstName, lastName, orgName, categoryDesc, endDate, created, updated} = k;
      let signedOutUser = "";
      if (userId) {
        signedOutUser = firstName + " " + lastName + (orgName ? " - " + orgName : "") + " (" + categoryDesc + ")";
      }
      let returnDate = "";
      let datetype = "date";
      if (endDate) {
        returnDate = (new Date(parseInt(endDate))).toLocaleString();
        const now = Date.now();
        if (now - endDate >= 0) {
          datetype = 'date_red';
        } else {
          datetype = 'date_green';
        }
      }
      //const signedout = contractorId && contractorId == "";
      let rowCols = [
        { id: 'code', numeric: false, center: true, disablePadding: true, value: code, type: 'text'},
        { id: 'locus', numeric: false, center: false, disablePadding: false, value: locus, type: 'text' },
        { id: 'signedout', numeric: false, center: false, disablePadding: false, value: signedOutUser, type: 'text' },
        { id: 'returndate', numeric: false, center: false, disablePadding: false, value: returnDate, type: datetype },
        { id: 'created', numeric: false, center: false, disablePadding: false, value: (new Date(created)).toLocaleString(), type: 'date' },
        { id: 'updated', numeric: false, center: false, disablePadding: false, value: (new Date(updated)).toLocaleString(), type: 'date' }
      ];
      rows.push({id: i + 1, code, locus, created, updated, signedout: signedOutUser, returndate: returnDate, key: k.sk, cols: rowCols})
    }
    return rows
  }


  getProperty() {


    const propertyId = this.props.location.state && this.props.location.state.propertyId;
    return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|NIL/${propertyId}`);

  }
  getKeys(propertyId) {
    //return API.get("qkeyz", `/properties/${propertyId}/KEY`);
    return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|KEY|NIL|${propertyId}`);
  }

  deleteKeys() {
    const idList = this.state.selected;
    if (idList.length > 0) {
      const body = {ids: idList};
      return API.del("qkeyz", `/organisations/${this.props.org.pk}/KEY`, {body: body});
    }
  }

  getOrgVisitors() {
      return API.get("qkeyz", `/organisations/${this.props.org.pk}/VIS`);
  }
  getOrgEmployees() {
      return API.get("qkeyz", `/organisations/${this.props.org.pk}/EMP`);
  }



  updateKey(keyId, body) {
    try {
      body.userName = this.props.user.firstName + " " + this.props.user.lastName;
      return API.put("qkeyz", `/organisations/${this.props.org.pk}/items/PRP|KEY|NIL|${keyId}`, {body: body});
    } catch(e) {
      console.log(e);
    }
  }

/*
  updateKey(keyId, body) {
    body.userName = this.props.user.firstName + " " + this.props.user.lastName;
    return API.put("qkeyz", `/organisations/${this.props.org.pk}/keys/${keyId}`, {body: body});
  }
  */

  getIdFromSk(sk) {
    return sk.substr(4);
  }
  getKeyIdFromSk(sk) {
    return sk.substr(12);
  }
  getPropertyIdFromSk(sk) {
    return sk.substr(8);
  }

handleReturnDateChange(date) {
  let end = date.getTime();
  this.setState({
    returnDate: end
  });
}

formatFilename(str) {
  return str.replace(/^\w+-/, "");
}

handleChange = event => {
  this.setState({
    [event.target.id]: event.target.value
  });
}

handleMapLinkClick = event => {
  if (this.state.property.mapLink && this.state.property.mapLink !== "") {
    window.open(this.state.property.mapLink, "_blank");
  }

}

handleCloseQRCodes= (event, selected) => {
  event.preventDefault();
  this.setState({
    mode: "normal"
  });
}

handleQRCodesClick = (event, selected) => {
  event.preventDefault();
  this.setState({
    mode: "qrcodes",
    selected
  });
}

handleCrumbClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}
handleAlertClose = () => {
    this.setState({ alertOpen: false, alertType: '' });
};

handleDropDownChange = (event, data) => {
  console.log(event);
  this.setState({
    userId: data.value
  });
}

mergeById(arr, sortKey) {
  return {
    with: function(arr2) {
      return _.map(arr, item => {
        return _.find(arr2, obj => obj[sortKey] === item[sortKey]) || item
      });
    }
  }
}
handleSelectorDialogExtendSelect = async () => {


  const bareKeyId = this.getKeyIdFromSk(this.state.key.sk);
  const barePropertyId = this.getPropertyIdFromSk(this.state.property.sk);
  let alertTitle = '';
  this.setState({
    isLoading: true
  });
  const key = this.state.key;
  await this.updateKey(bareKeyId, {
    signInOut: 'extend',
    endDate: this.state.returnDate,
    firstName: this.props.user.firstName,
    lastName: this.props.user.lastName,
    propertyId: barePropertyId,
    title: this.state.property.title,
    description: key.code,
    locus: key.locus
  });
  alertTitle = "Key Return Date Extension";
  key.endDate = this.state.returnDate;
  const newRows = await this.getStructuredKeyList([key]);
  const rows = this.mergeById(this.state.rows, "key").with(newRows)
  const keys = this.mergeById(this.state.keys, "sk").with([key])
  this.setState({
    isLoading: false,
    key: null,
    userId: null,
    isKeyChecking: true,
    alertType: 'default',
    userList: {},
    alertTitle: alertTitle,
    alertMessage: 'Success',
    rows: rows,
    keys: keys
  });
}


handleSelectorDialogSelect = async () => {
  const bareKeyId = this.getKeyIdFromSk(this.state.key.sk);
  const barePropertyId = this.getPropertyIdFromSk(this.state.property.sk);
  let alertTitle = '';
  this.setState({
    isLoading: true
  });


  const key = this.state.key;


  if (this.state.alertType === 'keyCheckIn') {
    await this.updateKey(bareKeyId, {
      signInOut: 'signin',
      firstName: key.firstName,
      lastName: key.lastName,
      orgName: key.orgName,
      propertyId: barePropertyId,
      title: this.state.property.title,
      description: key.code,
      locus: key.locus
    });
    alertTitle = "Key Sign-In";
    key.userId = null;
    key.firstName = null;
    key.lastName = null;
    key.orgName = null;
    key.email = null;
    key.category = null;
    key.categoryDesc = null;
    key.endDate = null;
    key.binaryImage = null;
  } else if (this.state.alertType === 'keyCheckOut') {
    console.log(this.state.userId);

    let user = this.state.userList.find(o => o.value === this.state.userId);

    console.log(user);


    await this.updateKey(bareKeyId, {
      signInOut: 'signout',
      userId: this.state.userId,
      firstName: user.firstname,
      lastName: user.lastname,
      orgName: user.orgname,
      email: user.email,
      category: user.category,
      categoryDesc: user.categorydesc,
      endDate: this.state.returnDate,
      binaryImage: user.binaryimage,
      propertyId: barePropertyId,
      title: this.state.property.title,
      description: key.code,
      locus: key.locus
    });
    alertTitle = "Key Sign-Out";
    key.userId = this.state.userId;
    key.firstName = user.firstname;
    key.lastName = user.lastname;
    key.orgName = user.orgname;
    key.email = user.email;
    key.category = user.category;
    key.categoryDesc = user.categorydesc;
    key.endDate = this.state.returnDate;
    key.binaryImage = user.binaryimage;
  }
  const newRows = await this.getStructuredKeyList([key]);
  const rows = this.mergeById(this.state.rows, "key").with(newRows)
  const keys = this.mergeById(this.state.keys, "sk").with([key])
  this.setState({
    isLoading: false,
    key: null,
    userId: null,
    isKeyChecking: true,
    alertType: 'default',
    userList: {},
    alertTitle: alertTitle,
    alertMessage: 'Success',
    rows: rows,
    keys: keys
  });
};




handleKeyClick = async (event, keySk) => {
  event.preventDefault();

  const key = this.state.keys.find(o => o.sk === keySk);
  const now = new Date().getTime();

  console.log(key);
  if (key && key.userId && key.userId != '') {
    let message = key.firstName + " " + key.lastName + (key.orgName ? " - " + key.orgName : "") + " (" + key.categoryDesc + ")";
    //const rdate = new Date(key.endDate);
    let ed = parseInt(key.endDate);
    console.log(ed);
    const rdate = new Date(ed);
    const overdue = Date.now() - key.endDate > 0 ? " (OVERDUE)" : "";
    const options = {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    }
    console.log(key.endDate);
    console.log(rdate.getTime());
    console.log(overdue);
    console.log(rdate);
    const returndate = new Intl.DateTimeFormat('default', options).format(rdate) + overdue;
    const newState = {
      userId: null,
      key: key,
      isLoading: false,
      isKeyChecking: true,
      returnDate: key.endDate,
      originalReturnDate: key.endDate,
      alertType: 'keyCheckIn',
      alertTitle: key.code + ": " + this.state.title +  ", " + key.locus,
      alertMessage: message,
      alertMessage2: returndate
    };
    this.setState(newState);
    return;
  }
  if (key) {
    const newState = {
      userId: null,
      key: key,
      isKeyChecking: true,
      isLoading: false,
      returnDate: now + (1 * 60 * 60 * 1000),
      alertType: 'keyCheckOut',
      alertTitle: key.code + ": " + this.state.title +  ", " + key.locus
    };
    if (this.state.userList.length > 0) {
      newState.alertMessage = 'Sign out key to:';
      this.setState(newState);
    } else {
      newState.alertMessage = 'Loading users...';
      this.setState(newState);
      let uList = [];
      const result = await this.getOrgVisitors();
      const visitors = result.Items;
      for (var i = 0; i < visitors.length; ++i) {
        const v = visitors[i];
        const vBareId = this.getIdFromSk(v.sk);
        //if(v.email != vBareId) {
          let text = v.firstName + " " + v.lastName;
          if (v.orgName) {
            text += " - " + v.orgName;
          }
          text += " (" + v.categoryDesc + ")"
          uList.push({ key: v.sk, value: vBareId, text: text, firstname: v.firstName, lastname: v.lastName, orgname: v.orgName, email: v.email, category: v.category, categorydesc: v.categoryDesc, image: { avatar: true, src: v.binaryImage }, binaryimage: v.binaryImage});
        //}
      }
      const result2 = await this.getOrgEmployees();
      const emps = result2.Items;
      for (var i = 0; i < emps.length; ++i) {
        const e = emps[i];
        const eBareId = this.getIdFromSk(e.sk);
        //if(e.email != eBareId) {
          let text = e.firstName + " " + e.lastName;
          text += " (EMPLOYEE)"
          uList.push({ key: e.sk, value: eBareId, text: text, firstname: e.firstName, lastname: e.lastName, orgname: e.orgName, email: e.email, category: 'EMPLOYEE', categorydesc: 'EMPLOYEE', image: { avatar: true, src: e.binaryImage }, binaryimage: e.binaryImage});
        //}
      }
      let message = 'Sign out key to:';
      this.setState({
        alertMessage: message,
        userList: uList
      });
    }
  }
}






handleKeyEditClick = (event, selected) => {
  event.preventDefault();
  this.props.history.push({
  pathname: '/properties/property/keys/edit',
  state: { keyId: this.getKeyIdFromSk(selected[0]), property: this.state.property, mode: 'edit' }
  });
}
handleCreateKeyClick = async event => {
  event.preventDefault();
  this.props.history.push({
    pathname: '/properties/property/keys/new',
    state: { property: this.state.property }
  });
}
handleCloneKeyClick = async (event, selected) => {
  event.preventDefault();
  this.props.history.push({
  pathname: '/properties/property/keys/edit',
  state: { keyId: this.getKeyIdFromSk(selected[0]), property: this.state.property, mode: 'clone' }
  });
}

handleDeleteKeyClick = async (event, selected) => {
  event.preventDefault();
  let count = selected.length;
  if (count > 0) {
    this.setState({
      alertOpen: true,
      alertType: 'delete',
      selected: selected
    });
  }
}
 handleDeleteKeys = async (event) => {
  this.setState({ isDeleting: true, alertOpen: false });
  console.log('aaa');
  let isSuccess = false
  try {
    const result = await this.deleteKeys();
    console.log(result);
    isSuccess = true;
  } catch (e) {
    alert(e);
  }

  console.log('ddd');
  const result = await this.getKeys(this.getPropertyIdFromSk(this.state.property.sk));
  const keys = result.Items;
  const rows = await this.getStructuredKeyList(keys);
  this.setState({ isDeleting: false, keys: keys, rows: rows });
}

handleSelectorDialogClose = () => {
  if (this.state.isKeyChecking) {
    this.setState({
      isLoading: false,
      key: null,
      userId: null,
      isKeyChecking: false,
      alertType: '',
      userList: {},
      alertTitle: '',
      alertMessage: ''
    });
    this.scanDisabled = false;
  }
};


renderQRCodes() {

  console.log(this.state.selected);
  console.log(this.state.rows);
  var selectedRows = [];
  var cols = [];
  for (var i2 = 0; i2 < this.state.selected.length; ++i2) {

    for (var i = 0; i < this.state.rows.length; ++i) {
      let rowKeyId = this.state.rows[i].key;
      if (rowKeyId === this.state.selected[i2]) {
        cols.push(this.state.rows[i]);
        if (cols.length == 2) {
          selectedRows.push(cols);
          cols = [];
        }
        break;
      }
    }
  }
  if (cols.length > 0) {
    cols.push({id: 999, code: 'blank', locus: 'blank', created: null, updated: null, signedout: false, key: '', cols: null});
//{id: i + 1, code, locus, created, updated, signedout, key: k.sk, cols: rowCols}
    selectedRows.push(cols);
  }
  console.log("selectedrows");
console.log(selectedRows);


  return(<div padding="16px"

>

  <ReactToPrint
     trigger={() => <Button color="primary" autoFocus>
       {'Print'}
     </Button>}
     content={() => this.componentRef}
   />
   <Button onClick={this.handleCloseQRCodes} color="secondary" autoFocus>
     {'Close'}
   </Button>
   <ComponentToPrint keys={selectedRows} org={this.props.org} property={this.state.property} ref={el => (this.componentRef = el)} />
</div>
)

}



renderLoader() {
  const label = this.state.isDeleting ? 'deleting...' : this.state.isSaving ? 'saving...' : 'loading...';
  return(

    <div id="splash" style={{paddingTop: 250, zIndex:999}}>

      <center id="splash">
        <img src={loaderIcon}/>
        <Typography style={{padding: '4px'}} variant="caption">{label}</Typography>
      </center>
    </div>



  )
}
renderKeys() {
  const menuItems = [
    { id: 0, label: 'Create new...', lt: Number.MAX_VALUE, gt: 0, isOrOperator: false, action: this.handleCreateKeyClick, divider: false, type: 'create' },
    { id: 1, label: 'Clone...', lt: 1, gt: 1, isOrOperator: true, action: this.handleCloneKeyClick, divider: false, type: 'clone' },
    { id: 2, label: 'Edit...', lt: 1, gt: 1, isOrOperator: true, action: this.handleKeyEditClick, divider: false, type: 'edit'},
    { id: 3, label: 'Delete', lt: 1, gt: -1, isOrOperator: false, action: this.handleDeleteKeyClick, divider: true, type: 'delete' },
    { id: 4, label: 'QRCodes...', lt: 1, gt: -1, isOrOperator: false, action: this.handleQRCodesClick, divider: false, type: 'qrcodes' }
  ];
    return (
        <div>
          {!this.state.isLoading && <EnhancedTable
                                        logosKey={this.state.key}
                                        title='Property Keys'
                                        type='propkeys'
                                        tooltip=''
                                        items={this.state.rows}
                                        menuItems={menuItems}
                                        handleItemClick={this.handleKeyClick}
                                        rowHeaders={rowHeaders}
                                        orderBy='code'
                                        order='asc'
                                        />}
          </div>
    );
  }

render() {
  //const { classes } = this.props;
  return (
  <div>
         <Breadcrumb  style={{top: 58, left: 66, right: 0, position: 'absolute', zIndex:999, backgroundColor: '#FFF'}}>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/properties">Properties</Breadcrumb.Item>
          <Breadcrumb.Item active>{this.state.title} Keys</Breadcrumb.Item>
        </Breadcrumb>
    <div className="PropertyKeys" style={{position: 'absolute', left:80, right:80,top: 104, paddingBottom:50}}>
      {!this.state.isLoading && this.state.property && <PropertyCard
        title={this.state.title}
        description={this.state.description}
        logo={this.state.logo}
        maplink={this.state.property.mapLink}
        contactName={this.state.property.contactName}
        contactNumber={this.state.property.contactPhone}
        onClick={this.handleMapLinkClick}
        colour={'#FAFcFA'}
        />}
        {this.state.isLoading || this.state.isDeleting || this.state.isSaving ? this.renderLoader() : this.state.mode === 'qrcodes' ? this.renderQRCodes() : this.renderKeys()}
    </div>
    <Dialog
        open={this.state.alertOpen && this.state.alertType != ''}
        onClose={this.handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.state.alertType == 'activate' ? (this.state.active ? 'Deactivate Project' : 'Activate Project') : 'Delete Keys'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {this.state.alertType == 'activate' ? (this.state.active ? 'Users will not have access to this projects active schemas.' : 'This projects active schemas will be available for use on users survey devices.') : 'Are you sure you wish to delete the selected keys? This cannot be undone.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleAlertClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.state.alertType == 'activate' ? this.changeProjectLiveState : this.handleDeleteKeys} color="primary" autoFocus>
            {this.state.alertType == 'activate' ? (this.state.active ? 'Deactivate' : 'Activate') : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>



      <Dialog
        open={this.state.isKeyChecking && this.state.alertType == 'default'}

        fullWidth={false}
        maxWidth = {'md'}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >

                         <DialogTitle id="alert-dialog-slide-title">
          {this.state.alertTitle}
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
           {this.state.alertMessage}
        </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleSelectorDialogClose} color="secondary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>



          <Dialog
            open={this.state.isKeyChecking && this.state.alertType == 'keyCheckIn'}

            fullWidth={false}
            maxWidth = {'md'}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >

                             <DialogTitle id="alert-dialog-slide-title">
              {this.state.alertTitle}
            </DialogTitle>
            <DialogContent>
      {this.state.key &&
        <>
            <FormGroup controlId="visitorId">
            <ControlLabel>Key currently signed out to:</ControlLabel>

            <p>{this.state.alertMessage}</p>
            <br />
            <p><b>Expected return date:</b></p>
            <p>{this.state.alertMessage2}</p>

      </FormGroup>
      <FormGroup controlId="endDate">
      <ControlLabel>Extend return date:</ControlLabel>
      <div className="layoutchild">
      <DatePicker
             selected={new Date(parseInt(this.state.returnDate))}
             onChange={this.handleReturnDateChange}
             showTimeSelect
             timeFormat="HH:mm"
             timeIntervals={30}
             dateFormat="dd MMM yyyy h:mm aa"
             timeCaption="time"
             className={'dateborder'}
           />
           </div>
      </FormGroup>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
</>
          }


            </DialogContent>
            <DialogActions>
            <Button onClick={this.handleSelectorDialogClose} color="secondary">
              Cancel
            </Button>
            <Button disabled={this.state.returnDate == this.state.originalReturnDate} onClick={this.handleSelectorDialogExtendSelect} color="primary">
              Extend return date
            </Button>
            <Button onClick={this.handleSelectorDialogSelect} color='primary'>
              Sign in key
            </Button>
            </DialogActions>
          </Dialog>






                  <Dialog className="dialog_overflow"
                    open={this.state.isKeyChecking && this.state.alertType == 'keyCheckOut'}
                    fullWidth={true}
                    maxWidth = {'md'}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                  >


                                     <DialogTitle id="alert-dialog-slide-title">
                      {this.state.alertTitle}


                    </DialogTitle>
                    <DialogContent  className="dialog_overflow">
      {!(this.state.userList.length > 0) &&
                       <b>{this.state.alertMessage}</b>
                     }






          <div>

      {this.state.userList.length > 0 && this.state.key &&
      <>
      <FormGroup controlId="visitorId">
      <ControlLabel>Sign out key to:</ControlLabel>
      <Dropdown
       placeholder='Select Employee / Visitor / Contractor'
       onChange={this.handleDropDownChange}
       fluid
       search
       deburr
       selection
       options={this.state.userList}
       value={this.state.visitorId}
      />
      </FormGroup>

      <FormGroup controlId="endDate">
      <ControlLabel>Return Date</ControlLabel>
      <div className="layoutchild">
      <DatePicker
             selected={new Date(parseInt(this.state.returnDate))}
             onChange={this.handleReturnDateChange}
             showTimeSelect
             timeFormat="HH:mm"
             timeIntervals={30}
             dateFormat="dd MMM yyyy h:mm aa"
             timeCaption="time"
             className={'dateborder'}
           />
           </div>
      </FormGroup>
      </>
      }
<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>

          </div>



                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleSelectorDialogClose} color="secondary">
                        Cancel
                      </Button>
                      <Button disabled={!this.state.userId} onClick={this.handleSelectorDialogSelect} color='primary'>
                        Sign out key
                      </Button>
                    </DialogActions>





                  </Dialog>


                  { this.state.isLoading && this.state.isKeyChecking &&
                  <Dimmer  style={{zIndex:99999}} disabled={!(this.state.isLoading && this.state.isKeyChecking)} active inverted><Loader disabled={!(this.state.isLoading && this.state.isKeyChecking)} size='small'/></Dimmer>

  }




    </div>

  );
}
}


class ComponentToPrint extends React.Component {


  render() {


  return (
    <div className="qrcodes">

      <center><h1>{this.props.org.orgName} - {this.props.property.title}</h1></center>


      <table className="a">

      {this.props.keys.map(keyPair => {
        let qrCode0 = "k|" + this.props.org.pk + "|" + keyPair[0].key.substr(12);
        if (keyPair[1].code !== 'blank') {
          let qrCode1 = "k|" + this.props.org.pk + "|" + keyPair[1].key.substr(12);
          return (
            <>
            <tbody><tr className="keepTogether"><td className="location" colSpan="1"><p className="g"><b>Location:</b> {keyPair[0].locus}</p></td><td></td><td className="location" colSpan="1"><p className="g"><b>Location:</b> {keyPair[1].locus}</p></td></tr></tbody>
            <tbody><tr><td className="x">
              <table className="b">
                <tbody><tr>
                  <td><center><p className="e">This key belongs to<br/><b>{this.props.org.orgName}</b><br/>If found please call<br/><b>{this.props.org.contactPhone}</b></p></center></td>
                  <td className="z"></td>
                  <td>
                    <table className="c">
                      <tbody><tr>
                        <td className="v"><center><span className="qkeyz"><center><img className="ttt" src={QKeyzLogo}/></center></span></center></td>
                        <td><center><span className="keyid"><p className="h">{keyPair[0].code}</p></span></center></td>
                        <td className="y" align="right"><QRCode renderAs="svg" size={116} value={qrCode0} /></td>
                      </tr></tbody>
                    </table>
                  </td>
                </tr></tbody>
              </table>
             </td>
             <td>&nbsp;</td>
             <td className="x">
              <table className="b">
                <tbody><tr>
                  <td><center><p className="e">This key belongs to<br/><b>{this.props.org.orgName}</b><br/>If found please call<br/><b>{this.props.org.contactPhone}</b></p></center></td>
                  <td className="z"></td>
                  <td>
                    <table className="c">
                      <tbody><tr>
                        <td className="v"><center><span className="qkeyz"><center><img className="ttt" src={QKeyzLogo}/></center></span></center></td>
                        <td><center><span className="keyid"><p className="h">{keyPair[1].code}</p></span></center></td>
                        <td className="y" align="right"><QRCode renderAs="svg" size={116} value={qrCode1} /></td>
                      </tr></tbody>
                    </table>
                  </td>
                </tr></tbody>
              </table>
             </td>
          </tr></tbody>
          </>
        )


} else {

  return (
    <>
    <tbody><tr className="keepTogether"><td className="location" colSpan="1"><p className="g"><b>Location:</b> {keyPair[0].locus}</p></td><td></td><td className="location" colSpan="1"></td></tr></tbody>

    <tbody><tr><td className="x">
      <table className="b">
        <tbody><tr>
          <td><center><p className="e">This key belongs to<br/><b>{this.props.org.orgName}</b><br/>If found please call<br/><b>{this.props.org.contactPhone}</b></p></center></td>
          <td className="z"></td>
          <td>
            <table className="c">
              <tbody><tr>
                <td className="v"><center><span className="qkeyz"><center><img className="ttt" src={QKeyzLogo}/></center></span></center></td>
                <td><center><span className="keyid"><p className="h">{keyPair[0].code}</p></span></center></td>
                <td className="y" align="right"><QRCode renderAs="svg" size={116} value={qrCode0} /></td>
              </tr></tbody>
            </table>
          </td>
        </tr></tbody>
      </table>
     </td>
     <td>&nbsp;</td>
     <td>
      <table className="b">
        <tbody><tr>
          <td></td>
          <td className="z"></td>
          <td>
            <table className="c">
              <tbody><tr>


              </tr></tbody>
            </table>
          </td>
        </tr></tbody>
      </table>
     </td>
  </tr></tbody>
  </>
)




}



}   )}




  </table>












      </div>
    );
  }
}
