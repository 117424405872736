import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import PlaceIcon from '@material-ui/icons/PlaceRounded';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import RegisteredIcon from '@material-ui/icons/AssignmentTurnedInRounded';
import NotRegisteredIcon from '@material-ui/icons/AssignmentLateRounded';
import Avatar from '@material-ui/core/Avatar';



const styles = theme => ({
  card: {


  },
  details: {

  },
  header: {
    display: 'flex',
    alignItems: 'column',
  },
  content: {

  },
  cover: {
    maxWidth: 300,
    height: 50,
    marginTop: 20,
    marginLeft: 20,
    marginBottom: 20,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

function renderLogo(logo, classes) {
  return(

    <CardMedia
      className={classes.cover}
      image={logo}
      title=""
    />


  )
}
function UserCard(props) {
  const {classes, theme, title, subtitle, logo, registered, info1, info2, notes, created, updated, colour} = props;
  return (
    <Card raised={false} className={classes.card} style={{backgroundColor: colour}}>
    <CardHeader  style={{minWidth: "600px", paddingRight: "100px"}}
   avatar={logo &&

     <img src={logo} height='80px'/>

 }
   title={<Typography variant="h5">{title}</Typography>}
   subheader={<Typography variant="h6" color="textSecondary">{subtitle}</Typography>}
 />
 <CardContent className={classes.content}>
   <Typography variant="subtitle1" color="textSecondary">{info1}</Typography>
   <Typography variant="subtitle1" color="textSecondary">{info2}</Typography>
   <br/>
   <Typography variant="body2" color="textSecondary">{notes}</Typography>
   <hr/>
   <Typography variant="caption" color="textSecondary">{created}</Typography>
   <Typography variant="caption" color="textSecondary">{updated}</Typography>
 </CardContent>

 <div className={classes.details} style={{position: "absolute", right: "24px", top: "24px"}}>
     <Chip

     avatar={
             <Avatar>
               {registered ? <RegisteredIcon /> : <NotRegisteredIcon />}
             </Avatar>
           }
     label={registered ? 'registered' : 'not registered'}
     clickable={false}
     className={classes.chip}
     color={registered ? 'secondary' : 'primary'}
     />

   </div>

    </Card>
  );
}

UserCard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(UserCard);
