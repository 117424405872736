import React, { Component } from "react";
import "./ForgotPassword.css";
import { Auth, API } from "aws-amplify";
import { HelpBlock, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      code: "",
      password: "",
      password2: ""
    };
  }
  /*
  handleForgotPassword = async event => {
    event.preventDefault();
    this.setState({ isForgotPWing: true });
    Auth.forgotPassword(this.state.email)
      .then(data =>
        this.navigateToForgottenPWPage(data))
      .catch(err =>
        this.forgotPasswordError(err));
  }
  forgotPasswordError(err) {
    this.setState({ isForgotPWing: false });
    alert(err.message);
  }
  navigateToForgottenPWPage(data) {
    console.log(data);
    this.props.history.push("/forgot");
  }
    validateForgotPWForm() {
    return this.state.email.length > 0;
  }

*/
  validateForm() {
    return this.state.code.length > 0 && this.state.password.length > 0 && this.state.password2.length > 0 && this.state.password == this.state.password2;
  }

/*
  updateUserAccessed() {
    return API.put("ftc", `/users/accessed`);
  }
*/
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

handleSubmit = async event => {
  event.preventDefault();

  this.setState({ isLoading: true });
  //Auth.forgotPasswordSubmit(username, code, new_password)
  //  .then(data => console.log(data))
  //  .catch(err => console.log(err));
/*
  try {
    console.log('1111111111');
    const user = await Auth.signIn(this.state.email, this.state.password);
    console.log('2222222222222222222');
    console.log(user);
    //const userDetail = await this.getUser();
    console.log('=======================');
//console.log(userDetail);
    console.log('=======================');

    const cui = await Auth.currentUserInfo();
      console.log('......................');
    console.log(cui.attributes['custom:category']);
    console.log('......................');

*/
/*
    const result = await this.createUser({
      email: this.state.email,
      firstName: cui.attributes.given_name,
      lastName: cui.attributes.family_name,
      category: cui.attributes['custom:category'],
      categoryDesc: 'ajshgdvkadsjhg'
    });
     console.log('=======================');
   console.log(result);
    console.log('=======================');

*/
    //this.updateUserAccessed();

    /*
  var categoryDescription = "Standard User";
  const category = cui.attributes['custom:category'];
  if (category === "SUPER") {
    categoryDescription = "Super User"
  }

console.log('>>>>>>>>>>>>>>>');
    var result = await this.getUser();
    console.log(result.status);
    console.log(result);
    console.log('+++++++++++++++++');
    if (result.status == true) {
      this.updateUserAccessed();
    } else {
      await this.createUser({
        email: this.state.email,
        firstName: cui.attributes.given_name,
        lastName: cui.attributes.family_name,
        category: category,
        categoryDesc: categoryDescription
      });
    }
    result = await this.getUser();
console.log(result.item);
    this.props.userHasAuthenticated(true);
    this.props.currentUser(result.item);

    this.props.history.push("/");
  } catch (e) {
    this.setState({ isLoading: false });
    //console.log(e);
    //if (e.code == "UserNotConfirmedException") {
    //  this.setState({ isUnconfirmed: true });
    //} else {
        alert(e.message);
    //}
  }


*/

}

/*
  createUser(user) {
    return API.post("qkeyz", "/users", {
      body: user
    });
  }
*/

  getUser() {
    return API.get("qkeyz", `/users`);

  }

  render() {
    return (
      <div className="ForgotPassword">

        <form onSubmit={this.handleSubmit}>
              <h1>Forgotten Password</h1>
        <p>An email verification code has been sent to your email address. Please enter it below.</p>
          <FormGroup controlId="code" bsSize="large">
            <ControlLabel>Verification code</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.code}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>New password</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <FormGroup controlId="password2" bsSize="large">
            <ControlLabel>Confirm new password</ControlLabel>
            <FormControl
              value={this.state.password2}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
            <LoaderButton
              block
              bsSize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Submit new password"
              loadingText="Submitting password…"
            />
        </form>
      </div>
    );
  }
}
