import React, { Component, Fragment } from "react";
import {Link, withRouter } from "react-router-dom";
import RouteNavItem from "./components/RouteNavItem";
import { Nav, Navbar, NavItem } from "react-bootstrap";

import Routes from "./Routes";
import "./App.css";
import { Storage, Auth, API } from "aws-amplify";

//import FTCLogo from "./images/ftc_logo.png";
import FTCName from "./images/qkeyz_logo.svg";

import {  MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';


const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#c64400',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {

      main: '#4AA561',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffffff',
    },
    // error: will use the default color
  },
});


class App extends Component {
  constructor(props) {
  super(props);

  this.state = {
    isAuthenticated: false,
    isAuthenticating: true,
    fullLogoPath: '',
    user: null,
    userOrg: null,
    org: null
  };
}
async componentDidMount() {
  try {
    if (await Auth.currentSession()) {
      this.userHasAuthenticated(true);
      if (this.state.user === null) {
        console.log('*********************');
          let result = await this.getUser();
          console.log('*********************');
          console.log(result);
          if (result.status == false) {
            this.logout();
            return;
          }
          this.setCurrentUser(result.item);
console.log('********zzz**********');


          let result2 = await this.getUserOrg();
          let userOrgResult = result2.Items;
          console.log(userOrgResult);
          console.log('*********ddd*****');
          if (userOrgResult.length > 0) {
            console.log('a*********************');
            let uo = userOrgResult[0];
            console.log('b*********************');
            console.log(uo);
            this.setUserOrg(uo);
            console.log('c*********************');
            console.log(uo);
            const orgResult = await this.getOrg(uo.pk);
            this.setOrg(orgResult);

            if (orgResult.binaryImage) {
              this.setLogo(orgResult.binaryImage);
            }


            /*
            if(orgResult.logo && orgResult.logo !== ''){
              let logoUrl = await Storage.get(orgResult.logo);
              this.setLogo(logoUrl);
            }
            */

            //console.log('^^^^^^^^^^^^^^^^^^^^^');
            //console.log(orgResult);
          } else {
            this.setUserOrg(null);
            this.setOrg(null);
          }
      }

    }
    console.log(this.state);
  }
  catch(e) {
    if (e !== 'No current user') {
      alert(e);
    }
  }

  this.setState({ isAuthenticating: false });
}
userHasAuthenticated = authenticated => {
  this.setState({ isAuthenticated: authenticated });
}
setCurrentUser = cUser => {
  this.setState({ user: cUser });
}
setUserOrg = cUserOrg => {
  this.setState({ userOrg: cUserOrg });
}
setOrg = cOrg => {
  this.setState({ org: cOrg });
}
setLogo = orgLogo => {
  this.setState({ fullLogoPath: orgLogo });
}
handleLogout = event => {
  this.logout();
}
async logout() {
  await Auth.signOut();

  this.userHasAuthenticated(false);
  this.setCurrentUser(null);
  this.setUserOrg(null);
  this.setOrg(null);
  this.props.history.push("/login");
}
handleAccount = async event => {
  this.props.history.push("/account");
}

  getUser() {
    return API.get("qkeyz", `/users`);

  }
  getUserOrg() {
    return API.get("qkeyz", `/users/organisation`);

  }
  getOrg(orgId) {
    return API.get("qkeyz", `/organisations/${orgId}`);
  }




render() {
  const childProps = {
    isAuthenticated: this.state.isAuthenticated,
    userHasAuthenticated: this.userHasAuthenticated,
    setCurrentUser: this.setCurrentUser,
    user: this.state.user,
    setUserOrg: this.setUserOrg,
    userOrg: this.state.userOrg,
    setOrg: this.setOrg,
    org: this.state.org
  };

/*
            <Navbar.Text>
                {this.state.org ?  <a href="#"><img src={"https://s3.amazonaws.com/ftc-app-uploads/public/" + this.state.org.logo} weight="30" height="30"/>&nbsp;{this.state.org.orgName}</a> : ""}
            </Navbar.Text>

            <Link to="/" style={{height:30}}><img src={"https://s3.amazonaws.com/ftc-app-uploads/public/" + this.state.org.logo} weight="20" height="20"/></Link>
            <Link to="/" style={{paddingLeft: 8, paddingRight: 8, height: 14}}>{this.state.org.orgName}</Link>

 style={{marginLeft:'0', paddingLeft:'0'}}



         <Navbar.Header >
          <Navbar.Brand style={{padding: 9}}>
            <Link to="/"><img src={FTCLogo}/></Link>
            <Link to="/" style={{paddingLeft: 8, paddingRight: 200}}><img src={FTCName}/></Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>

                     <Link to="https://www.birdlife.org"  style={{height:29}}><img src={"https://s3.amazonaws.com/ftc-app-uploads/public/" + this.state.org.logo} weight="20" height="20"/></Link>
            <Link to="https://www.birdlife.org" style={{paddingLeft: 4, verticalAlign: 'middle'}}>{this.state.org.orgName}</Link>


*/
//console.log(this.state.user);

//"https://s3.amazonaws.com/ftc-app-orguploads/public/" + this.state.org.logo

//console.log("APP.JS");
//console.log(this.state.org);
//console.log(this.state.user);
//console.log(this.state.userOrg);
const isVisitor = this.state.userOrg && this.state.userOrg.entity == "VIS";
console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>: " + " " + isVisitor);
  return (
    !this.state.isAuthenticating &&
    <MuiThemeProvider theme={theme}>
    <div className="App container">
      <Navbar fixedTop collapseOnSelect>
        <Navbar.Header >
          <Navbar.Brand style={{position: 'absolute', left:40, padding: 6}}>
            <Link to="/"  ><img height="36px" src={FTCName}/></Link>
            <Link to="/" style={{paddingLeft: 8, paddingRight: 8}}></Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>







        <Navbar.Collapse style={{position: 'absolute', right:40}}>
          {this.state.isAuthenticated ? this.state.org && !isVisitor ?
                <div className='orgBox'>
                <img src={this.state.org.binaryImage} weight="20" height="20"/>
                <span style={{paddingLeft: 4, verticalAlign: 'middle',color: '#C60000'}}>{this.state.org.orgName + ' - ' + this.state.user.firstName + ' ' + this.state.user.lastName}</span>
            </div> :
                <div className='orgBox'>
                <span style={{paddingLeft: 4, verticalAlign: 'middle', color: '#C60000'}}>{this.state.user.firstName + ' ' + this.state.user.lastName + (this.state.userOrg && this.state.userOrg.orgName ? " - " + this.state.userOrg.orgName : '')}</span>
              </div>


         : ''}

           <Nav pullRight >
           {this.state.isAuthenticated
                ?
                [
                    <NavItem key={1} onClick={this.handleAccount}>Account</NavItem>,
                    <NavItem key={2} onClick={this.handleLogout}>Logout</NavItem>
                    ]



                : [
                    <RouteNavItem key={1} href="/signup">
                      Signup
                    </RouteNavItem>,
                    <RouteNavItem key={2} href="/login">
                      Login
                    </RouteNavItem>,
                  ]}



          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div style={{display: 'flex', alignItems: 'center', display: '-webkit-flex', WebkitAlignItems: 'center', borderTop: '1px solid #ddd', zIndex: 999, height: '30px', position: 'fixed', left: '0px', right: '0px', bottom: '0px', backgroundColor: '#F5F5F5'}}>
      <div style={{paddingRight:'10px', width:'100%', fontSize:'8pt', textAlign:'right', fontFamily:"'HelveticaNeue-Light', 'Arial'", color: '#6688aa'}}>
  			Copyright © 2019 QKeyz.com. All rights reserved.
  		</div>

      </div>

      <Routes childProps={childProps} />

    </div>
    </MuiThemeProvider>
  );
}



}
export default withRouter(App);
