import React, { Component } from "react";
import "./Login.css";
import { Auth, API } from "aws-amplify";
import { HelpBlock, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUnconfirmed: false,
      isUnverified: false,
      verificationCode: "",
      isLoading: false,
      isForgotPWing: false,
      email: "",
      code: "",
      password: "",
      password2: "",
      forgotData: null,
      currentAuthUser: null
    };
  }
  handleForgotPassword = async event => {
    event.preventDefault();
    this.setState({ isForgotPWing: true });
    Auth.forgotPassword(this.state.email)
      .then(data =>
        this.forgotPasswordSuccess(data))
      .catch(err =>
        this.forgotPasswordError(err));
  }
  forgotPasswordError(err) {
    this.setState({ isForgotPWing: false });
    alert(err.message);
  }
  forgotPasswordSuccess(forgotData) {
    this.setState({ isForgotPWing: false });
    console.log(forgotData);
    this.setState({
      forgotData
    });
    //this.props.history.push("/forgot");
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }
  validateForgotPW() {
    return this.state.email.length > 0;
  }
  validateValidationCode() {
    return this.state.verificationCode.length > 0;
  }
  validateForgotPWForm() {
        return this.state.code.length > 0 && this.state.password.length > 0 && this.state.password2.length > 0 && this.state.password == this.state.password2;
  }

/*
  updateUserAccessed() {
    return API.put("qkeyz", `/users/accessed`);
  }
  */

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
handleForgotSubmit = async event => {
  event.preventDefault();

  this.setState({ isLoading: true });
  Auth.forgotPasswordSubmit(this.state.email, this.state.code, this.state.password)
    .then(data => this.forgotPasswordSubmitSuccess(data))
    .catch(err => this.forgotPasswordSubmitError(err));
}
  forgotPasswordSubmitError(err) {
    this.setState({ isLoading: false });
    alert(err.message);
  }
  forgotPasswordSubmitSuccess(data) {
    this.setState({ isLoading: false });
    console.log(data);
    alert("New password reset successfully!");
    this.setState({
      forgotData: null,
      password: "",
      password2: "",
      code: ""
    });
    //this.props.history.push("/forgot");
  }
handleResubmitVerificationLink = async event => {
  event.preventDefault();
  Auth.resendSignUp(this.state.email);
  alert('Successfully resent verification link. When you receive this in your email please click on the link then try logging in again.');
  this.setState({
    isUnconfirmed: false,
    code: "",
    password: "",
    password2: "",
    forgotData: null
  });
}
handleReturnToLogin = async event => {
  event.preventDefault();
  this.setState({
    isUnconfirmed: false,
    code: "",
    password: "",
    password2: "",
    forgotData: null
  });
}


  sendVerifyError(err) {
    this.setState({
      isLoading: false,
      isUnverified: false
    });
    console.log(err);
    alert("There is a problem logging in. Please try again later.");
  }
  sendVerifySuccess(data) {
    this.setState({
      isLoading: false,
      isUnverified: true
    });
  }
handleVerifyEmail = async event => {
  event.preventDefault();
  this.setState({ isLoading: true });
  Auth.verifyUserAttributeSubmit(this.state.currentAuthUser, 'email', this.state.verificationCode)
    .then(data => this.validationSubmitSuccess(data))
    .catch(err => this.validationSubmitError(err));
}
  validationSubmitError(err) {
    this.setState({ isLoading: false });
    alert(err.message);
    this.setState({
      isUnverified: false,
      password: "",
      password2: "",
      code: ""
    });
  }
  validationSubmitSuccess(data) {
    this.setState({
      isLoading: true,
      isUnverified: false,
      verificationCode: "",
      password2: "",
      code: ""
    });
    this.attemptUserLogin();
  }
  attemptUserLogin = async event =>  {

  try {
    const user = await Auth.signIn(this.state.email, this.state.password);
    const cui = await Auth.currentUserInfo();
    if (!cui.attributes.email_verified) {
      Auth.currentAuthenticatedUser()
          .then(user => {
            this.setState({
              currentAuthUser: user
            });
            Auth.verifyUserAttribute(user, 'email');
          })
          .then(data => {
            this.sendVerifySuccess(data);
          })
          .catch(err => {
            this.sendVerifyError(err);
          });
      await Auth.signOut();
    } else {
      var categoryDescription = "Standard User";
      const category = cui.attributes['custom:category'];
      if (category === "SUPER") {
        categoryDescription = "Super User"
      }
console.log("111");
      var result = await this.getUser();
console.log("222");
      if (result.status == true) {
        //this.updateUserAccessed(); //disused
      } else {

console.log("CREATE USER:");
console.log(this.state.email);
console.log(cui.attributes['custom:firstname']);
console.log(cui.attributes['custom:lastname']);
console.log(category);
console.log(categoryDescription);


        result = await this.createUser({
          email: this.state.email,
          firstName: cui.attributes['custom:firstname'],
          lastName: cui.attributes['custom:lastname'],
          category: category,
          categoryDesc: categoryDescription
        });

console.log(result);

        result = await this.getUser();
      }
      console.log("333");

      console.log("3a3a3a");
      this.props.setCurrentUser(result.item);
      console.log("444");

      console.log(this.props);








      // getEmpOrgs

      // if more than one then return list : then user chooses and retains a list of their orgs: then new screen and process to switch between orgs they are employed by and run new script which continues below
      //NOTE user may choose NONE to be able to login as a contractor.

      // ELSE if zero or one company then continue as below:



// Regardless, on the web app there chould be a page accessable by ANYONE to be able to signin/signout a key for THEMSELVES which also lists current keys held (showing there details incl the company they are owned by)


      let result = await this.getUserOrg();
      // note: should really check for 'more' and set ExclusiveStartKey etc BUT need to set query params too in request
      let userOrgResult = result.Items;
      console.log("555");

      if (userOrgResult.length > 0) {
        let uo = userOrgResult[0];
        this.props.setUserOrg(uo);
        console.log(this.props);
        console.log('*********************');
        console.log(uo);
        console.log("666");

        const orgResult = await this.getOrg(uo.pk);
        console.log("777");

        this.props.setOrg(orgResult);
        console.log('^^^^^^^^^^^^^^^^^^^^^');
        console.log(orgResult);
        console.log("888");








      } else {
        this.props.setUserOrg(null);
        this.props.setOrg(null);













      }



      console.log("999");


     let uOrg = this.props.userOrg;
     console.log("aaa");

      console.log(this.props);
      console.log("bbb");

    //this.props.history.push("/");
    console.log("ccc");

      this.props.userHasAuthenticated(true);





    }
  } catch (e) {
    this.setState({ isLoading: false });
    console.log(e);
    if (e.code == "UserNotConfirmedException") {
      this.setState({ isUnconfirmed: true });
    } else {
        alert(e.message);
    }
  }

}
handleSubmit = async event => {
  event.preventDefault();

  this.setState({ isLoading: true });

  try {
    await this.attemptUserLogin();
  } catch (e) {
    this.setState({ isLoading: false });
    console.log(e);
    if (e.code == "UserNotConfirmedException") {
      this.setState({ isUnconfirmed: true });
      //console.log("resending...")
      //Auth.resendSignUp(this.state.email);
      //console.log("RESENT")
    } else {
        alert(e.message);
    }
  }
}


  createUser(user) {
    return API.post("qkeyz", "/users", {
      body: user
    });
  }


  getUser() {
    return API.get("qkeyz", `/users`);

  }

  getUserOrg() {
    return API.get("qkeyz", `/users/organisation`);

  }
  getOrg(orgId) {
    return API.get("qkeyz", `/organisations/${orgId}`);
  }





  renderUnconfirmedForm() {
    return (

        <form onSubmit={this.handleResubmitVerificationLink}>
              <h1>Confirm Account</h1>
        <p>An email verification link has been sent to your email address. Please click on the link in the email to confirm your account.</p>
        <p>If you would like to have the verification link resent to your email please click on the following 'Reset Link' button:</p>

            <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              disabled={false}
              type="submit"
              isLoading={this.state.isLoading}
              text="Resend Link"
              loadingText="Sending…"
            />
          <LoaderButton
            block
            bsSize="large"
            disabled={false}
            isLoading={false}
            text="Return to Login"
            onClick={this.handleReturnToLogin}
            loadingText="..."
          />

        </form>
          );
  }

  renderUnverifiedForm() {
    return (

        <form onSubmit={this.handleVerifyEmail}>
          <h1>Verify New Email Address</h1>
          <p>An email verification code has been sent to your email address. Please enter it below.</p>
          <FormGroup controlId="verificationCode" bsSize="large">
            <ControlLabel>Verification code</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.verificationCode}
              onChange={this.handleChange}
            />
          </FormGroup>

            <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              disabled={!this.validateValidationCode()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Verify Email"
              loadingText="Verifying…"
            />

        </form>
          );
  }

  renderForm() {
    return (
      //<div className="Login">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel>Email</ControlLabel>
            <FormControl
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
            <LoaderButton
              block
              bsSize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Login"
              loadingText="Logging in…"
            />
          <LoaderButton
            block
            disabled={!this.validateForgotPW()}
            bsStyle="danger"
            bsSize="small"
            isLoading={this.state.isForgotPWing}
            onClick={this.handleForgotPassword}
            text="Forgot password?"
            loadingText="Sending code…"
          />
        </form>
      //</div>
    );
  }
  renderForgotForm() {
    return (
      //<div className="ForgotPassword">

        <form onSubmit={this.handleForgotSubmit}>
              <h1>Forgotten Password</h1>
        <p>An email verification code has been sent to your email address. Please enter it below.</p>
          <FormGroup controlId="code" bsSize="large">
            <ControlLabel>Verification code</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.code}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>New password</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <FormGroup controlId="password2" bsSize="large">
            <ControlLabel>Confirm new password</ControlLabel>
            <FormControl
              value={this.state.password2}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
            <LoaderButton
              block
              bsSize="large"
              disabled={!this.validateForgotPWForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Submit new password"
              loadingText="Submitting password…"
            />
        </form>
      //</div>
    );
  }
  render() {
    return (
      <div className="Login">
        {this.state.forgotData === null
          ? (this.state.isUnconfirmed ? this.renderUnconfirmedForm() : (this.state.isUnverified ? this.renderUnverifiedForm() : this.renderForm()))
          : this.renderForgotForm()}
      </div>
    );
  }





}
