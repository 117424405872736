import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel , Breadcrumb } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./NewOrg.css";
import { API } from "aws-amplify";
import { s3Upload } from "../libs/awsLib";
import Checkbox from "./Checkbox";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default class NewOrgUser extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      alertOpen: false,
      alertType: '',
      isLoading: null,
      org: null,
      oId: "",
      oName: "",
      firstName: "",
      lastName: "",
      email: "",
      role_short: "RO",
      roles: [{value: 'VIEW_ANY', title: 'View Any'},
              {value: 'CREATE', title: 'Create'},
              {value: 'EDIT_ANY', title: 'Edit Any'},
              {value: 'ADMIN', title: 'Admin'}
            ]

    };
  }
  componentWillMount = () => {
    this.selectedCheckboxes = new Set();
  }


  async componentDidMount() {


  if (!this.props.isAuthenticated) {
    return;
  }
    const user = this.props.user;
    const { category } = user;
    if (category != 'SUPER') {
      this.props.history.push("/account");
      return;
    }

    try {
      const org = await this.getOrg();
      const { pk, orgName } = org;
        console.log(org);
      this.setState({
        org,
        oId: pk,
        oName: orgName
      });
    } catch (e) {
      alert(e);
    }
  }



  getOrg() {
    return API.get("qkeyz", `/organisations/${this.props.match.params.id}`);
  }

  validateForm() {
    return this.state.firstName.length > 0 && this.state.lastName.length > 0 && this.state.email.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }


handleSubmit = async event => {
  event.preventDefault();


  this.setState({ isLoading: true });

  try {

    for (const checkbox of this.selectedCheckboxes) {
      console.log(checkbox, 'is selected.');


    }

      //let cat = this.state.categories.find(o => o.value === this.state.category_short);


    let result = await this.createUser({
      orgId: this.state.oId,
      entity: 'EMP',
      category: 'EMP',
      categoryDesc: 'Employee',
      orgName: this.state.oName,
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      userRoles: Array.from(this.selectedCheckboxes)
    });

    console.log(result);
    if (result.status == 'false' && result.reason == 'exists') {
      this.setState({
        isLoading: false,
        alertOpen: true,
        alertType: 'exists'
      });
    } else {
      this.props.history.push("/organisations/" + this.state.oId);
    }

  } catch (e) {
    alert(e);
    this.setState({ isLoading: false });
  }
}


createUser(user) {
  user.userName = this.props.user.firstName + " " + this.props.user.lastName;
  console.log('create user wtf');
  console.log(user);
  return API.post("qkeyz", "/organisations/users", {
    body: user
  });
}
handleAlertClose = () => {
    this.setState({ alertOpen: false, alertType: '' });
};

handleCrumbClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}

  createCheckbox = role => (
    <Checkbox
            label={role.title}
            handleCheckboxChange={this.toggleCheckbox}
            key={role.value}
            othkey={role.value}
        />
  )

  createCheckboxes = () => (
    this.state.roles.map(this.createCheckbox)
  )
  toggleCheckbox = role => {
    if (this.selectedCheckboxes.has(role.value)) {
      this.selectedCheckboxes.delete(role.value);
    } else {
      this.selectedCheckboxes.add(role.value);
    }
  }

  render() {
    return (
      <div className="NewOrg">
        <Breadcrumb>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/account">Account</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/organisations">Organisations</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href={"/organisations/" + this.state.oId}>{this.state.oName}</Breadcrumb.Item>
          <Breadcrumb.Item active>Add User</Breadcrumb.Item>
        </Breadcrumb>
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="email">
          <ControlLabel>Email</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.email}
              componentClass="input"
              type="text"
            />
            </FormGroup>
            <FormGroup controlId="firstName">
          <ControlLabel>First Name</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.firstName}
              componentClass="input"
              type="text"
            />
            </FormGroup>
            <FormGroup controlId="lastName">
         <ControlLabel>Last Name</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.lastName}
              componentClass="input"
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="role_short">
          <ControlLabel>Roles</ControlLabel>
           {this.createCheckboxes()}
          </FormGroup>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
        </form>
        <Dialog
            open={this.state.alertOpen && this.state.alertType != ''}
            onClose={this.handleAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{this.state.alertType == 'exists' ? 'User Exists' : ''}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              {this.state.alertType == 'exists' ? 'A user with the given email already exists.' : ''}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleAlertClose} color="secondary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>

      </div>
    );
  }
}
