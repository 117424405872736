import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem, Breadcrumb} from "react-bootstrap";
import "./Organisations.css";
import { API } from "aws-amplify";

export default class Organisations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      orgs: []
    };
  }
async componentDidMount() {
  if (!this.props.isAuthenticated) {
    return;
  }

  try {
    const user = this.props.user;
    const { category } = user;
    if (category != 'SUPER') {
      this.props.history.push("/account");
      return;
    }
    const result = await this.orgs();
    /// need to test for 'MORE' and use esk etc...
    const orgs = result.Items;
    this.setState({ orgs });
  } catch (e) {
    alert(e);
  }

  this.setState({ isLoading: false });
}

orgs() {
  return API.get("qkeyz", "/organisations");
}

handleOrgClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}
handleCrumbClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}

renderOrgsList(orgs) {
  return [{}].concat(orgs).map(
    (org, i) =>
      i !== 0
        ? <ListGroupItem
            key={org.pk}
            href={`/organisations/${org.pk}`}
            onClick={this.handleOrgClick}
            header={org.orgName}
          >
            {org.categoryDesc + "   Created: " + new Date(org.created).toLocaleString()}
          </ListGroupItem>
        : <ListGroupItem
            key="new"
            href={"/organisations/new"}
            onClick={this.handleOrgClick}
          >
            <h4>
              <b>{"\uFF0B"}</b> New Organisation
            </h4>
          </ListGroupItem>
  );
}



  renderLander() {
    return (
      <div className="lander">
        <h1>Organisations</h1>
        <p>Manage organisations here</p>
      </div>
    );
  }
renderOrgs() {
    return (
      <div className="orgs">
        <PageHeader>Organisations</PageHeader>
        <ListGroup>
          {!this.state.isLoading && this.renderOrgsList(this.state.orgs)}
        </ListGroup>





      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        <Breadcrumb>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/account">Account</Breadcrumb.Item>
          <Breadcrumb.Item active>Organisations</Breadcrumb.Item>
        </Breadcrumb>
        {this.props.isAuthenticated ? this.renderOrgs() : this.renderLander()}
      </div>
    );
  }
}
