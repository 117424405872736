import React, { Component } from "react";
import { Checkbox, Glyphicon, InputGroup, Button, FormGroup, FormControl, ControlLabel, Breadcrumb } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./NewOrgEmployee.css";
import { API, Storage } from "aws-amplify";
import { s3UploadBlobAsPng, s3UploadBlob } from "../libs/awsLib";


//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';


import loaderIcon from'../images/qkeyz.gif';

import Webcam from "react-webcam";

import CameraIcon from '@material-ui/icons/CameraRounded';



export default class NewOrgEmployee extends Component {
  setRef = webcam => {
    this.webcam = webcam;
  };
  constructor(props) {
    super(props);

console.log(props);

    this.file = null;
    const isEditing = this.props.location.state && this.props.location.state.employeeId;
    this.state = {
      isEditing: false,
      isLoading: isEditing,
      isSaving: null,
      employee: null,
      email: "",
      firstName: "",
      lastName: "",
      contactPhone: "",
      notesAdmin: "",
      admin: false,
      adminOrig: false,
      userRoles: [],

      //photo: "",
      //attachmentURL: null,
      //cameraImage: null,


      alertOpen: false,
      cameraOpen: false,
      binaryImage: null
    };
}

  async componentDidMount() {
    if (!this.props.isAuthenticated || !this.props.org) {
      this.props.history.push("/");
      return;
    }


    //var pId;
    if (this.props.location.state) {
      if (this.props.location.state.employeeId) {
        this.eId = this.props.location.state.employeeId

         try {
          let attachmentURL;
          const employee = await this.getEmployee(this.eId);
          const { binaryImage, firstName, lastName, email, userRoles, contactPhone, notesAdmin } = employee;
          //if (photo) {
          //  attachmentURL = await Storage.get(photo);
          //}
          let isadmin = userRoles.indexOf('ADMIN') >= 0;
          this.setState({
            isEditing: true,
            isLoading: false,
            employee,
            firstName: firstName,
            lastName: lastName,
            email: email,
            admin: isadmin,
            adminOrig: isadmin,
            userRoles: userRoles,
            //photo: photo,
            //attachmentURL,
            //cameraImage: attachmentURL,
            contactPhone: contactPhone,
            notesAdmin: notesAdmin,
            binaryImage: binaryImage
          });
        } catch (e) {
          alert(e);
        }


      }
    }




  }
  getEmployee(employeeId) {
    return API.get("qkeyz", `/organisations/${this.props.org.pk}/EMP/${employeeId}`);
  }


  updateEmployee(employeeId, body) {
    try{
      body.userName = this.props.user.firstName + " " + this.props.user.lastName;
      console.log('body');
      console.log(body);
      return API.put("qkeyz", `/organisations/${this.props.org.pk}/users/${employeeId}`, {body: body});
    }catch(e) {
      console.log(e);
    }
  }

validateChangesOnly() {
  console.log(this.file, this.state.employee.photo);

  return this.validateForm() && (this.state.firstName !== this.state.employee.firstName || this.state.lastName !== this.state.employee.lastName || this.state.binaryImage != this.state.employee.binaryImage || this.state.admin !== this.state.adminOrig || this.file != null || (this.state.cameraImage != null && this.state.photo == null) || this.state.email !== this.state.employee.email || this.state.userRoles !== this.state.employee.userRoles|| this.state.contactPhone !== this.state.employee.contactPhone || this.state.notesAdmin !== this.state.employee.notesAdmin);
}

  validateForm() {
    const t = this.state.firstName.trim();
    const a = this.state.lastName.trim();
    const e = this.state.email.trim();

    return a.length > 0 && t.length > 0 && e.length > 0;
  }

handleReset = event => {
  event.preventDefault();
  this.file = null;
  this.inputEl.value='';
  this.setState({
    firstName: this.state.employee.firstName,
    lastName: this.state.employee.lastName,
    email: this.state.employee.email,
    userRoles: this.state.employee.userRoles,
    admin: this.state.adminOrig,
    //photo: this.state.employee.photo,
    //cameraImage: this.state.attachmentURL,
    contactPhone: this.state.employee.contactPhone,
    notesAdmin: this.state.employee.notesAdmin,
    binaryImage: this.state.visitor.binaryImage
  });


}


handleChange = event => {
  this.setState({
    [event.target.id]: event.target.value
  });
}
handleCBChange = event => {
  let newState = !this.state.admin;
  let newRoles = this.state.userRoles;
  //console.log('beforex: ', newState, newRoles);

  let idx = newRoles.indexOf('ADMIN');
  if (newState) {
    if (idx < 0) {
      newRoles.push('ADMIN');
    }
  } else {
    if (idx >= 0) {
      newRoles.splice(idx, 1);
    }
  }
//console.log('beforey: ', newState, newRoles);

    this.setState({
      admin: newState, userRoles: newRoles
    });


  //console.log('after: ', this.state.admin, this.state.userRoles);



}
handleFileChange = async event => {
  event.preventDefault();
  this.file = event.target.files[0];
  if (this.file && this.file.type.indexOf("image") == -1) {
    this.file = null;
    this.inputEl.value='';
    this.setState({
      alertOpen: true,
      alertTitle: 'Invalid Image Format',
      alertMessage: 'Please choose a valid image for the employee pic.'
    });
  } else {
    if (this.file) {
      const base64 = await this.getReducedImageAsBase64(this);
      console.log('base64:' + base64);
      this.setState({ binaryImage: base64});
    }
  }

}


/*
  handleFileChange = async event => {
    event.preventDefault();
    this.file = event.target.files[0];
    if (this.file && this.file.type.indexOf("image") == -1) {
      this.file = null;
      this.inputEl.value='';
      this.setState({
        alertOpen: true,
        alertTitle: 'Invalid Image Format',
        alertMessage: 'Please choose a valid image for the employee pic.'
      });
    } else {
      if (this.file) {
        const base64 = await this.getReducedImageAsBase64(this);
        console.log(base64);
        this.setState({ cameraImage: base64, photo: null});
      }
    }

  }

  */

handleSubmit = async event => {
  event.preventDefault();

  if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
    this.setState({
      alertOpen: true,
      alertTitle: 'Invalid Image File Size',
      alertMessage: 'Please choose a file smaller than 5MB.'
    });
    return;
  }

  this.setState({ isSaving: true });

  try {

    /*
    console.log("+++");
    let attachment = null;
    if (this.file) {
      const blob = await this.getReducedImageAsBlob(this);
      //let orgId = this.props.org.pk;
      attachment = await s3UploadBlob('users', this.file, blob, 'photos');
      console.log(attachment);
      console.log("***");
    } else if (this.state.cameraImage && !this.state.photo) {
      const blob = this.base64toBlob(this.state.cameraImage);
      //let orgId = this.props.org.pk;
      attachment = await s3UploadBlobAsPng('users', blob, 'photos');
    }




    //const attachment = this.file
    //  ? await this.uploadReducedImage(this, this.file)
    //  : null;
      console.log("---");

    */

    if (this.state.isEditing) {
      console.log('this.updateEmployee:');
      await this.updateEmployee(this.eId, {
        firstName: this.state.firstName.trim(),
        lastName: this.state.lastName.trim(),
        email: this.state.email.trim(),
        //photo: attachment ? attachment : this.state.photo,
        userRoles: this.state.userRoles ? this.state.userRoles : null,
        contactPhone: this.state.contactPhone ? this.state.contactPhone : null,
        notesAdmin: this.state.notesAdmin ? this.state.notesAdmin : null,
        binaryImage: this.state.binaryImage ? this.state.binaryImage : null
      });
      this.props.history.push("/employees");
    } else {
      console.log('this.createEmployee:');
      //console.log(attachment);
      let createResult = await this.createEmployee({
        orgId: this.props.org.pk ,
        entity: 'EMP',
        category: 'EMP',
        categoryDesc: 'Employee',
        firstName: this.state.firstName.trim(),
        lastName: this.state.lastName.trim(),
        email: this.state.email.trim(),
        userRoles: this.state.userRoles ? this.state.userRoles : null,
        contactPhone: this.state.contactPhone ? this.state.contactPhone : null,
        notesAdmin: this.state.notesAdmin ? this.state.notesAdmin : null,
        binaryImage: this.state.binaryImage ? this.state.binaryImage : null
      });
      if (createResult.status == false && createResult.error == 'exists') {
        this.setState({
          alertOpen: true,
          alertTitle: 'User Exists',
          alertMessage: 'A user with this email already exists as an employee.',
          isSaving: false
        });
      } else {
        this.props.history.push("/employees");
      }
    }
  } catch (e) {
    this.setState({
      alertOpen: true,
      alertTitle: 'Error',
      alertMessage: e.message,
      isSaving: false
    });
  }
}
/*
getReducedImageAsBase64(context) {
  var reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(null);
    };
    reader.onload = () => {
      var img = new Image();
      img.onload = function() {
          console.log('IMG: ', img.height, img.width);
          let h = 200;
          let w = 200;
          if (img.height > img.width) {
            w = w * (img.width / img.height);
          } else {
            h = h * (img.height / img.width);
          }
          var oc = document.createElement('canvas');
          oc.width = w;
          oc.height = h;
          oc.getContext('2d').drawImage(img, 0, 0, w, h);
          var dataurl = oc.toDataURL(context.file.type);
          console.log(dataurl);
          //var blob = context.dataURItoBlob(dataurl, context.file.type);
          resolve(dataurl);
        }
      img.src = reader.result
      };
    reader.readAsDataURL(context.file);
  });
}
*/

getReducedImageAsBase64(context) {
  var reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(null);
    };
    reader.onload = () => {
      var img = new Image();
      img.onload = function() {
          console.log('IMG: ', img.height, img.width);
          let h = 200;
          let w = 200;
          if (img.height > img.width) {
            w = w * (img.width / img.height);
          } else {
            h = h * (img.height / img.width);
          }
          var oc = document.createElement('canvas');
          oc.width = w;
          oc.height = h;
          var ctx = oc.getContext('2d');
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, w, h);
          ctx.drawImage(img, 0, 0, w, h);
          var dataurl = oc.toDataURL('image/jpeg'); //context.file.type
          resolve(dataurl);
        }
      img.src = reader.result
      };
    reader.readAsDataURL(context.file);
  });
}


/*
getReducedImageAsBlob(context) {
  var reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(null);
    };
    reader.onload = () => {
      var img = new Image();
      img.onload = function() {
          console.log('IMG: ', img.height, img.width);
          let h = 200;
          let w = 200;
          if (img.height > img.width) {
            w = w * (img.width / img.height);
          } else {
            h = h * (img.height / img.width);
          }
          var oc = document.createElement('canvas');
          oc.width = w;
          oc.height = h;
          oc.getContext('2d').drawImage(img, 0, 0, w, h);
          var dataurl = oc.toDataURL(context.file.type);
          //console.log(dataurl);
          var blob = context.dataURItoBlob(dataurl, context.file.type);
          resolve(blob);
        }
      img.src = reader.result
      };
    reader.readAsDataURL(context.file);
  });
}

dataURItoBlob(dataURI, fileType) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: fileType});
}
base64toBlob(data) {
  const dataArr = data.split(',');
  var fileType = dataArr[0];
    var binary = atob(dataArr[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: fileType});
}
*/

createEmployee(employee) {
  employee.userName = this.props.user.firstName + " " + this.props.user.lastName;
  return API.post("qkeyz", "/organisations/users", {
    body: employee
  });
}

handleAlertClose = () => {
    this.setState({ alertOpen: false });
};
handleCameraClose = () => {
    this.setState({ cameraOpen: false });
};
handleCrumbClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}
loadMapLink = event => {
  let url = "https://www.google.com/maps";
  if (this.state.mapLink != "") {
    url = this.state.mapLink;
  }
  window.open(url, "_blank");
}
handleShowCamera = event => {
  event.preventDefault();
  this.setState({ cameraOpen: true });
}

/*
handleCaptureImage = () => {
  const imageSrc = this.webcam.getScreenshot();
  this.setState({ cameraImage: imageSrc, cameraOpen: false, photo: null});
  this.file = null;
  this.inputEl.value='';

};
*/

handleCaptureImage = () => {
  const imageSrc = this.webcam.getScreenshot();
  this.setState({ binaryImage: imageSrc, cameraOpen: false});
  this.file = null;
  this.inputEl.value='';
};




renderFields() {
console.log('this.eId');
  console.log(this.props);
  console.log(this.eId);


  return(
    <div className="NewOrgEmployee" style={{position: 'absolute', left:80, right:80,top: 110}}>
       <form onSubmit={this.handleSubmit}>
       <FormGroup controlId="email">
       <ControlLabel>Email*</ControlLabel>
         <FormControl
           onChange={this.handleChange}
           value={this.state.email}
           disabled={this.state.isEditing}
           componentClass="input"
           type="text"
         />
       </FormGroup>
       <FormGroup controlId="firstName">
       <ControlLabel>First Name*</ControlLabel>
         <FormControl
           onChange={this.handleChange}
           value={this.state.firstName}
           componentClass="input"
           type="text"
         />
       </FormGroup>
       <FormGroup controlId="lastName">
       <ControlLabel>Last Name*</ControlLabel>
         <FormControl
           onChange={this.handleChange}
           value={this.state.lastName}
           componentClass="input"
           type="text"
         />
       </FormGroup>



       <FormGroup controlId="admin" >
         <Checkbox
                   onChange={this.handleCBChange}
                   disabled={this.state.isEditing && this.eId === this.props.user.pk}
                   checked={this.state.admin}><b>Administrator</b> (Manage employee accounts)

                 </Checkbox>
       </FormGroup>


         <FormGroup controlId="contactPhone">
         <ControlLabel>Phone Number</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.contactPhone}
             componentClass="input"
             type="text"
           />
         </FormGroup>



         <FormGroup>
           <ControlLabel>Employee Photo</ControlLabel>
         </FormGroup>
         <Paper className={"recList"}>
         <FormGroup>
           {this.state.binaryImage &&
           <FormControl.Static >
             <img  src={this.state.binaryImage} style={{maxWidth: '400px'}} height='80px' alt="Employee photo"/>
           </FormControl.Static>
           }
         </FormGroup>

                  <FormGroup controlId="file">
                  <InputGroup>
                    <FormControl inputRef={ el => this.inputEl=el }  onChange={this.handleFileChange} type="file"/>
                      </InputGroup>



                  </FormGroup>
                           <FormGroup controlId="takePic">

                                 <Button  onClick={this.handleShowCamera}><CameraIcon /> Take Pic</Button>

                           </FormGroup>


         </Paper>











         <FormGroup controlId="notesAdmin">
         <ControlLabel>Notes</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.notesAdmin ? this.state.notesAdmin : ''}
             componentClass="textarea"
           />
         </FormGroup>



         {this.state.isEditing ?
           <div>
                     <LoaderButton

           bsStyle="primary"
           bsSize="large"
           disabled={!this.validateChangesOnly()}
           type="submit"
           isLoading={this.state.isSaving}
           text="Save"
           loadingText="Saving…"
         />

         <LoaderButton


           bsSize="large"
           disabled={!this.validateChangesOnly()}
           isLoading={false}
           text="Cancel"
           onClick={this.handleReset}
           loadingText="Cancelling…"
         />
         </div>
         :
         <LoaderButton
           bsStyle="primary"
           bsSize="large"
           disabled={!this.validateForm()}
           type="submit"
           isLoading={this.state.isSaving}
           text="Create"
           loadingText="Adding new employee…"
         />}






       </form>
     </div>
  )
}


renderCamera() {
  const videoConstraints = {
     width: 200,
     height: 200,
     facingMode: "user"
   };
  return(
    <div>
       <Webcam
         audio={false}
         height={350}
         ref={this.setRef}
         screenshotFormat="image/png"
         width={350}
         videoConstraints={videoConstraints}
         />
     </div>
  );
}



  renderLoader() {
    const label = 'loading...';
    return(

      <div id="splash" style={{paddingTop: 250, zIndex:999}}>

        <center id="splash">
          <img src={loaderIcon}/>
          <Typography style={{padding: '4px'}} variant="caption">{label}</Typography>
        </center>
      </div>



    )
  }


  render() {
    let fullName = "";
    if (this.state.employee && this.state.employee.firstName && this.state.employee.lastName) {
      fullName = this.state.employee.firstName + " " + this.state.employee.lastName;
    }

    return (
      <div>
         <Breadcrumb style={{top: 58, left: 66, right: 0, position: 'fixed', zIndex:999, backgroundColor: '#FFF'}}>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/employees">Employees</Breadcrumb.Item>
          <Breadcrumb.Item active>{this.state.isLoading ? '' : fullName ? 'Edit: ' + fullName : 'Create new'}</Breadcrumb.Item>
        </Breadcrumb>

        {this.state.isLoading ? this.renderLoader() : this.renderFields()}











        <Dialog
            open={this.state.alertOpen}
            onClose={this.handleAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{this.state.alertTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.alertMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleAlertClose} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>


          <Dialog
              open={this.state.cameraOpen}
              onClose={this.handleAlertClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Take Pic of Employee</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description" >

                </DialogContentText>
                {this.state.cameraOpen ? this.renderCamera() : null}
              </DialogContent>
              <DialogActions>
              <Button onClick={this.handleCaptureImage} color="primary">
                Take Pic
              </Button>
              <Button onClick={this.handleCameraClose} color="secondary">
                Cancel
              </Button>
              </DialogActions>
            </Dialog>


      </div>

    );
  }
}
