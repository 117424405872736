import React, { Component } from "react";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Signup.css";
import { Auth, API } from "aws-amplify";

export default class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      firstName: "",
      lastName: "",
      category: "STANDARD",
      categoryDesc: "Standard User",
      newUser: null
    };
  }

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }


  handleReturnToLogin = async event => {
    event.preventDefault();
    this.props.history.push("/login");
  }

handleSubmit = async event => {
  event.preventDefault();

  this.setState({ isLoading: true });

  try {
    const newUser = await Auth.signUp({
      username: this.state.email,
      password: this.state.password,
      attributes: {
        'custom:firstname': this.state.firstName,
        'custom:lastname': this.state.lastName,
        'custom:category': this.state.category
      }
    });

    console.log(".....................");
    console.log(newUser);
    console.log(".....................");

    let confirmed = newUser.userConfirmed;
/*
    this.setState({
      newUser
    });

  await Auth.signIn(this.state.email, this.state.password);
    await this.createUser({
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      category: this.state.category,
      categoryDesc: this.state.categoryDesc
    });
*/

  this.setState({
    newUser: newUser
  });

    //this.props.history.push("/login");



  } catch (e) {

    alert(e.message);

  }

  this.setState({ isLoading: false });
}
/*
handleConfirmationSubmit = async event => {
  event.preventDefault();

  this.setState({ isLoading: true });

  try {
    await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
    await Auth.signIn(this.state.email, this.state.password);

    await this.createUser({
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      category: this.state.category,
      categoryDesc: this.state.categoryDesc
    });

    const userDetail = await this.getUser();

    this.props.userHasAuthenticated(true);
    this.props.currentUser(userDetail);
    this.props.history.push("/");
  } catch (e) {
    console.log(e);
    alert(e.message);
    this.setState({ isLoading: false });
  }
}
*/
/*
createUser(user) {
  return API.post("notes", "/users", {
    body: user
  });
}

  getUser() {
    return API.get("notes", `/users`);

  }
  */
/*
  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirmationSubmit}>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <ControlLabel>Confirmation Code</ControlLabel>
          <FormControl
            autoFocus
            type="tel"
            value={this.state.confirmationCode}
            onChange={this.handleChange}
          />
          <HelpBlock>Please check your email for the code.</HelpBlock>
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateConfirmationForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Verify"
          loadingText="Verifying…"
        />
      </form>
    );
  }
*/
renderUnconfirmedForm() {
  return (

      <form onSubmit={this.handleResubmitVerificationLink}>
            <h1>Confirm Account</h1>
      <p>Thank you, a verification link has been sent to your email address.</p>
      <p>To complete the registration process please click on the verification link to confirm your email address.</p>
        <LoaderButton
          block
          bsSize="large"
          disabled={false}
          isLoading={false}
          text="Return to Login"
          onClick={this.handleReturnToLogin}
          loadingText="..."
        />

      </form>
        );
}


render() {
  return (
    <div className="Signup">
      {this.state.newUser === null ? this.renderSignup() : this.renderUnconfirmedForm()}
    </div>
  );
}


  renderSignup() {
    return (
      <div className="Signup">
      <form onSubmit={this.handleSubmit}>
        <FormGroup controlId="firstName" bsSize="large">
          <ControlLabel>First Name</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={this.state.firstName}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="lastName" bsSize="large">
          <ControlLabel>Last Name</ControlLabel>
          <FormControl
            type="text"
            value={this.state.lastName}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            value={this.state.password}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" bsSize="large">
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl
            value={this.state.confirmPassword}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Signup"
          loadingText="Signing up…"
        />
      </form>
      </div>
    );
  }
  /*
  render() {
    return (
      <div className="Signup">
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderConfirmationForm()}
      </div>
    );
  }
  */

}
