import React, { Component } from "react";
import { Glyphicon, InputGroup, Button, FormGroup, FormControl, ControlLabel, Breadcrumb } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./NewProperty.css";
import { API, Storage } from "aws-amplify";
import { s3UploadBlob } from "../libs/awsLib";

import uuid from "uuid";
//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';


import loaderIcon from'../images/qkeyz.gif';


export default class NewProperty extends Component {
  constructor(props) {
    super(props);

console.log(props);

    this.file = null;
    const isEditing = this.props.location.state && this.props.location.state.propertyId;
    this.state = {
      isEditing: false,
      isLoading: isEditing,
      isSaving: null,
      property: null,
      title: "",
      logo: null,
      category: "NONE",
      categoryDesc: "",
      description: "",
      address: "",
      contactName: "",
      contactNumber: "",
      mapLink: "",
      adminNotes: "",
      contractorNotes: "",
      photo: "",
      attachmentURL: null,
      alertOpen: false,
      categories: [{value: 'NONE', title: 'select...'},
          {value: 'HOUSE', title: 'Homestead'},
          {value: 'HOTEL', title: 'Hotel'},
          {value: 'APARTMENTS', title: 'Apartments'},
          {value: 'FLATS', title: 'Flats'},
          {value: 'OFFICE', title: 'Offices'},
          {value: 'RETAIL', title: 'Retail Centre'},
          {value: 'COMMERCIAL', title: 'Other Commercial'}
        ]
    };
}

  async componentDidMount() {
    if (!this.props.isAuthenticated || !this.props.org) {
      this.props.history.push("/");
      return;
    }


    //var pId;
    if (this.props.location.state) {
      if (this.props.location.state.propertyId) {
        this.pId = this.props.location.state.propertyId

         try {
          let attachmentURL;
          const property = await this.getProperty(this.pId);
          const {binaryImage, title, photo, category, categoryDesc, description, address, contactName, contactPhone, mapLink, notesAdmin, notesContractor } = property;


          console.log(property);
          if (photo) {
            attachmentURL = await Storage.get(photo);
          }
          this.setState({
            isEditing: true,
            isLoading: false,
            property,
            title: title,
            category: category,
            categoryDesc: categoryDesc,
            logo: binaryImage,
            photo: photo,
            attachmentURL,
            description: description,
            address: address,
            contactName: contactName,
            contactNumber: contactPhone,
            mapLink: mapLink,
            adminNotes: notesAdmin,
            contractorNotes: notesContractor

          });
        } catch (e) {
          alert(e);
        }


      }
    }




  }
  getProperty(propertyId) {
    return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|NIL/${propertyId}`);
  }

  createProperty(property) {
    property.id = "PRP|NIL|" + uuid.v1();
    property.userName = this.props.user.firstName + " " + this.props.user.lastName;
    return API.post("qkeyz", "/items", {
      body: property
    });
  }
  /*
  createProperty(property) {
    property.userName = this.props.user.firstName + " " + this.props.user.lastName;
    return API.post("qkeyz", "/properties", {
      body: property
    });
  }
  */

  updateProperty(propertyId, body) {
    try{
      body.userName = this.props.user.firstName + " " + this.props.user.lastName;
      return API.put("qkeyz", `/organisations/${this.props.org.pk}/items/PRP|NIL|${propertyId}`, {body: body});
    }catch(e) {
      console.log(e);
    }
  }

validateChangesOnly() {
  console.log(this.file, this.state.property.photo);

  return this.validateForm() && (this.state.title !== this.state.property.title || this.state.category !== this.state.property.category || this.state.description !== this.state.property.description || this.state.logo != this.state.property.binaryImage || this.file != null || this.state.address !== this.state.property.address|| this.state.contactName !== this.state.property.contactName|| this.state.contactNumber !== this.state.property.contactPhone|| this.state.mapLink !== this.state.property.mapLink|| this.state.adminNotes !== this.state.property.notesAdmin|| this.state.contractorNotes !== this.state.property.notesContractor);
}

  validateForm() {
    const t = this.state.title.trim();
    const a = this.state.address.trim();
    const c = this.state.category;

    return a.length > 0 && t.length > 0 && c != 'NONE';
  }

handleReset = event => {
  event.preventDefault();
  this.file = null;
  this.inputEl.value='';
  this.setState({
    title: this.state.property.title,
    category: this.state.property.category,
    categoryDesc: this.state.property.categoryDesc,
    description: this.state.property.description,
    photo: this.state.property.photo,
    address: this.state.property.address,
    contactName: this.state.property.contactName,
    contactNumber: this.state.property.contactPhone,
    mapLink: this.state.property.mapLink,
    adminNotes: this.state.property.notesAdmin,
    contractorNotes: this.state.property.notesContractor,
    logo: this.state.property.binaryImage
  });


}


  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFileChange = async event => {
    event.preventDefault();
    this.file = event.target.files[0];
    if (this.file && this.file.type.indexOf("image") == -1) {
      this.file = null;
      this.inputEl.value='';
      this.setState({
        alertOpen: true,
        alertTitle: 'Invalid Image Format',
        alertMessage: 'Please choose a valid image for the property logo.'
      });
    } else {
      if (this.file) {
        const base64 = await this.getReducedImageAsBase64(this);
        this.setState({ logo: base64});
      }
    }
  }

handleSubmit = async event => {
  event.preventDefault();

  if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
    this.setState({
      alertOpen: true,
      alertTitle: 'Invalid Image File Size',
      alertMessage: 'Please choose a file smaller than 5MB.'
    });
    return;
  }

  this.setState({ isSaving: true });

  try {
    console.log("+++");
    let attachment = null;
    /*
    if (this.file) {
      const blob = await this.getReducedImageAsBlob(this);
      let orgId = this.props.org.pk; //'';
      //if (this.props.org) {
      //  orgId = this.props.org.pk;
      //} else {
      //  orgId = this.props.user.pk;
      //}

      attachment = await s3UploadBlob(orgId, this.file, blob);
      console.log(attachment);
      console.log("***");
    }
*/



    //const attachment = this.file
    //  ? await this.uploadReducedImage(this, this.file)
    //  : null;
    let cat = this.state.categories.find(o => o.value === this.state.category);

      console.log("---");
    if (this.state.isEditing) {
      await this.updateProperty(this.pId, {
        title: this.state.title.trim(),
        photo: attachment ? attachment : this.state.photo,
        category: this.state.category,
        categoryDesc: cat.title ? cat.title : null,
        description: this.state.description ? this.state.description : null,
        address: this.state.address,
        contactName: this.state.contactName ? this.state.contactName : null,
        contactPhone: this.state.contactNumber ? this.state.contactNumber : null,
        mapLink: this.state.mapLink ? this.state.mapLink : null,
        notesAdmin: this.state.adminNotes ? this.state.adminNotes : null,
        notesContractor: this.state.contractorNotes ? this.state.contractorNotes : null,
        binaryImage: this.state.logo ? this.state.logo : null
      });
    } else {
      let pRslt = await this.createProperty({
        orgId: this.props.org.pk ,
        attachment,
        title: this.state.title.trim(),
        category: this.state.category,
        categoryDesc: cat.title ? cat.title : null,
        description: this.state.description ? this.state.description : null,
        address: this.state.address,
        contactName: this.state.contactName ? this.state.contactName : null,
        contactPhone: this.state.contactNumber ? this.state.contactNumber : null,
        mapLink: this.state.mapLink ? this.state.mapLink : null,
        notesAdmin: this.state.adminNotes ? this.state.adminNotes : null,
        notesContractor: this.state.contractorNotes ? this.state.contractorNotes : null,
        binaryImage: this.state.logo ? this.state.logo : null
      });
      console.log(pRslt);
      if (pRslt.status === 'failed') {
        if (pRslt.reason === 'exists') {
          this.setState({
            alertOpen: true,
            alertTitle: 'Property Name Exists',
            alertMessage: 'The property name you have entered has already been used for another property. Please enter a unique property name.',
            isSaving: false
          });
          return;
        }
      }



    }
    this.props.history.push("/properties");
  } catch (e) {
    this.setState({
      alertOpen: true,
      alertTitle: 'Error',
      alertMessage: e.message,
      isSaving: false
    });
  }
}
getReducedImageAsBase64(context) {
  var reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(null);
    };
    reader.onload = () => {
      var img = new Image();
      img.onload = function() {
          console.log('IMG: ', img.height, img.width);
          let h = 200;
          let w = 200;
          if (img.height > img.width) {
            w = w * (img.width / img.height);
          } else {
            h = h * (img.height / img.width);
          }
          var oc = document.createElement('canvas');
          oc.width = w;
          oc.height = h;
          var ctx = oc.getContext('2d');
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, w, h);
          ctx.drawImage(img, 0, 0, w, h);
          var dataurl = oc.toDataURL('image/jpeg'); //context.file.type
          resolve(dataurl);
        }
      img.src = reader.result
      };
    reader.readAsDataURL(context.file);
  });
}



handleAlertClose = () => {
    this.setState({ alertOpen: false });
};
handleCrumbClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}
loadMapLink = event => {
  let url = "https://www.google.com/maps";
  if (this.state.mapLink != "") {
    url = this.state.mapLink;
  }
  window.open(url, "_blank");
}

renderFields() {
  return(
    <div className="NewProperty" style={{position: 'absolute', left:80, right:80,top: 110}}>
       <form onSubmit={this.handleSubmit}>
         <FormGroup controlId="title">
         <ControlLabel>Property title</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.title}
             componentClass="input"
             type="text"
           />
         </FormGroup>

         <FormGroup controlId="category">
         <ControlLabel>Category*</ControlLabel>
           <FormControl
             componentClass="select"
             placeholder="category"
             onChange={this.handleChange}
             value={this.state.category}
             >
               {
                 this.state.categories.map((cat, index) => {
                   return (<option key={index} value={cat.value}>{cat.title}</option>)
                 })
               }

           </FormControl>
         </FormGroup>

         <FormGroup controlId="address">
         <ControlLabel>Address</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.address}
             componentClass="textarea"
           />
         </FormGroup>






         <FormGroup>
           <ControlLabel>Property Logo</ControlLabel>
         </FormGroup>
         <Paper className={"recList"}>
         <FormGroup>
           {this.state.logo &&
           <FormControl.Static >
             <img  src={this.state.logo} style={{maxWidth: '400px'}} height='80px' alt="Visitor photo"/>
           </FormControl.Static>
           }
         </FormGroup>

                  <FormGroup controlId="file">
                  <InputGroup>
                  <label htmlFor='file_input' className='label_as_button' ><Glyphicon glyph="folder-open" /> &nbsp;Choose logo</label>
                    <FormControl id='file_input' inputRef={ el => this.inputEl=el } accept="image/*" onChange={this.handleFileChange} type="file" style={{display: 'none'}}/>
                      </InputGroup>







                      </FormGroup>



         </Paper>

















         <FormGroup controlId="description">
         <ControlLabel>Property description</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.description ? this.state.description : ''}
             componentClass="textarea"
           />
         </FormGroup>


         <FormGroup controlId="contactName">
         <ControlLabel>Contact Name</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.contactName ? this.state.contactName : ''}
             componentClass="input"
             type="text"
           />
         </FormGroup>
         <FormGroup controlId="contactNumber">
         <ControlLabel>Contact Phone Number</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.contactNumber ? this.state.contactNumber : ''}
             componentClass="input"
             type="text"
           />
         </FormGroup>




         <FormGroup controlId="mapLink">
         <ControlLabel>Map Link</ControlLabel>
           <InputGroup>
           <FormControl
           onChange={this.handleChange}
           value={this.state.mapLink ? this.state.mapLink : ''}
           componentClass="input"
           type="text"
           />
             <InputGroup.Button>
               <Button  onClick={this.loadMapLink}><Glyphicon glyph="map-marker" /></Button>
             </InputGroup.Button>
           </InputGroup>
         </FormGroup>






         <FormGroup controlId="adminNotes">
         <ControlLabel>Notes for Admin</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.adminNotes ? this.state.adminNotes : ''}
             componentClass="textarea"
           />
         </FormGroup>
         <FormGroup controlId="contractorNotes">
         <ControlLabel>Notes for Contractor</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.contractorNotes ? this.state.contractorNotes : ''}
             componentClass="textarea"
           />
         </FormGroup>



         {this.state.isEditing ?
           <div>
                     <LoaderButton

           bsStyle="primary"
           bsSize="large"
           disabled={!this.validateChangesOnly()}
           type="submit"
           isLoading={this.state.isSaving}
           text="Save"
           loadingText="Saving…"
         />

         <LoaderButton


           bsSize="large"
           disabled={!this.validateChangesOnly()}
           isLoading={false}
           text="Cancel"
           onClick={this.handleReset}
           loadingText="Cancelling…"
         />
         </div>
         :
         <LoaderButton
           bsStyle="primary"
           bsSize="large"
           disabled={!this.validateForm()}
           type="submit"
           isLoading={this.state.isSaving}
           text="Create"
           loadingText="Creating new property…"
         />}






       </form>
     </div>
  )
}
  renderLoader() {
    const label = 'loading...';
    return(

      <div id="splash" style={{paddingTop: 250, zIndex:999}}>

        <center id="splash">
          <img src={loaderIcon}/>
          <Typography style={{padding: '4px'}} variant="caption">{label}</Typography>
        </center>
      </div>



    )
  }


  render() {
    return (
      <div>
         <Breadcrumb style={{top: 58, left: 66, right: 0, position: 'fixed', zIndex:999, backgroundColor: '#FFF'}}>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/properties">Properties</Breadcrumb.Item>
          <Breadcrumb.Item active>{this.state.isLoading ? '' : this.state.property && this.state.property.title ? 'Edit: ' + this.state.property.title : 'Create new'}</Breadcrumb.Item>
        </Breadcrumb>

        {this.state.isLoading ? this.renderLoader() : this.renderFields()}


      <Dialog
          open={this.state.alertOpen}
          onClose={this.handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.state.alertTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.alertMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAlertClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}
