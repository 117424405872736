export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "us-east-1",
    BUCKET: "qkeyz-app-uploads"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://n408dv8dz2.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_CMH5PjkhV",
    APP_CLIENT_ID: "3he6i8guoo7j1po9j86utfnd7h",
    IDENTITY_POOL_ID: "us-east-1:afecec10-63d2-4aef-8ecc-6fd40380fbc7"
  }
};
