import React, { Component } from "react";
import { Glyphicon, InputGroup, FormGroup, FormControl, ControlLabel, Breadcrumb } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./NewObservation.css";
import { API, Storage } from "aws-amplify";
import { s3UploadBlob } from "../libs/awsLib";

import uuid from "uuid";
//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';

import loaderIcon from'../images/qkeyz.gif';


import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/CancelRounded';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import SoundIcon from '@material-ui/icons/RecordVoiceOverRounded';
import InTrayIcon from '@material-ui/icons/AddCircleOutlineRounded';
import JobIcon from '@material-ui/icons/BuildRounded';
import Button from '@material-ui/core/Button';


import Sound from 'react-sound';
import Slide from '@material-ui/core/Slide';



const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
});
function Transition(props) {
  return <Slide direction="right" {...props} />;
}
export default class NewObservation extends Component {
  constructor(props) {
    super(props);


console.log(props);

    this.file = null;
    const isEditing = this.props.location.state && this.props.location.state.observationId;
    this.state = {
      isLightBoxOpen: false,
      isEditing: false,
      isLoading: isEditing,
      isUserEditor: true,
      audioPath: null,
      currentAudioFile: null,
      currentPhotoFile: null,
      playerPosition:"",
      isPlaying: false,
      isSaving: null,
      observation: null,
      propertyId: "",
      title: "",
      locus: "",
      category: 'CLEAN',
      categoryDesc: "",
      description: "",
      recordings: [],
      photos: [],
      photoPaths: [],
      audioPaths: [],
      currentPhoto: null,
      notesAdmin: null,
      currentStatus: "NONE",
      associatedIds: [],
      createdByName: "",
      associationsChanged: false,
      photosChanged: false,
      alertOpen: false,
      alertType: '',
      categories: [{value: 'CLEAN', title: 'Cleaning'},
              {value: 'PLUMB', title: 'Plumbing'},
              {value: 'ELEC', title: 'Electrical'},
              {value: 'FURN', title: 'Furnishings'},
              {value: 'PAINT', title: 'Painting'},
              {value: 'FIRE', title: 'Fire Systems'},
              {value: 'OTHER', title: 'Other'}
            ],
      statuses: [{value: 'NONE', title: ''}, {value: 'ASSIGNED', title: 'Assigned to Job'},
      {value: 'RESOLVED', title: 'Resolved'}, {value: 'REMOVED', title: 'Cancelled by Observer'}

            ],
      properties: []

    };
}

  async componentDidMount() {
    if (!this.props.isAuthenticated || !this.props.org) {
      this.props.history.push("/");
      return;
    }
    const result = await this.getOrgProperties();
    const properties = result.Items;
    if (properties.length == 0) {
      alert("NO PROPERTIES");
      this.props.history.push("/");
      return;
    }
    let propsList = [];
    for (var i = 0; i < properties.length; ++i) {
      let p = properties[i];
      propsList.push({value: this.getPropertyIdFromSk(p.sk), title: p.title});
    }

    //var pId;
      if (this.props.location.state && this.props.location.state.observationId) {
        this.oId = this.props.location.state.observationId
        this.viewOnly = this.props.location.state.viewOnly

         try {
          const observation = await this.getObservation(this.oId);
          const { propertyId, title, locus, category, categoryDesc, description, photos, recordings, notesAdmin, currentStatus, associatedIds, createdBy, createdByName } = observation;

          let [photoPaths, photosEsk] = await this.getPhotoPaths(this.oId, photos);
          let [audioPaths, audiosEsk] = await this.getAudioPaths(this.oId, recordings);

          console.log(this.props);
          console.log("CREATEDBY: " + createdBy);
          //if (photo) {
          //  attachmentURL = await Storage.get(photo);
          //}
          this.setState({
            isEditing: true,
            isLoading: false,
            observation,
            propertyId: propertyId,
            title: title,
            locus: locus,
            category: category,
            categoryDesc: categoryDesc,
            description: description,
            photoPaths: photoPaths,
            photos: photos,
            audioPaths: audioPaths,
            recordings: recordings,
            notesAdmin: notesAdmin,
            currentStatus: currentStatus,
            createdByName: createdByName,
            associatedIds: associatedIds,
            properties: propsList,
            isUserEditor: this.props.user.pk === createdBy
          });

          this.getMorePhotos(photosEsk, photos);
          this.getMoreAudios(audiosEsk, recordings);








        } catch (e) {
          alert(e);
        }
    } else {
      this.viewOnly = false;
      propsList.unshift({id: '', title: 'select property ...'});
      this.setState({
        properties: propsList
      });
    }




  }

  async getMorePhotos(esk, photos) {
    if (esk) {
      let [newPhotoPaths, newEsk] = await this.getPhotoPaths(this.props.location.state.observationId, photos, esk);
      var combinedPhotopaths = this.state.photoPaths.concat(newPhotoPaths);
      this.setState({
        photoPaths: combinedPhotopaths
      })
      this.getMorePhotos(newEsk, photos);
    }
  }
  async getMoreAudios(esk, recordings) {
    if (esk) {
      let [newAudioPaths, newEsk] = await this.getAudioPaths(this.props.location.state.observationId, recordings, esk);
      var combinedAudiopaths = this.state.audioPaths.concat(newAudioPaths);
      this.setState({
        audioPaths: combinedAudiopaths
      })
      this.getMoreAudios(newEsk, recordings);
    }
  }




  async getPhotoPaths(observationId, photos, prevEsk) {
    let photoPaths = [];
    let esk;
    try {
      if (photos.length > 0) {
        const queryParams = '?asc=false' + (prevEsk ? '&esk=' + JSON.stringify(prevEsk) : "");
        let result2 = await this.getOrgObsPics(observationId, queryParams);
         let obsPics = result2.Items;
         esk = result2.LastEvaluatedKey;
        for (let i = 0; i < obsPics.length; ++i) {
          let idParts = obsPics[i].sk.split("|NIL|");
          let picBareId = idParts[1];
          let id;
          let title;
          let timestamp;
          for (let i2 = 0; i2 < photos.length; ++i2) {
              if (photos[i2].id == picBareId) {
                id = picBareId;
                title = photos[i2].title;
                timestamp = photos[i2].timestamp;
                let img = obsPics[i].base64Data
                photoPaths[i] = {file: id, img: img, id: i, title: title, timestamp: timestamp};
                break;
              }
          }
        }
      }
    } catch (e) {
      alert(e);
    }
    return [photoPaths, esk];
  }
  async getAudioPaths(observationId, recordings, prevEsk) {
    let audioPaths = [];
    let esk;
    try {
      if (recordings.length > 0) {
        const queryParams = '?asc=false' + (prevEsk ? '&esk=' + JSON.stringify(prevEsk) : "");
        let result3 = await this.getOrgObsAudios(observationId, queryParams);
        let obsAudios = result3.Items;
        esk = result3.LastEvaluatedKey;
        for (let i = 0; i < obsAudios.length; ++i) {
          let idParts = obsAudios[i].sk.split("|NIL|");
          let audioBareId = idParts[1];
          let id;
          let duration;
          for (let i2 = 0; i2 < recordings.length; ++i2) {
              if (recordings[i2].id == audioBareId) {
                id = audioBareId;
                duration = recordings[i2].duration;
                let base64String = obsAudios[i].base64Data
                audioPaths[i] = {file: id, base64String: base64String, id: i, duration: duration};
                break;
              }
          }
        }
      }
    } catch (e) {
      alert(e);
    }
    return [audioPaths, esk];
  }




/*
  setPhotoPaths = async (photos) => {
    const orgId = this.props.org.pk;
    const obsId = this.getIdFromSk(this.state.observation.sk);
    let photoPaths = [];
    for (var i = 0; i < photos.length; ++i) {
      let path = orgId + '/photos/' + photos[i].file;
      let attachmentURL = await Storage.get(path);
      photoPaths[i] = {file: photos[i].file, img: attachmentURL, id: i, title: photos[i].title, timestamp: photos[i].timestamp};
      this.setState({
        photoPaths: photoPaths,
        photoPathsBackup: photoPaths
      });
    }
  }
  */

  getObservation(observationId) {
      return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|OBS|NIL/${observationId}`);
  }

  createObservation(observation) {
    observation.id = "PRP|OBS|NIL|" + observation.propertyId + "|" + new Date().getTime() + "~" + uuid.v1();
    observation.userName = this.props.user.firstName + " " + this.props.user.lastName;
    return API.post("qkeyz", "/items", {
      body: observation
    });
  }

  updateObservation(observationId, body) {
    try{
      body.userName = this.props.user.firstName + " " + this.props.user.lastName;
      console.log(body);
      return API.put("qkeyz", `/organisations/${this.props.org.pk}/items/PRP|OBS|NIL|${observationId}`, {body: body});
    }catch(e) {
      console.log(e);
    }
  }

  getOrgProperties() {
      return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|NIL`);
  }

  getOrgObsPics(bareObsId, queryParams) {
    //console.log(`/organisations/${this.props.org.pk}/OBS|PIC|NIL|${bareObsId}`);
      return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|OBS|PIC|NIL|${bareObsId}${queryParams}`);
      //return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|EVT|NIL|${propertyId}${queryParams}`);

  }

  getOrgObsAudios(bareObsId, queryParams) {
      return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|OBS|AUD|NIL|${bareObsId}${queryParams}`);
  }

  getIdFromSk(sk) {
    return sk.substr(4);
  }

  getPropertyIdFromSk(sk) {
    return sk.substr(8);
  }


validateChangesOnly() {
  return this.validateForm() && (this.state.recordings !== this.state.observation.recordings || this.state.photos !== this.state.observation.photos || this.state.propertyId !== this.state.observation.propertyId || this.state.locus !== this.state.observation.locus || this.state.category !== this.state.observation.category || this.state.description !== this.state.observation.description || this.state.currentStatus !== this.state.observation.currentStatus|| this.state.notesAdmin !== this.state.observation.notesAdmin);
}

  validateForm() {
    const p = this.state.propertyId;
    const l = this.state.locus.trim();
    const c = this.state.category;
    const d = this.state.description.trim();
    return p.length > 0 && c.length > 0 && d.length > 0;
  }

handleReset = event => {
  event.preventDefault();
  //this.file = null;
  //this.inputEl.value='';
  this.setState({
    propertyId: this.state.observation.propertyId,
    title: this.state.observation.title,
    locus: this.state.observation.locus,
    category: this.state.observation.category,
    categoryDesc: this.state.observation.categoryDesc,
    description: this.state.observation.description,
    photos: this.state.observation.photos,
    recordings: this.state.observation.recordings,
    notesAdmin: this.state.observation.notesAdmin,
    currentStatus: this.state.observation.currentStatus,
    associatedIds: this.state.observation.associatedIds
  });


}


  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFileChange = event => {
    this.file = event.target.files[0];
    if (this.file && this.file.type.indexOf("image") == -1) {
      this.file = null;
      this.inputEl.value='';
      this.setState({
        alertOpen: true,
        alertTitle: 'Invalid Image Format',
        alertMessage: 'Please choose a valid image for the observation logo.'
      });
    } else {
      this.setState({

      });
    }
  }


  handleCreateJobClick = event => {
    event.preventDefault();
    this.props.history.push({
    pathname: '/jobs/new',
    state: { obs: {obsId: this.oId, propertyId: this.state.propertyId, title: this.state.title, locus: this.state.locus, category: this.state.category, categoryDesc: this.state.categoryDesc, details: this.state.description}}
    });
  }











handleSubmit = async event => {
  event.preventDefault();

  if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
    this.setState({
      alertOpen: true,
      alertTitle: 'Invalid Image File Size',
      alertMessage: 'Please choose a file smaller than 5MB.'
    });
    return;
  }

  this.setState({ isSaving: true });

  try {
    //console.log("+++");
    //let attachment = null;
    //if (this.file) {
      //const blob = await this.getReducedImageAsBlob(this);
      //let orgId = this.props.org.pk; //'';
      //if (this.props.org) {
      //  orgId = this.props.org.pk;
      //} else {
      //  orgId = this.props.user.pk;
      //}

      //attachment = await s3UploadBlob(orgId, this.file, blob);
      //console.log(attachment);
      //console.log("***");
    //}

    console.log(this.state.category, this.state.propertyId);
    let cat = this.state.categories.find(o => o.value === this.state.category);
    //let prp = this.state.properties.find(o => o.value === this.getIdFromSk(this.state.propertyId));
    let prp = this.state.properties.find(o => o.value === this.state.propertyId);
    console.log(this.state.properties);
    console.log(this.state.propertyId);
    console.log(this.state.isEditing);
    if (this.state.isEditing) {
      await this.updateObservation(this.oId, {
        propertyId: this.state.propertyId,
        title: prp.title,
        locus: this.state.locus.trim(),
        category: this.state.category,
        categoryDesc: cat.title,
        description: this.state.description.trim(),
        photos: this.state.photos,
        recordings: this.state.recordings,
        notesAdmin: this.state.notesAdmin ? this.state.notesAdmin : null,
        currentStatus: this.state.currentStatus,
        associatedIds: this.state.associatedIds,
      });
    } else {
      await this.createObservation({
        orgId: this.props.org.pk ,
        propertyId: this.state.propertyId,
        title: prp.title ? prp.title : null,
        locus: this.state.locus.trim(),
        category: this.state.category,
        categoryDesc: cat.title ? cat.title : null,
        description: this.state.description.trim(),
        photos: this.state.photos,
        recordings: this.state.recordings,
        notesAdmin: this.state.notesAdmin ? this.state.notesAdmin : null,
        currentStatus: this.state.currentStatus,
        associatedIds: this.state.associatedIds
      });
    }
    this.props.history.push("/observations");
  } catch (e) {
    this.setState({
      alertOpen: true,
      alertType: '',
      alertTitle: 'Error',
      alertMessage: e.message,
      isSaving: false
    });
  }
}
handleDeletePhoto = tile => () => {
    this.setState({
      alertOpen: true,
      alertType: 'deletePhoto',
      currentPhotoFile: tile.file,
      alertMessage: 'Are you sure you wish to delete this photo?',
      alertTitle: 'Delete Photo'
    });
};
handleAlertDeletePhoto = () => {
  console.log(this.state.currentPhotoFile);
  let idx = -1;
  const chipDataOrig = [...this.state.photos];
  const chipData = [...this.state.photoPaths];
  for (var i = 0; i < chipData.length; ++i) {
    let p = chipData[i];
    if (p.file === this.state.currentPhotoFile) {
      idx = i;
      break;
    }
  }
  if (idx > -1) {
    chipData.splice(idx, 1);
    chipDataOrig.splice(idx, 1);
    this.setState({
      alertOpen: false,
      photos: chipDataOrig,
      photoPaths: chipData,
      currentPhotoFile: null
    });
  } else {
    this.setState({
      alertOpen: false,
      currentPhotoFile: null
    });
  }
}

handleZoomPhoto = tile => () => {
  console.log(tile);
  this.setState({
    currentPhoto: tile,
    isLightBoxOpen: true
  });
};
handleDeleteRecording = data => () => {
    if (this.state.isPlaying) {
      this.setState({
        isPlaying: false,
        audioPath: null
      });
    } else {
      this.setState({
        alertOpen: true,
        alertType: 'deleteRecording',
        currentAudioFile: data.file,
        alertMessage: 'Are you sure you wish to delete this recording?',
        alertTitle: 'Delete Recording'
      });
    }
  };
  handleAlertDeleteRecording = () => {
    console.log(this.state.currentAudioFile);
    let idx = -1;
    const chipData = [...this.state.audioPaths];
    for (var i = 0; i < chipData.length; ++i) {
      let r = chipData[i];
      if (r.file === this.state.currentAudioFile) {
        idx = i;
        break;
      }
    }
    if (idx > -1) {
      chipData.splice(idx, 1);
      this.setState({
        alertOpen: false,
        audioPaths: chipData,
        currentAudioFile: null
      });
    } else {
      this.setState({
        alertOpen: false,
        currentAudioFile: null
      });
    }
  }
  handlePlayRecording = data => async () => {
    if (this.state.isPlaying) {
      this.setState({
        isPlaying: false,
        audioPath: null
      });
      return;
    }
    console.log("AUDIOAUDIOAUDIOAUDIO");
    console.log(data.file);


    //let attachmentURL = await this.getVoiceFilePath(data.file);



    //console.log("AUDIO");
    //console.log(attachmentURL);
    if (data.base64String) {
    //if (attachmentURL) {
      this.setState({
        audioPath: data.base64String,
        currentAudioFile: data.file,
        playerPosition: (data.duration/1000).toFixed(2) + "s",
        isPlaying: true
      });
    } else {
      this.setState({
        isPlaying: false,
        currentAudioFile: null,
        audioPath: null
      });
    }

  };
  /*
  getVoiceFilePath = async fileName => {
    console.log('attachmentURL');
    const orgId = this.props.org.pk;
    const obsId = this.getIdFromSk(this.state.observation.sk);
    let attachmentURL = await Storage.get(orgId + '/audio/' + fileName);
    console.log(attachmentURL);
    return attachmentURL;
  }
  */

  handleFinishedPlaying = () => {

    this.setState({
      isPlaying: false,
      currentAudioFile: null,
      audioPath: null
    });
    console.log('Finished!');
  };
  handlePlaying = (player) => {
    console.log('PLAYING: ', player.position, player.duration);
    let currentVal = ((player.duration - player.position)/1000).toFixed(2) + "s";
    this.setState({
      playerPosition: currentVal
    });
  };
getReducedImageAsBlob(context) {
  var reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(null);
    };
    reader.onload = () => {
      var img = new Image();
      img.onload = function() {
          console.log('IMG: ', img.height, img.width);
          let h = 200;
          let w = 200;
          if (img.height > img.width) {
            w = w * (img.width / img.height);
          } else {
            h = h * (img.height / img.width);
          }
          var oc = document.createElement('canvas');
          oc.width = w;
          oc.height = h;
          oc.getContext('2d').drawImage(img, 0, 0, w, h);
          var dataurl = oc.toDataURL(context.file.type);
          //console.log(dataurl);
          var blob = context.dataURItoBlob(dataurl, context.file.type);
          resolve(blob);
        }
      img.src = reader.result
      };
    reader.readAsDataURL(context.file);
  });
}

dataURItoBlob(dataURI, fileType) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: fileType});
}


handleAssign = () => {



};
handleAlertClose = () => {
    this.setState({
      alertOpen: false,
      alertType: ''
    });
};
handleLightBoxClose = () => {
    this.setState({ isLightBoxOpen: false, currentPhoto: null });
  };

handleCrumbClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}


renderFields() {
  return(
    <div className="NewObservation" style={{position: 'absolute', left:80, right:80,top: 110}}>
       <form onSubmit={this.handleSubmit}>


       <FormGroup controlId="propertyId">
       <ControlLabel>Property</ControlLabel>
         <FormControl
           componentClass="select"
           placeholder="property"
           onChange={this.handleChange}
           value={this.state.propertyId}
           >
             {
               this.state.properties.map((prop, index) => {
                 return (<option key={index} value={prop.value}>{prop.title}</option>)
               })
             }

         </FormControl>
       </FormGroup>

       <FormGroup controlId="locus">
       <ControlLabel>Locus</ControlLabel>
         <FormControl
           onChange={this.handleChange}
           value={this.state.locus}
           componentClass="input"
           type="text"
         />
       </FormGroup>

       <FormGroup controlId="category">
       <ControlLabel>Category</ControlLabel>
         <FormControl
           componentClass="select"
           placeholder="category"
           onChange={this.handleChange}
           value={this.state.category}
           >
             {
               this.state.categories.map((cat, index) => {
                 return (<option key={index} value={cat.value}>{cat.title}</option>)
               })
             }

         </FormControl>
       </FormGroup>


       <FormGroup controlId="description">
       <ControlLabel>Details</ControlLabel>
         <FormControl
           onChange={this.handleChange}
           value={this.state.description}
           componentClass="textarea"
           rows="3"
         />
       </FormGroup>


       {this.state.audioPaths && this.state.audioPaths.length > 0 &&

       <FormGroup controlId="recordings">
       <ControlLabel>Voice Recordings</ControlLabel>

       <Paper className={"recList"}>
         {this.state.audioPaths.map(data => {
           let icon = null;

           //if (data.label === 'React') {
             icon = <SoundIcon />;
           //}
           return (
             <Chip
               key={data.file}
               icon={icon}
               label={this.state.isPlaying && data.file === this.state.currentAudioFile ? this.state.playerPosition : (data.duration/1000).toFixed(2) + "s"}
               onDelete={this.handleDeleteRecording(data)}
               onClick={this.handlePlayRecording(data)}
               color={(this.state.isPlaying || (this.state.alertType === 'deleteRecording' && this.state.alertOpen)) && data.file === this.state.currentAudioFile ? 'primary' : 'secondary'}
               className={"chip"}
             />
           );
         })}
       </Paper>
</FormGroup>
}


{this.state.photoPaths && this.state.photoPaths.length > 0 &&

       <FormGroup controlId="pics">
       <ControlLabel>Photos</ControlLabel>

<Paper className={"recList"}>



         {this.state.photoPaths.map(tile => (
           <div key={tile.file} className={"ibcontainer"}>
           <img src={tile.img} alt={tile.title} onClick={this.handleZoomPhoto(tile)}/>
           <IconButton className={"btn"}
              onClick={this.handleDeletePhoto(tile)}
           >
             <CancelIcon/>
           </IconButton>

           </div>

           ))}



</Paper>
</FormGroup>
}












<FormGroup controlId="currentStatus">
<ControlLabel>Status</ControlLabel>
  <FormControl
    componentClass="select"
    placeholder="currentStatus"
    onChange={this.handleChange}
    value={this.state.currentStatus ? this.state.currentStatus : ''}
    >
      {
        this.state.statuses.map((status, index) => {
          return (<option key={index} value={status.value}>{status.title}</option>)
        })
      }

  </FormControl>
</FormGroup>





         <FormGroup controlId="notesAdmin">
         <ControlLabel>Admin Notes</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.notesAdmin ? this.state.notesAdmin : ''}
             componentClass="textarea"
             rows="3"
           />
         </FormGroup>



         {this.state.isEditing ?
           <div>





           <LoaderButton

 bsStyle="primary"
 bsSize="large"
 disabled={!this.validateChangesOnly()}
 type="submit"
 isLoading={this.state.isSaving}
 text="Save"
 loadingText="Saving…"
/>

<LoaderButton


  bsSize="large"
  disabled={!this.validateChangesOnly()}
  isLoading={false}
  text="Cancel"
  onClick={this.handleReset}
  loadingText="Cancelling…"
/>
         </div>
         :
         <LoaderButton
           bsStyle="primary"
           bsSize="large"
           disabled={!this.validateForm()}
           type="submit"
           isLoading={this.state.isSaving}
           text="Create"
           loadingText="Creating new observation…"
         />}



         <Sound
            url={this.state.audioPath ? this.state.audioPath : ''}
            playStatus={this.state.audioPath && this.state.isPlaying ? Sound.status.PLAYING : Sound.status.STOPPED}
            onLoading={this.handleLoading}
            onPlaying={this.handlePlaying}
            onFinishedPlaying={this.handleFinishedPlaying}
            />



       </form>
     </div>
  )
}


renderMinFields() {
  return(
    <div className="NewObservation" style={{position: 'absolute', left:80, right:80,top: 110}}>
    <Card className="card">
   <CardContent>
   <div className='right_align'>
   <Chip
     icon={<JobIcon />}
     label={'Create New Job from this Observation'}
     onClick={this.handleCreateJobClick}
     color={ 'secondary' }
     className={"chip"}/>
     </div>

   <Typography gutterBottom variant="h5" component="h2">
 <b>{this.state.title} - {this.state.locus}</b>
</Typography>
<Typography gutterBottom variant="h6" component="h1">
{this.state.categoryDesc}
</Typography>
<Typography component="p">
  {this.state.description}
  <br/><br/>
</Typography>


{this.state.audioPaths.length > 0 &&
       <Paper className={"recList"}>
         {this.state.audioPaths.map(data => {
           let icon = null;

           //if (data.label === 'React') {
             icon = <SoundIcon />;
           //}
console.log(data.file, this.currentAudioFile);
           return (
             <Chip
               key={data.file}
               icon={icon}
               label={this.state.isPlaying && data.file === this.state.currentAudioFile ? this.state.playerPosition : (data.duration/1000).toFixed(2) + "s"}
               onDelete={this.state.isUserEditor && !this.viewOnly ? this.handleDeleteRecording(data) : null}
               onClick={this.handlePlayRecording(data)}
               color={(this.state.isPlaying || (this.state.alertType === 'deleteRecording' && this.state.alertOpen)) && data.file === this.state.currentAudioFile ? 'primary' : 'secondary'}
               className={"chip"}
             />
           );
         })}
       </Paper>
     }




{this.state.photoPaths.length > 0 &&
<Paper className={"recList"}>



         {this.state.photoPaths.map(tile => (
           <div key={tile.file} className={"ibcontainer"}>
           <img src={tile.img} alt={tile.title} onClick={this.handleZoomPhoto(tile)}/>
{this.state.isUserEditor && !this.viewOnly &&
           <IconButton className={"btn"}
              onClick={this.handleDeletePhoto(tile)}
           >
             <CancelIcon/>
           </IconButton>}

           </div>

           ))}



</Paper>
}
<Typography component="p">
  <b>{"Created by: " + this.state.createdByName}</b>
</Typography>


</CardContent>
</Card>




<br/>






<form onSubmit={this.handleSubmit}>


<div className='generalHorizontalLayout'>
<FormGroup controlId="currentStatus">
<ControlLabel>Status</ControlLabel>
<div>
  <FormControl
    componentClass="select"
    placeholder="currentStatus"
    onChange={this.handleChange}
    value={this.state.currentStatus ? this.state.currentStatus : ''}
    >
      {
        this.state.statuses.map((status, index) => {
          return (<option key={index} value={status.value}>{status.title}</option>)
        })
      }

  </FormControl>
  </div>
</FormGroup>
</div>

         <FormGroup controlId="notesAdmin">
         <ControlLabel>Admin Notes</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.notesAdmin ? this.state.notesAdmin : ''}
             componentClass="textarea"
             rows="3"
           />
         </FormGroup>



         {this.state.isEditing ?
           <div>



                     <LoaderButton

           bsStyle="primary"
           bsSize="large"
           disabled={!this.validateChangesOnly()}
           type="submit"
           isLoading={this.state.isSaving}
           text="Save"
           loadingText="Saving…"
         />

         <LoaderButton


           bsSize="large"
           disabled={!this.validateChangesOnly()}
           isLoading={false}
           text="Cancel"
           onClick={this.handleReset}
           loadingText="Cancelling…"
         />
         </div>
         :
         <LoaderButton
           bsStyle="primary"
           bsSize="large"
           disabled={!this.validateForm()}
           type="submit"
           isLoading={this.state.isSaving}
           text="Create"
           loadingText="Creating new observation…"
         />}



         <Sound
            url={this.state.audioPath ? this.state.audioPath : ''}
            playStatus={this.state.audioPath && this.state.isPlaying ? Sound.status.PLAYING : Sound.status.STOPPED}
            onLoading={this.handleLoading}
            onPlaying={this.handlePlaying}
            onFinishedPlaying={this.handleFinishedPlaying}
            />



       </form>
     </div>
  )
}


  renderLoader() {
    const label = 'loading...';
    return(

      <div id="splash" style={{paddingTop: 250, zIndex:999}}>

        <center id="splash">
          <img src={loaderIcon}/>
          <Typography style={{padding: '4px'}} variant="caption">{label}</Typography>
        </center>
      </div>



    )
  }

render() {
  //console.log(this.viewOnly + "  " + this.state.isUserEditor);
    return (
      <div>
         <Breadcrumb style={{top: 58, left: 66, right: 0, position: 'fixed', zIndex:999, backgroundColor: '#FFF'}}>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/observations">Observations</Breadcrumb.Item>
          <Breadcrumb.Item active>{this.state.isLoading ? '' : this.state.observation && this.state.observation.title ? 'Edit: ' + this.state.observation.title + ', ' + this.state.observation.locus + ' - ' + this.state.observation.categoryDesc: 'Create new'}</Breadcrumb.Item>
        </Breadcrumb>

        {this.state.isLoading ? this.renderLoader() : this.state.isUserEditor && !this.viewOnly ? this.renderFields() : this.renderMinFields()}

        <Dialog
          open={this.state.isLightBoxOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleLightBoxClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {this.state.title + ' - ' + this.state.locus}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
               {this.state.currentPhoto && new Date(this.state.currentPhoto.timestamp).toLocaleString()}
                {this.state.currentPhoto && this.state.currentPhoto.title && <br/>}
               {this.state.currentPhoto && this.state.currentPhoto.title ? this.state.currentPhoto.title : ''}
            </DialogContentText>
            {this.state.currentPhoto && <img src={this.state.currentPhoto.img} style={{maxWidth: 500, maxHeight:500}}/>}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleLightBoxClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

      <Dialog
          open={this.state.alertOpen}
          onClose={this.handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.state.alertTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.alertMessage}
            </DialogContentText>
          </DialogContent>


          {this.state.alertType === 'deleteRecording' ?
           <DialogActions>
           <Button onClick={this.handleAlertClose} color='secondary' autoFocus>
             Cancel
           </Button>
           <Button onClick={this.handleAlertDeleteRecording} color='primary'>
             Delete
           </Button>
           </DialogActions>

           : this.state.alertType === 'deletePhoto' ?
           <DialogActions>
           <Button onClick={this.handleAlertClose} color='secondary' autoFocus>
             Cancel
           </Button>
           <Button onClick={this.handleAlertDeletePhoto} color='primary'>
             Delete
           </Button>
           </DialogActions>


           :<DialogActions><Button onClick={this.handleAlertClose} color='primary'>
             Ok
           </Button></DialogActions>}


        </Dialog>

      </div>
    );
  }
}
