import React from "react";
import { Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute"
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import ForgotPassword from "./containers/ForgotPassword";
import Account from "./containers/Account";
import Organisations from "./containers/Organisations";
import Organisation from "./containers/Organisation";
import NewOrg from "./containers/NewOrg";
import NewOrgUser from "./containers/NewOrgUser";
import OrgUser from "./containers/OrgUser";
import Login from "./containers/Login";
import AppliedRoute from "./components/AppliedRoute";
import Signup from "./containers/Signup";
import NewProperty from "./containers/NewProperty";
import Properties from "./containers/Properties";
import PropertyKeys from "./containers/PropertyKeys";
import PropertyLog from "./containers/PropertyLog";
import NewKey from "./containers/NewKey";
import Visitors from "./containers/Visitors";
import NewVisitor from "./containers/NewVisitor";
import Employees from "./containers/Employees";
import NewEmployee from "./containers/NewOrgEmployee";
import Observations from "./containers/Observations";
import NewObservation from "./containers/NewObservation";
import Jobs from "./containers/Jobs";
import NewJob from "./containers/NewJob";
import Privacy from "./containers/Privacy";



export default ({ childProps }) =>
  <Switch>
  <AppliedRoute path="/" exact component={Home} props={childProps}/>
  <UnauthenticatedRoute path="/login" exact component={Login} props={childProps}/>
  <UnauthenticatedRoute path="/forgot" exact component={ForgotPassword} props={childProps}/>
  <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps}/>
  <AuthenticatedRoute path="/account" exact component={Account} props={childProps}/>
  <AuthenticatedRoute path="/organisations" exact component={Organisations} props={childProps}/>
  <AuthenticatedRoute path="/organisations/new" exact component={NewOrg} props={childProps}/>
  <AuthenticatedRoute path="/organisations/:id" exact component={Organisation} props={childProps}/>
  <AuthenticatedRoute path="/organisations/:id/users/add" exact component={NewOrgUser} props={childProps}/>
  <AuthenticatedRoute path="/organisations/users/:id" exact component={OrgUser} props={childProps}/>
  <AuthenticatedRoute path="/properties" exact component={Properties} props={childProps} />
  <AuthenticatedRoute path="/properties/new" exact component={NewProperty} props={childProps} />
  <AuthenticatedRoute path="/properties/edit" exact component={NewProperty} props={childProps}/>
  <AuthenticatedRoute path="/properties/:id/keys" exact component={PropertyKeys} props={childProps}/>
  <AuthenticatedRoute path="/properties/:id/log" exact component={PropertyLog} props={childProps}/>
  <AuthenticatedRoute path="/properties/property/keys/new" exact component={NewKey} props={childProps} />
  <AuthenticatedRoute path="/properties/property/keys/edit" exact component={NewKey} props={childProps} />
  <AuthenticatedRoute path="/visitors" exact component={Visitors} props={childProps} />
  <AuthenticatedRoute path="/visitors/new" exact component={NewVisitor} props={childProps} />
  <AuthenticatedRoute path="/visitors/edit" exact component={NewVisitor} props={childProps}/>
  <AuthenticatedRoute path="/employees" exact component={Employees} props={childProps} />
  <AuthenticatedRoute path="/employees/new" exact component={NewEmployee} props={childProps} />
  <AuthenticatedRoute path="/employees/edit" exact component={NewEmployee} props={childProps}/>
  <AuthenticatedRoute path="/observations" exact component={Observations} props={childProps}/>
  <AuthenticatedRoute path="/observations/new" exact component={NewObservation} props={childProps}/>
  <AuthenticatedRoute path="/observations/edit" exact component={NewObservation} props={childProps}/>
  <AuthenticatedRoute path="/jobs" exact component={Jobs} props={childProps}/>
  <AuthenticatedRoute path="/jobs/new" exact component={NewJob} props={childProps}/>
  <AuthenticatedRoute path="/jobs/edit" exact component={NewJob} props={childProps}/>
  <UnauthenticatedRoute path="/privacy" exact component={Privacy} props={childProps}/>



/*
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/properties" exact component={Properties} props={childProps} />
    <AppliedRoute path="/login" exact component={Login} props={childProps} />
    <AppliedRoute path="/signup" exact component={Signup} props={childProps} />
    <AppliedRoute path="/properties/new" exact component={NewProperty} props={childProps} />
    */
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;
