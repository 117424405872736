import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem, Breadcrumb } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Observations.css";
import { API, Storage } from "aws-amplify";
import EnhancedTable from "./EnhancedTable";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';


import loaderIcon from'../images/qkeyz.gif';





const rowHeaders = [
  { id: 'property', numeric: false, center: false, disablePadding: true, label: 'Property' },
  { id: 'locus', numeric: false, center: false, disablePadding: true, label: 'Locus' },
  { id: 'category', numeric: false, center: false, disablePadding: true, label: 'Category' },
  { id: 'description', numeric: false, center: false, disablePadding: false, label: 'Details' },
  { id: 'photos', numeric: false, center: true, disablePadding: true, label: 'Photos' },
  { id: 'recordings', numeric: false, center: true, disablePadding: true, label: 'Voice Recordings' },
  { id: 'createdbyname', numeric: false, center: false, disablePadding: false, label: 'Created By' },
  { id: 'created', numeric: false, center: false, disablePadding: false, label: 'Created Date' },
  { id: 'status', numeric: false, center: false, disablePadding: false, label: 'Status' },
];

export default class Observations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isDeleting: false,
      alertOpen: false,
      observations: []
    };
  }
async componentDidMount() {
  //console.log(this.props);
  if (!this.props.isAuthenticated || !this.props.org) {
    this.props.history.push("/");
    return;
  }

  try {
    const result = await this.getOrgObservations();
    const observations = result.Items;
    const rows = await this.getStructuredObservationList(observations);

/*
    var key = '';
    if (url) {
      let pos = url.indexOf('?AWSAccessKeyId');
      key = url.substring(pos);
    }
*/


    this.setState({ observations, rows });
  } catch (e) {
    alert(e);
  }

  this.setState({ isLoading: false });
}

async getStructuredObservationList(observations) {
  var rows = [];
  for (var i = 0; i < observations.length; ++i) {
    let o = observations[i];
    const {locus, title, description, category, categoryDesc, photos, recordings, created, createdByName, updated, currentStatus} = o;
    let picCount = 0;
    let recCount = 0;
    if (o.photos) {
      picCount = o.photos.length;
    }
    if (o.recordings) {
      recCount = o.recordings.length;
    }
    let status = "";
    if (currentStatus) {
      status = currentStatus;
    }
    let cr = '';
    let crOrder = '';
    if (created) {
      cr = (new Date(created)).toLocaleString();
      crOrder = created;
    }
    let up = '';
    let upOrder = '';
    if (updated) {
      up = (new Date(updated)).toLocaleString();
      upOrder = updated;
    }



    let rowCols = [
      { id: 'property', numeric: false, center: false, disablePadding: true, value: title, type: 'text' },
      { id: 'locus', numeric: false, center: false, disablePadding: true, value: locus, type: 'text' },
      { id: 'category', numeric: false, center: false, disablePadding: true, value: categoryDesc, type: 'text' },
      { id: 'description', numeric: false, center: false, disablePadding: false, value: description, type: 'text' },
      { id: 'photos', numeric: false, center: true, disablePadding: false, value: picCount, type: 'text' },
      { id: 'recordings', numeric: false, center: true, disablePadding: false, value: recCount, type: 'text' },
      { id: 'createdbyname', numeric: false, center: false, disablePadding: false, value: createdByName, type: 'text' },
      { id: 'created', numeric: false, center: false, disablePadding: false, value: cr, type: 'date' },
      { id: 'status', numeric: false, center: false, disablePadding: false, value: status, type: 'text' },
    ];
    rows.push({id: i + 1, property: title, locus, category: categoryDesc, description, photos: picCount, recordings: recCount, createdbyname: createdByName, created: crOrder, status: status, key: o.sk, cols: rowCols})
  }
  return rows
}


getOrgObservations() {
  //const body = {entity: 'OBS'};,
  return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|OBS|NIL?asc=false&arch=false`);
}
deleteObservations() {
  //console.log(this.state);
  const idList = this.state.selected;
  if (idList.length > 0) {
    const body = {ids: idList};
    console.log(body);
    return API.del("qkeyz", `/organisations/${this.props.org.pk}/OBS`, {body: body});
  }
}
archiveObservations() {
  const idList = this.state.selected;
  if (idList.length > 0) {
    let body = {ids: idList};
    body.userName = this.props.user.firstName + " " + this.props.user.lastName;
    console.log(body);
    return API.put("qkeyz", `/organisations/${this.props.org.pk}/OBS`, {body: body});
  }
}

handleCrumbClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}

getObservationIdFromSk(sk) {
  return sk.substr(12);
}

 renderObservationsList(observations) {
  return [{}].concat(observations).map(
    (observation, i) =>
      i !== 0
        ? <ListGroupItem
            key={observation.sk}
            href={`/observations/${this.getObservationIdFromSk(observation.sk)}`}
            onClick={this.handleObservationClick}
            header={observation.sk}
          >
            {"Created: " + new Date(observation.created).toLocaleString()}
          </ListGroupItem>
        : <ListGroupItem
            key="new"
            href={"/observations/new"}
            onClick={this.handleObservationClick}
          >
            <h4>
              <b>{"\uFF0B"}</b> New Observation
            </h4>
          </ListGroupItem>
  );
}
handleAlertClose = () => {
    this.setState({ alertOpen: false });
};
handleObservationClick = (event, observationSk) => {
  event.preventDefault();
  this.props.history.push({
    pathname: '/observations/edit',
    state: { viewOnly: true, observationId: this.getObservationIdFromSk(observationSk) }
  });
}
handleObservationEditClick = (event, selected) => {
  event.preventDefault();
  console.log(selected);
  this.props.history.push({
  pathname: '/observations/edit',
  state: { viewOnly: false, observationId: this.getObservationIdFromSk(selected[0]) }
  });
}
handleCreateObservationClick = event => {
  event.preventDefault();
  this.props.history.push("/observations/new");
}
handleDeleteObservationClick = async (event, selected) => {
  event.preventDefault();
  let count = selected.length;
  if (count > 0) {
    this.setState({
      alertOpen: true,
      alertType: 'delete',
      selected: selected
    });
  }
}
handleDeleteObservations = async (event) => {
  console.log(this.state);
  console.log('LEN0: ', this.state.rows.length);
 this.setState({ isDeleting: true, alertOpen: false });
 console.log('aaa');
 let isSuccess = false
 try {
   const result = await this.deleteObservations();
   console.log(result);
   isSuccess = true;
 } catch (e) {
   alert(e);
 }

 console.log('ddd');
 const result = await this.getOrgObservations();
 const observations = result.Items;
 const rows = await this.getStructuredObservationList(observations);
 console.log('LEN: ', rows.length);
 this.setState({ isDeleting: false, observations: observations, rows: rows });
}

handleArchiveObservationClick = async (event, selected) => {
  event.preventDefault();
  let count = selected.length;
  if (count > 0) {
    this.setState({
      alertOpen: true,
      alertType: 'archive',
      selected: selected
    });
  }
}
handleArchiveObservations = async (event) => {
  console.log(this.state);
  console.log('LEN0sssss: ', this.state.rows.length);
 this.setState({ isDeleting: true, alertOpen: false });
 console.log('aaasss');
 let isSuccess = false
 try {
   const result = await this.archiveObservations();
   console.log(result);
   isSuccess = true;
 } catch (e) {
   alert(e);
 }

 console.log('dddssss');
 const result = await this.getOrgObservations();
 const observations = result.Items;
 const rows = await this.getStructuredObservationList(observations);
 console.log('LENssss: ', rows.length);
 this.setState({ isDeleting: false, observations: observations, rows: rows });
}





/*
<MenuItem disabled={numSelected > 0} onClick={handleCreateItemClick}>Create new...</MenuItem>
<MenuItem disabled={numSelected != 1} onClick={event => handleEditItemClick(event, selected)}>Edit...</MenuItem>
<MenuItem disabled={numSelected == 0} onClick={event => actionsItemClickEvent(event, selected, handleDeleteItemClick)}>Delete</MenuItem>
*/


/*
async deleteProjects() {
  console.log('bbb');
  try {
    await this.deleteProjects();
  } catch (e) {
    alert(e);
  }
  console.log('ccc');

}
*/
renderLander() {
    return (
      <div className="lander">
        <h1>Observations</h1>
        <p>Create your first observation here</p>
          <LoaderButton
            bsStyle="primary"
            bsSize="large"
            isLoading={this.state.isLoading}
            onClick={this.handleCreateObservationClick}
            text="Create Observation"
            loadingText="Create observation"
          />


      </div>
    );
}


/*

      <div className="items">
        <PageHeader>{this.props.org ? this.props.org.orgName + ':' : 'My'} Projects</PageHeader>

*/
/*

handleEditItemClick={this.handleProjectEditClick}
handleCreateItemClick={this.handleCreateProjectClick}
handleDeleteItemClick={this.handleDeleteProjectClick}



*/

renderObservations() {
  const menuItems = [
    { id: 0, label: 'Create new...', lt: Number.MAX_VALUE, gt: 0, isOrOperator: false, action: this.handleCreateObservationClick, divider: false, type: 'create'},
    { id: 1, label: 'Edit...', lt: 1, gt: 1, isOrOperator: true, action: this.handleObservationEditClick, divider: false, type: 'edit' },
    { id: 2, label: 'Delete', lt: 1, gt: -1, isOrOperator: false, action: this.handleDeleteObservationClick, divider: true, type: 'delete' },
    { id: 3, label: 'Archive', lt: 1, gt: -1, isOrOperator: false, action: this.handleArchiveObservationClick, divider: false, type: 'archive' }
  ];
    return (
        <div>
          {!this.state.isLoading && <EnhancedTable
                                        logosKey={this.state.key}
                                        title='Observations'
                                        tooltip=''
                                        items={this.state.rows}
                                        menuItems={menuItems}
                                        handleItemClick={this.handleObservationClick}
                                        rowHeaders={rowHeaders}
                                        orderBy='title'
                                        order='asc'
                                        />}
          </div>
    );
  }

  renderLoader() {
    const label = this.state.isDeleting ? (this.state.alertType == 'delete' ? 'deleting...' : 'archiving...') : 'loading...';
    return(

      <div id="splash" style={{paddingTop: 250, zIndex:999}}>

    		<center id="splash">
    			<img src={loaderIcon}/>
          <Typography style={{padding: '4px'}} variant="caption">{label}</Typography>
    		</center>
    	</div>



    )
  }


  render() {
    //{this.state.isLoading || this.state.isDeleting ? this.renderLoader() : this.state.properties.length > 0 ? this.renderProperties() : this.renderLander()}
    return (
    <div>
        <Breadcrumb className="breadcrumb" style={{top: 58, left: 66, right: 0, position: 'absolute', zIndex:999, backgroundColor: '#FFF'}}>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Observations</Breadcrumb.Item>
        </Breadcrumb>

      <div className="Observations" style={{position: 'absolute', left:80, right:80,top: 80, paddingBottom:50}}>
        {this.state.isLoading || this.state.isDeleting || this.state.isSaving ? this.renderLoader() : this.state.observations.length > 0 ? this.renderObservations() : this.renderLander()}
      </div>

      <Dialog
          open={this.state.alertOpen}
          onClose={this.handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.state.alertType == "delete" ? "Delete Observations" : "Archive Observations"}</DialogTitle>
          <DialogContent>
            {this.state.alertType == "delete" ? <DialogContentText id="alert-dialog-description">Are you sure you wish to delete the selected observations? <br/><br/> This cannot be undone.</DialogContentText> : <DialogContentText id="alert-dialog-description">Are you sure you wish to archive the selected observations?</DialogContentText>}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAlertClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.state.alertType == "delete" ? this.handleDeleteObservations : this.handleArchiveObservations} color="primary" autoFocus>
              {this.state.alertType == "delete" ? "Delete" : "Archive"}
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}
