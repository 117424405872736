import React, { Component } from "react";
import { Glyphicon, InputGroup, FormGroup, FormControl, ControlLabel, Breadcrumb } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./NewJob.css";
import { API, Storage } from "aws-amplify";
import { s3UploadBlob } from "../libs/awsLib";

//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import loaderIcon from'../images/qkeyz.gif';


import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/CancelRounded';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Tooltip from '@material-ui/core/Tooltip';

import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import SoundIcon from '@material-ui/icons/MusicNoteRounded';
import AddIcon from '@material-ui/icons/AddCircleOutlineRounded';
import JobIcon from '@material-ui/icons/BuildRounded';
import KeyIcon from '@material-ui/icons/VpnKeyRounded';
import ObsIcon from '@material-ui/icons/VisibilityRounded';
import Button from '@material-ui/core/Button';


import Sound from 'react-sound';
import Slide from '@material-ui/core/Slide';


import { Icon, List, Image, Dropdown } from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
/*import moment from 'moment'*/



const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },

});




function Transition(props) {
  return <Slide direction="right" {...props} />;
}
export default class NewJob extends Component {
  constructor(props) {
    super(props);


console.log(props);
    const now = Date.now();
    this.file = null;
    const isEditing = this.props.location.state && this.props.location.state.jobId;
    this.state = {
      anchorEl: null,
      isLightBoxOpen: false,
      isEditing: false,
      alertTitle: '',
      alertMessage: '',
      isLoading: isEditing,
      isUserEditor: true,
      isSelectorDialogOpen: false,
      audioPath: null,
      currentAudioFile: null,
      currentPhotoFile: null,
      playerPosition:"",
      isPlaying: false,
      isSaving: null,
      job: null,
      code: "",
      accountingCode: "",
      propertyId: "",
      title: "",
      locus: "",
      category: 'VIEW',
      categoryDesc: "",
      description: "",
      visitorId: "",
      firstName: "",
      lastName: "",
      orgName: null,
      keys: [],
      obs: [],
      recordings: [],
      photos: [],
      photoPaths: [],
      audioPaths: [],
      photoPathsBackup: [],
      currentPhoto: null,
      notesAdmin: null,
      notesContractor: null,
      startDate: now,
      endDate: now,
      extraDate: null,
      currentStatus: "NONE",
      associatedIds: [],

      associationsChanged: false,
      photosChanged: false,
      alertOpen: false,
      alertType: '',
      categories: [{value: 'INSPECT', title: 'Inspection'},{value: 'VIEW', title: 'Viewing'},{value: 'CLEAN', title: 'Cleaning'},
              {value: 'PLUMB', title: 'Plumbing'},
              {value: 'ELEC', title: 'Electrical'},
              {value: 'FURN', title: 'Furnishings'},
              {value: 'PAINT', title: 'Painting'},
              {value: 'FIRE', title: 'Fire Systems'},
              {value: 'OTHER', title: 'Other'}
            ],
      statuses: [{value: 'WAITING', title: 'Waiting to start'}, {value: 'STARTED', title: 'Started'},
      {value: 'COMPLETED', title: 'Completed'}

            ],
      properties: [],
      visitors: [],
      keyList: [],
      obsList: [],
      keyListPrev: [],
      obsListPrev: [],


      currkeyid: ''

    };
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
}

  async componentDidMount() {
    if (!this.props.isAuthenticated || !this.props.org) {
      this.props.history.push("/");
      return;
    }

    // GET PROPERTIES LIST
    const result = await this.getOrgProperties();
    const properties = result.Items;
    if (properties.length == 0) {
      alert("NO PROPERTIES");
      this.props.history.push("/");
      return;
    }
    let propsList = [];
    for (var i = 0; i < properties.length; ++i) {
      let p = properties[i];
      propsList.push({value: this.getPropertyIdFromSk(p.sk), title: p.title});
    }

    // GET VISITORS and EMPLOYEE LIST
    const vResult = await this.getOrgVisitors();
    const visitors = vResult.Items;
    const eResult = await this.getOrgEmployees();
    const employees = eResult.Items;
    //console.log("VISITORS");
    //console.log(visitors);
    if (visitors.length == 0 && employees.length == 0) {
      alert("Please first add employees and/or visitors to your organisation.");
      this.props.history.push("/");
    }
    let visitorsList = [];
    for (var i = 0; i < visitors.length; ++i) {
      const v = visitors[i];
      let text = v.firstName + " " + v.lastName;
      if (v.orgName) {
        text += " - " + v.orgName;
      }
      text += " (" + v.categoryDesc + ")"
      visitorsList.push({ key: v.sk, value: this.getIdFromSk(v.sk), text: text, firstname: v.firstName, lastname: v.lastName, orgname: v.orgName, image: { avatar: true, src: v.binaryImage }, binaryimage: v.binaryImage});
    }
    for (var i = 0; i < employees.length; ++i) {
      const v = employees[i];
      let text = v.firstName + " " + v.lastName;
      if (v.orgName) {
        text += " - " + v.orgName;
      }
      text += " (" + v.categoryDesc + ")"
      visitorsList.push({ key: v.sk, value: this.getIdFromSk(v.sk), text: text, firstname: v.firstName, lastname: v.lastName, orgname: v.orgName, image: { avatar: true, src: v.binaryImage }, binaryimage: v.binaryImage});
    }

      console.log(visitorsList);

      if (this.props.location.state && this.props.location.state.jobId) {
        this.viewOnly = this.props.location.state.viewOnly
        this.jId = this.props.location.state.jobId
         try {
          const job = await this.getJob(this.jId);
          const { code, accountingCode, propertyId, title, locus, category, categoryDesc, description, firstName, lastName, orgName, userId, keyIds, photos, recordings, notesAdmin, notesContractor, startDate, endDate, extraDate, currentStatus, associatedIds, createdBy } = job;

          const visitorId = userId;
          let keys = [];
          if (keyIds) {
            keys = keyIds;
          }
          let obs = [];
          if (associatedIds) {
            obs = associatedIds;
          }

              //NB MUST GET PROPERTY KEYS AND OBS HERE
              // BUT ONLY IF keys/obs NOT NULL

              const kl = [];
              const ol = [];
              let keyList = [];
              let obsList = [];
              if (propertyId && keys.length > 0) {
                const result = await this.getKeys(propertyId);
                const allkeys = result.Items;
                for (var i = 0; i < allkeys.length; ++i) {
                  const k = allkeys[i];
                  //kl.push({ index: i, key: k.sk, code: k.code, locus: k.locus, selected: false});
                  kl.push({ key: k.sk, value: this.getKeyIdFromSk(k.sk), text: k.code + ' - ' + k.locus, image: null, code: k.code, locus: k.locus, selected: false});
                }
                keyList = this.getResetKeyList(keys, kl);
              }
              if (propertyId && associatedIds.length > 0) {
                const result = await this.getObs(propertyId);
                const allobs = this.getFilteredList(result.Items, 'currentStatus', 'RESOLVED');
                //const allobs = result.Items;
                console.log("allobsx");
                console.log(allobs);

                for (var i = 0; i < allobs.length; ++i) {
                  const o = allobs[i];
                  ol.push({ index: i, key: o.sk, category: o.category, categoryDesc: o.categoryDesc, details: o.description, locus: o.locus, selected: false});
                }
                obsList = this.getResetObsList(associatedIds, ol);
              }
              if (photos && photos.length > 0) {
                let pResult = await this.getOrgJobPics(this.jId);
                let obsPics = pResult.Items;
                let photoPaths = [];
                for (let i = 0; i < obsPics.length; ++i) {
                  let idParts = obsPics[i].sk.split("|NIL|");
                  let picBareId = idParts[1]; //idParts[idParts.length-1];
                  let id;
                  let title;
                  let timestamp;
                  for (let i2 = 0; i2 < photos.length; ++i2) {
                      if (photos[i2].id == picBareId) {
                        id = picBareId;
                        title = photos[i2].title;
                        timestamp = photos[i2].timestamp;
                        let img = obsPics[i].base64Data
                        photoPaths[i] = {file: id, img: img, id: i, title: title, timestamp: timestamp};
                        break;
                      }
                  }
                }
                this.setState({
                  photoPaths: photoPaths,
                  photoPathsBackup: photoPaths
                });
              }
              if (recordings && recordings.length > 0) {
                let aResult = await this.getOrgJobAudios(this.jId);
                let obsAudios = aResult.Items;
                let audioPaths = [];
                for (let i = 0; i < obsAudios.length; ++i) {
                  let idParts = obsAudios[i].sk.split("|NIL|");
                  let audioBareId = idParts[1]; //idParts[idParts.length-1];
                  let id;
                  let duration;
                  for (let i2 = 0; i2 < recordings.length; ++i2) {
                      if (recordings[i2].id == audioBareId) {
                        id = audioBareId;
                        duration = recordings[i2].duration;
                        let base64String = obsAudios[i].base64Data
                        audioPaths[i] = {file: id, base64String: base64String, id: i, duration: duration};
                        break;
                      }
                  }
                }
                this.setState({
                  audioPaths: audioPaths
                });
              }


          this.setState({
            isEditing: true,
            isLoading: false,
            job,
            code: code,
            accountingCode: accountingCode,
            propertyId: propertyId,
            title: title,
            locus: locus,
            category: category,
            categoryDesc: categoryDesc,
            description: description,
            visitorId: visitorId,
            firstName: firstName,
            lastName: lastName,
            orgName: orgName,
            keys: keys,
            obs: obs,
            photos: photos ? photos : [],
            recordings: recordings ? recordings : [],
            notesAdmin: notesAdmin,
            notesContractor: notesContractor,
            startDate: startDate,
            endDate: endDate,
            extraDate: extraDate,
            currentStatus: currentStatus,
            associatedIds: associatedIds,
            properties: propsList,
            visitors: visitorsList,
            isUserEditor: this.props.user.pk === createdBy,
            keyList: keyList,
            obsList: obsList,
            keyListPrev: keyList,
            obsListPrev: obsList
          });
          //console.log(photos);
          //this.setPhotoPaths(photos); /// add back in when added android app function to take photos
        } catch (e) {
          console.log(">>>>>>>>>", e);
          alert(e);
        }
    } else if (this.props.location.state && this.props.location.state.obs) {
      const {obsId, propertyId, title, locus, category, categoryDesc, details} = this.props.location.state.obs;
      const oId = "PRP|OBS|NIL|" + obsId;
      console.log(obsId);
      let obsList = [];
      let ol = [];

      console.log(propertyId);
      if (propertyId) {
        const result = await this.getObs(propertyId);
        const allobs = this.getFilteredList(result.Items, 'currentStatus', 'RESOLVED');
        //const allobs = result.Items;
        console.log("allobs");
        console.log(allobs);
        for (var i = 0; i < allobs.length; ++i) {
          const o = allobs[i];
          ol.push({ index: i, key: o.sk, category: o.category, categoryDesc: o.categoryDesc, details: o.description, locus: o.locus, selected: false});
        }
        console.log(ol);
        obsList = this.getResetObsList([oId], ol);
      }
      this.setState({
        obs: [oId],
        propertyId: propertyId,
        title: title,
        locus: locus,
        category: category,
        categoryDesc: categoryDesc,
        description: details,
        properties: propsList,
        visitors: visitorsList,
        obsList: obsList
      });









    } else {
      this.viewOnly = false;
      propsList.unshift({id: '', title: 'select property ...'});
      this.setState({
        properties: propsList,
        visitors: visitorsList
      });
    }




  }

/*
  setPhotoPaths = async (photos) => {
    const orgId = this.props.org.pk;
    //const obsId = this.getJobIdFromSk(this.state.job.sk);
    let photoPaths = [];
    if (photos) {
      for (var i = 0; i < photos.length; ++i) {
        //let path = orgId + '/jobs/' + obsId + '/photos/' + photos[i];
        const p = photos[i];
        let path = orgId + '/photos/' + p.file;
        //console.log(path);
        //console.log(JSON.stringify(photos[i]));
        let attachmentURL = await Storage.get(path);
        photoPaths[i] = {file: p.file, img: attachmentURL, id: i, title: p.title ? p.title : '', timestamp: p.timestamp};
      }
    }
    this.setState({
      photoPaths: photoPaths,
      photoPathsBackup: photoPaths
    });
  }
  */
  getFilteredList(list, attrib, filter) {
    return list.filter(function (el) {
        return el[attrib] != filter;
      });

  }
  getJob(jobId) {
      return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|JOB|NIL/${jobId}`);
  }

  createJob(job) {
    job.id = "PRP|JOB|NIL|" + job.propertyId; // job id created in lambda function
    job.userName = this.props.user.firstName + " " + this.props.user.lastName;
    return API.post("qkeyz", "/items", {
      body: job
    });
  }


  updateJob(jobId, body) {
    try{
      body.userName = this.props.user.firstName + " " + this.props.user.lastName;
      return API.put("qkeyz", `/organisations/${this.props.org.pk}/items/PRP|JOB|NIL|${jobId}`, {body: body});
    }catch(e) {
      console.log(e);
    }
  }

  getOrgProperties() {
      return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|NIL`);
  }

  getOrgJobPics(bareObsId) {
      return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|JOB|PIC|NIL|${bareObsId}`);
  }

  getOrgJobAudios(bareObsId) {
      console.log(`/organisations/${this.props.org.pk}/PRP|JOB|AUD|NIL|${bareObsId}`);
      return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|JOB|AUD|NIL|${bareObsId}`);
  }






  getOrgVisitors() {
      return API.get("qkeyz", `/organisations/${this.props.org.pk}/VIS`);
  }
  getOrgEmployees() {
      return API.get("qkeyz", `/organisations/${this.props.org.pk}/EMP`);
  }
  getKeys(propertyId) {
    //console.log("PROPID" + propertyId);
    //return API.get("qkeyz", `/properties/${propertyId}/keys`);
    //return API.get("qkeyz", `/properties/${propertyId}/KEY`);
    return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|KEY|NIL|${propertyId}`);

  }
  getObs(propertyId) {
    return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|OBS|NIL|${propertyId}`);
  }

  getIdFromSk(sk) {
    return sk.substr(4);
  }
  getPropertyIdFromSk(sk) {
    return sk.substr(8);
  }
  getKeyIdFromSk(sk) {
    return sk.substr(12);
  }
  getJobIdFromSk(sk) {
    return sk.substr(12);
  }

  validateChangesOnly() {
    let keyChanges = false;
    if (this.state.job.keyIds) {
      keyChanges = this.state.keys !== this.state.job.keyIds;
    } else {
      keyChanges = this.state.keys.length > 0;
    }
    let obsChanges = false;
    if (this.state.job.associatedIds) {
      obsChanges = this.state.obs !== this.state.job.associatedIds;
    } else {
      obsChanges = this.state.obs.length > 0;
    }



    return this.validateForm() &&(this.state.accountingCode !== this.state.job.accountingCode || this.state.startDate !== this.state.job.startDate ||
      this.state.endDate !== this.state.job.endDate || keyChanges  || obsChanges || this.state.visitorId !== this.state.job.userId ||
      this.state.code !== this.state.job.code ||
      this.state.propertyId !== this.state.job.propertyId || this.state.locus !== this.state.job.locus || this.state.category !== this.state.job.category ||
      this.state.description !== this.state.job.description || this.state.currentStatus !== this.state.job.currentStatus|| this.state.notesAdmin !== this.state.job.notesAdmin
      );
  }

  validateForm() {
    const co = this.state.code;
    const p = this.state.propertyId;
    const l = this.state.locus.trim();
    const c = this.state.category;
    const d = this.state.description.trim();
    const v = this.state.visitorId;

    return p.length > 0 && v.length > 0 && l.length > 0 && c.length > 0 && d.length > 0 && co.length > 0;
  }

handleReset = event => {
  event.preventDefault();
  //this.file = null;
  //this.inputEl.value='';
  this.setState({
    code: this.state.job.code,
    accountingCode: this.state.job.accountingCode,

    propertyId: this.state.job.propertyId,
    title: this.state.job.title,
    locus: this.state.job.locus,
    category: this.state.job.category,
    categoryDesc: this.state.job.categoryDesc,
    description: this.state.job.description,


    visitorId: this.state.job.userId,
    firstName: this.state.job.firstName,
    lastName: this.state.job.lastName,
    orgName: this.state.job.orgName,
    keys: this.state.job.keyIds ? this.state.job.keyIds : [],
    obs: this.state.job.associatedIds ? this.state.job.associatedIds : [],
    keyList: this.state.keyListPrev,
    obsList: this.state.obsListPrev,
    photos: this.state.job.photos,
    photoPaths: this.state.photoPathsBackup,
    recordings: this.state.job.recordings ? this.state.job.recordings : [],
    notesAdmin: this.state.job.notesAdmin,

    notesContractor: this.state.job.notesContractor,
    startDate: this.state.job.startDate,
    endDate: this.state.job.endDate,
    extraDate: this.state.job.extraDate,

    currentStatus: this.state.job.currentStatus,
    associatedIds: this.state.job.associatedIds
  });


}

handleStartDateChange(date) {
  if (date) {
    let start = date.getTime();
    let end = this.state.endDate;
    if (start <= end) {
      this.setState({
        startDate: start
      });
    } else {
      this.setState({
        startDate: start,
        endDate: start
      });
    }
  }
}
handleEndDateChange(date) {
  if (date) {
    let end = date.getTime();
    let start = this.state.startDate;
    if (end >= start) {
      this.setState({
        endDate: end
      });
    } else {
      this.setState({
        startDate: end,
        endDate: end
      });
    }
  }
}

populateKeys() {

}

handleChange = event => {
  this.setState({
    [event.target.id]: event.target.value
  });
}

handlePropertyChange = async event => {
  const pId = event.target.value
  const prp = this.state.properties.find(o => o.value === pId);
  let propertyId = "";
  let title = "";
  if (prp) {
    propertyId = pId;
    title = prp.title;
  }
  this.setState({
    propertyId: propertyId,
    title: title,
    keys: [],
    obs: [],
    keyList: [],
    obsList: []
  })

/*


  const propertyId = event.target.value
  let prp = this.state.properties.find(o => o.value === propertyId);

  const keys = await this.getKeys(propertyId);
  const obs = await this.getObs(propertyId);
  let keyList = [];
  let obsList = [];
  for (var i = 0; i < keys.length; ++i) {
    const k = keys[i];
    keyList.push({ index: i, key: k.sk, code: k.code, locus: k.locus, selected: false});
  }
  console.log(obs);
  for (var i2 = 0; i2 < obs.length; ++i2) {
    const o = obs[i2];
    obsList.push({ index: i2, key: o.sk, category: o.category, categoryDesc: o.categoryDesc, details: o.description, locus: o.locus, selected: false});
  }
  this.setState({
    propertyId: propertyId,
    title: prp.title,
    keys: [],
    obs: [],
    keyList: keyList,
    obsList: obsList
  })

*/

}



handleDropDownChange = (event, data) => {
  //console.log(data);
  this.setState({
    visitorId: data.value
  });
}

handleKeyDropDownChange = (event, data) => {
  console.log(data);
  const kl = this.getResetKeyList(); //this.state.keyList;
  const key = kl.find(o => o.value === data.value);
  key.selected = true;
  this.setState({
    keyList: kl, currkeyid: data.value
  });
  /*
  this.setState({
    currkeyid: data.value
  });
  */
}


handleListClick = (event, data) => {
  console.log(data);
  const idx = parseInt(data.name);
  if (this.state.isSelectorDialogOpen) {
    if (this.state.alertType === 'keys') {
      const keyList = this.state.keyList;
      keyList[idx].selected = !this.state.keyList[idx].selected;
      this.setState({
        keyList: keyList
      })
    } else if (this.state.alertType === 'obs') {
      const obsList = this.state.obsList;
      obsList[idx].selected = !this.state.obsList[idx].selected;
      this.setState({
        obsList: obsList
      })
    }
  }
}

  handleFileChange = event => {
    this.file = event.target.files[0];
    if (this.file && this.file.type.indexOf("image") == -1) {
      this.file = null;
      this.inputEl.value='';
      this.setState({
        alertOpen: true,
        alertTitle: 'Invalid Image Format',
        alertMessage: 'Please choose a valid image for the job logo.'
      });
    } else {
      this.setState({

      });
    }
  }

handleSubmit = async event => {
  event.preventDefault();

  if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
    this.setState({
      alertOpen: true,
      alertTitle: 'Invalid Image File Size',
      alertMessage: 'Please choose a file smaller than 5MB.'
    });
    return;
  }

  this.setState({ isSaving: true });

  try {
    let cat = this.state.categories.find(o => o.value === this.state.category);
    let prp = this.state.properties.find(o => o.value === this.state.propertyId);
    let vis = this.state.visitors.find(o => o.value === this.state.visitorId);
    console.log(vis);
    if (this.state.isEditing) {
      await this.updateJob(this.jId, {
        accountingCode: this.state.accountingCode === '' ? null : this.state.accountingCode,

        propertyId: this.state.propertyId,
        title: prp.title ? prp.title : null,
        locus: this.state.locus.trim(),
        category: this.state.category,
        categoryDesc: cat.title ? cat.title : null,
        description: this.state.description.trim(),

        visitorId: vis ? this.state.visitorId : null,
        firstName: vis ? vis.firstname : null,
        lastName: vis ? vis.lastname: null,
        orgName: vis ? vis.orgname : null,
        keys: this.state.keys,
        associatedIds: this.state.obs,

        photos: this.state.photos,
        recordings: this.state.recordings,
        notesAdmin: this.state.notesAdmin ? this.state.notesAdmin : null,

        notesContractor: this.state.notesContractor ? this.state.notesContractor : null,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        extraDate: this.state.extraDate,

        currentStatus: this.state.currentStatus
      });
    } else {
      const result = await this.createJob({
        orgId: this.props.org.pk ,
        code: this.state.code,
        accountingCode: this.state.accountingCode === '' ? null : this.state.accountingCode,

        propertyId: this.state.propertyId,
        title: prp.title ? prp.title : null,
        locus: this.state.locus.trim(),
        category: this.state.category,
        categoryDesc: cat.title ? cat.title : null,
        description: this.state.description.trim(),

        visitorId: vis ? this.state.visitorId : null,
        firstName: vis ? vis.firstname : null,
        lastName: vis ? vis.lastname: null,
        orgName: vis ? vis.orgname : null,
        keys: this.state.keys,
        associatedIds: this.state.obs,

        photos: this.state.photos,
        recordings: this.state.recordings,
        notesAdmin: this.state.notesAdmin ? this.state.notesAdmin : null,

        notesContractor: this.state.notesContractor ? this.state.notesContractor : null,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        extraDate: this.state.extraDate,

        currentStatus: this.state.currentStatus
      });
      if (result.status === 'failed') {
        if (result.reason === 'exists') {
          this.setState({
            alertOpen: true,
            alertTitle: 'Job ID Exists',
            alertMessage: 'The Job ID you have entered has already been used for another job. Please enter a unique Job ID.',
            isSaving: false
          });
          return;
        }
      }

    }
    this.props.history.push("/jobs");
  } catch (e) {
    this.setState({
      alertOpen: true,
      alertType: '',
      alertTitle: 'Error',
      alertMessage: e.message,
      isSaving: false
    });
  }
}
handleDeletePhoto = tile => () => {
    this.setState({
      alertOpen: true,
      alertType: 'deletePhoto',
      currentPhotoFile: tile.file,
      alertMessage: 'Are you sure you wish to delete this photo?',
      alertTitle: 'Delete Photo'
    });
};
handleAlertDeletePhoto = () => {
  console.log(this.state.currentPhotoFile);
  let idx = -1;
  const chipDataOrig = [...this.state.photos];
  const chipData = [...this.state.photoPaths];
  for (var i = 0; i < chipData.length; ++i) {
    let p = chipData[i];
    if (p.file === this.state.currentPhotoFile) {
      idx = i;
      break;
    }
  }
  if (idx > -1) {
    chipData.splice(idx, 1);
    chipDataOrig.splice(idx, 1);
    this.setState({
      alertOpen: false,
      photos: chipDataOrig,
      photoPaths: chipData,
      currentPhotoFile: null
    });
  } else {
    this.setState({
      alertOpen: false,
      currentPhotoFile: null
    });
  }
}

handleZoomPhoto = tile => () => {
  console.log(tile);
  this.setState({
    currentPhoto: tile,
    isLightBoxOpen: true
  });
};
handleDeleteKey = data => () => {
      this.setState({
        alertOpen: true,
        alertType: 'deleteKey',
        currentData: data,
        alertMessage: 'Are you sure you wish to remove this key?',
        alertTitle: 'Remove Key'
      });
  };
  handleDeleteObs = data => () => {
        this.setState({
          alertOpen: true,
          alertType: 'deleteObs',
          currentData: data,
          alertMessage: 'Are you sure you wish to remove this observation?',
          alertTitle: 'Remove Observation'
        });
    };


handleAlertDeleteObject = () => {
  let idx = -1;

  if (this.state.alertType === 'deleteKey') {
    const chipData = [...this.state.keys];
    for (var i = 0; i < chipData.length; ++i) {
      let k = chipData[i];
      if (k === this.state.currentData) {
        idx = i;
        break;
      }
    }
    if (idx > -1) {
      chipData.splice(idx, 1);
      const keyList = this.getResetKeyList(chipData);
      this.setState({
        alertOpen: false,
        keys: chipData,
        keyList: keyList,
        currentData: null
      });
    } else {
      this.setState({
        alertOpen: false,
        currentData: null
      });
    }
  } else if (this.state.alertType === 'deleteObs') {
    const chipData = [...this.state.obs];
    for (var i = 0; i < chipData.length; ++i) {
      let o = chipData[i];
      if (o === this.state.currentData) {
        idx = i;
        break;
      }
    }
    if (idx > -1) {
      chipData.splice(idx, 1);
      const obsList = this.getResetObsList(chipData);
      this.setState({
        alertOpen: false,
        obs: chipData,
        obsList: obsList,
        currentData: null
      });
    } else {
      this.setState({
        alertOpen: false,
        currentData: null
      });
    }
  }
}



handleDeleteRecording = data => () => {
    if (this.state.isPlaying) {
      this.setState({
        isPlaying: false,
        audioPath: null
      });
    } else {
      this.setState({
        alertOpen: true,
        alertType: 'deleteRecording',
        currentAudioFile: data.file,
        alertMessage: 'Are you sure you wish to delete this recording?',
        alertTitle: 'Delete Recording'
      });
    }
  };
  handleAlertDeleteRecording = () => {
    console.log(this.state.currentAudioFile);
    let idx = -1;
    const chipData = [...this.state.audioPaths];
    for (var i = 0; i < chipData.length; ++i) {
      let r = chipData[i];
      if (r.file === this.state.currentAudioFile) {
        idx = i;
        break;
      }
    }
    if (idx > -1) {
      chipData.splice(idx, 1);
      this.setState({
        alertOpen: false,
        recordings: chipData,
        currentAudioFile: null
      });
    } else {
      this.setState({
        alertOpen: false,
        currentAudioFile: null
      });
    }
  }
  handlePlayRecording = data => async () => {
    if (this.state.isPlaying) {
      this.setState({
        isPlaying: false,
        audioPath: null
      });
      return;
    }
    //let attachmentURL = await this.getVoiceFilePath(data.file);

    //console.log(attachmentURL);

    if (data.base64String) {
      this.setState({
        audioPath: data.base64String,
        currentAudioFile: data.file,
        playerPosition: (data.duration/1000).toFixed(2) + "s",
        isPlaying: true
      });
    } else {
      this.setState({
        isPlaying: false,
        currentAudioFile: null,
        audioPath: null
      });
    }

  };
  getVoiceFilePath = async fileName => {
    console.log('attachmentURL');
    const orgId = this.props.org.pk;
    //const obsId = this.getJobIdFromSk(this.state.job.sk);
    //let attachmentURL = await Storage.get(orgId + '/obs/' + obsId + '/audio/' + fileName);
    let attachmentURL = await Storage.get(orgId + '/audio/' + fileName);
    console.log(attachmentURL);
    return attachmentURL;
  }

  handleFinishedPlaying = () => {

    this.setState({
      isPlaying: false,
      currentAudioFile: null,
      audioPath: null
    });
    console.log('Finished!');
  };
  handlePlaying = (player) => {
    console.log('PLAYING: ', player.position, player.duration);
    let currentVal = ((player.duration - player.position)/1000).toFixed(2) + "s";
    this.setState({
      playerPosition: currentVal
    });
  };
getReducedImageAsBlob(context) {
  var reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(null);
    };
    reader.onload = () => {
      var img = new Image();
      img.onload = function() {
          console.log('IMG: ', img.height, img.width);
          let h = 200;
          let w = 200;
          if (img.height > img.width) {
            w = w * (img.width / img.height);
          } else {
            h = h * (img.height / img.width);
          }
          var oc = document.createElement('canvas');
          oc.width = w;
          oc.height = h;
          oc.getContext('2d').drawImage(img, 0, 0, w, h);
          var dataurl = oc.toDataURL(context.file.type);
          //console.log(dataurl);
          var blob = context.dataURItoBlob(dataurl, context.file.type);
          resolve(blob);
        }
      img.src = reader.result
      };
    reader.readAsDataURL(context.file);
  });
}

dataURItoBlob(dataURI, fileType) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: fileType});
}



/*

const propertyId = event.target.value
let prp = this.state.properties.find(o => o.value === propertyId);

const keys = await this.getKeys(propertyId);
const obs = await this.getObs(propertyId);
let keyList = [];
let obsList = [];
for (var i = 0; i < keys.length; ++i) {
  const k = keys[i];
  keyList.push({ index: i, key: k.sk, code: k.code, locus: k.locus, selected: false});
}
console.log(obs);
for (var i2 = 0; i2 < obs.length; ++i2) {
  const o = obs[i2];
  obsList.push({ index: i2, key: o.sk, category: o.category, categoryDesc: o.categoryDesc, details: o.description, locus: o.locus, selected: false});
}
this.setState({
  propertyId: propertyId,
  title: prp.title,
  keys: [],
  obs: [],
  keyList: keyList,
  obsList: obsList
})

*/


handleAssignObs = async () => {
  if (this.state.obsList.length > 0) {
    this.setState({
      isSelectorDialogOpen: true,
      alertType: 'obs',
      alertTitle: this.state.title + (this.state.locus ? ' - ' + this.state.locus : ''),
      alertMessage: 'Select observations to associate with this job:'
    });
  } else {
    if (this.state.propertyId === '') {
      this.setState({
        alertOpen: true,
        alertType: 'general',
        alertTitle: 'No Property Selected',
        alertMessage: 'Please first select a property.'
      });
    } else {
      this.setState({
        isSelectorDialogOpen: true,
        alertType: 'obs',
        alertTitle: this.state.title + (this.state.locus ? ' - ' + this.state.locus : ''),
        alertMessage: 'Loading observations...'
      });
      const result = await this.getObs(this.state.propertyId);
      const obs = this.getFilteredList(result.Items, 'currentStatus', 'RESOLVED');
      //const obs = result.Items;
      //console.log("WTF");
      console.log(obs);

      const obsList = [];
      for (var i = 0; i < obs.length; ++i) {
        const o = obs[i];
        obsList.push({ index: i, key: o.sk, category: o.category, categoryDesc: o.categoryDesc, details: o.description, locus: o.locus, selected: false});
      }
      let message = 'Select observations to associate with this job:';
      if (obsList.length === 0) {
        message = 'There are no observations available for this property.';
      }
      this.setState({
        alertMessage: message,
        obsList: obsList
      });
    }
  }
};






handleAssignKeys = async() => {
  if (this.state.keyList.length > 0) {
    this.setState({
      isSelectorDialogOpen: true,
      alertType: 'keys',
      alertTitle: this.state.title + (this.state.locus ? ' - ' + this.state.locus : ''),
      alertMessage: 'Select key required for this job:'
    });
  } else {
    if (this.state.propertyId === '') {
      this.setState({
        alertOpen: true,
        alertType: 'general',
        alertTitle: 'No Property Selected',
        alertMessage: 'Please first select a property.'
      });
    } else {
      this.setState({
        isSelectorDialogOpen: true,
        alertType: 'keys',
        alertTitle: this.state.title + (this.state.locus ? ' - ' + this.state.locus : ''),
        alertMessage: 'Loading keys...'
      });
      const result = await this.getKeys(this.state.propertyId);
      const keys = result.Items;
      let keyList = [];
      for (var i = 0; i < keys.length; ++i) {
        const k = keys[i];
        //keyList.push({ index: i, key: k.sk, code: k.code, locus: k.locus, selected: false});
        keyList.push({ key: k.sk, value: this.getKeyIdFromSk(k.sk), text: k.code + ' - ' + k.locus, image: null, code: k.code, locus: k.locus, selected: false});
      }
      let message = 'Select key required for this job:';
      if (keyList.length === 0) {
        message = 'There are no keys available for this property.';
      }
      this.setState({
        alertMessage: message,
        keyList: keyList
      });
    }
  }






















};



handleAlertClose = () => {
    this.setState({
      alertOpen: false,
      alertType: '',
      alertTitle: '',
      alertMessage: ''
    });
};
handleLightBoxClose = () => {
    this.setState({ isLightBoxOpen: false, currentPhoto: null });
};

handleSelectorDialogClose = () => {
  if (this.state.alertType === 'keys') {
    const keyList = this.getResetKeyList();
    this.setState({ isSelectorDialogOpen: false, alertType: '', keyList: keyList, currkeyid: ''});
  } else if (this.state.alertType === 'obs') {
    const obsList = this.getResetObsList();
    this.setState({ isSelectorDialogOpen: false, alertType: '', obsList: obsList, currkeyid: ''});
  }
};

handleSelectorDialogSelect = () => {
  const objects = [];
  if (this.state.isSelectorDialogOpen) {
    if (this.state.alertType === 'keys') {
      for (var i = 0; i < this.state.keyList.length; ++i) {
        if (this.state.keyList[i].selected) {
          objects.push(this.state.keyList[i].key);
        }
      }
      this.setState({ isSelectorDialogOpen: false, alertType: '', keys: objects, currkeyid: ''});
    } else if (this.state.alertType === 'obs') {
      for (var i = 0; i < this.state.obsList.length; ++i) {
        if (this.state.obsList[i].selected) {
          objects.push(this.state.obsList[i].key);
        }
      }
      this.setState({ isSelectorDialogOpen: false, alertType: '', obs: objects, currkeyid: ''});
    }
  }
};

handleCrumbClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}


getResetKeyList(newKeys, newKeyList) {
  let keys = this.state.keys;
  if (newKeys) {
    keys = newKeys;
  }
  let keyList = this.state.keyList;
  if (newKeyList) {
    keyList = newKeyList;
  }
  for (var i = 0; i < keyList.length; ++i) {
    keyList[i].selected = false;
    for (var i2 = 0; i2 < keys.length; ++i2) {
      if (keys[i2] === keyList[i].key) {
        keyList[i].selected = true;
        break;
      }
    }
  }
  console.log(newKeys);
  console.log(newKeyList);
  console.log(keyList);
  return keyList;
}
getResetObsList(newObs, newObsList) {
  let obs = this.state.obs;
  if (newObs) {
    obs = newObs;
  }
  let obsList = this.state.obsList;
  if (newObsList) {
    obsList = newObsList;
  }
  for (var i = 0; i < obsList.length; ++i) {
    obsList[i].selected = false;
    for (var i2 = 0; i2 < obs.length; ++i2) {
      if (obs[i2] === obsList[i].key) {
        obsList[i].selected = true;
        break;
      }
    }
  }
  return obsList;
}

renderFields() {

  return(
    <div className="NewJob" style={{position: 'absolute', left:80, right:80,top: 110}}>
       <form onSubmit={this.handleSubmit}>

<Paper className={"generalHorizontalLayout"}>
       <FormGroup controlId="code">

       <ControlLabel>Job ID*</ControlLabel>
       <div className="layoutchild">
         <FormControl
          disabled={this.state.isEditing}
           onChange={this.handleChange}
           value={this.state.code}
           componentClass="input"
           type="text"
         />
         </div>
       </FormGroup>

       <FormGroup controlId="accountingCode">
       <ControlLabel>Accounting Code</ControlLabel>
       <div className="layoutchild">
         <FormControl
           onChange={this.handleChange}
           value={this.state.accountingCode ? this.state.accountingCode : ''}
           componentClass="input"
           type="text"
         />
         </div>
       </FormGroup>



       <FormGroup controlId="startDate">
       <ControlLabel>Start Date</ControlLabel>
       <div className="layoutchild">
       <DatePicker
               selected={new Date(this.state.startDate)}
               onChange={this.handleStartDateChange}
               showTimeSelect
               timeFormat="HH:mm"
               timeIntervals={30}
               dateFormat="dd MMM yyyy h:mm aa"
               timeCaption="time"
               className={'dateborder'}
             />
             </div>
       </FormGroup>
       <FormGroup controlId="endDate">
       <ControlLabel>End Date</ControlLabel>
       <div className="layoutchild">
       <DatePicker
               selected={new Date(this.state.endDate)}
               onChange={this.handleEndDateChange}
               showTimeSelect
               timeFormat="HH:mm"
               timeIntervals={30}
               dateFormat="dd MMM yyyy h:mm aa"
               timeCaption="time"
               className={'dateborder'}
             />
             </div>
       </FormGroup>
       <FormGroup controlId="currentStatus">
       <ControlLabel>Status</ControlLabel>
         <FormControl
           componentClass="select"
           placeholder="currentStatus"
           onChange={this.handleChange}
           value={this.state.currentStatus ? this.state.currentStatus : ''}
           >
             {
               this.state.statuses.map((status, index) => {
                 return (<option key={index} value={status.value}>{status.title}</option>)
               })
             }

         </FormControl>
       </FormGroup>

</Paper>
<Paper className={"generalHorizontalLayout"}>

       <FormGroup controlId="propertyId">
       <ControlLabel>Property*</ControlLabel>
       <div className="layoutchild">
         <FormControl
           componentClass="select"
           placeholder="property"
           onChange={this.handlePropertyChange}
           value={this.state.propertyId}
           >
             {
               this.state.properties.map((prop, index) => {
                 return (<option key={index} value={prop.value}>{prop.title}</option>)
               })
             }

         </FormControl>
         </div>
       </FormGroup>


       <FormGroup controlId="locus" className={"lastControlInLine"}>
       <ControlLabel>Locus*</ControlLabel>
         <FormControl
          onChange={this.handleChange}
           value={this.state.locus}
           componentClass="input"
           type="text"
         />
       </FormGroup>
 </Paper>
 <Paper className={"noHorizontalLayout"}>

       <FormGroup controlId="category">
       <ControlLabel>Category*</ControlLabel>
         <FormControl
           componentClass="select"
           placeholder="category"
           onChange={this.handleChange}
           value={this.state.category}
           >
             {
               this.state.categories.map((cat, index) => {
                 return (<option key={index} value={cat.value}>{cat.title}</option>)
               })
             }

         </FormControl>
       </FormGroup>


       <FormGroup controlId="description">
       <ControlLabel>Details*</ControlLabel>
         <FormControl
           onChange={this.handleChange}
           value={this.state.description}
           componentClass="textarea"
         />
       </FormGroup>
</Paper>




{false && this.state.isEditing && this.state.audioPaths.length > 0 &&

       <FormGroup controlId="recordings">
       <ControlLabel>Voice Recordings</ControlLabel>

       <Paper className={"recList"}>
         {this.state.audioPaths.map(data => {
           let icon = null;

           //if (data.label === 'React') {
             icon = <SoundIcon />;
           //}
           return (
             <Chip
               key={data.file}
               icon={icon}
               label={this.state.isPlaying && data.file === this.state.currentAudioFile ? this.state.playerPosition : (data.duration/1000).toFixed(2) + "s"}
               onDelete={this.handleDeleteRecording(data)}
               onClick={this.handlePlayRecording(data)}
               color={(this.state.isPlaying || (this.state.alertType === 'deleteRecording' && this.state.alertOpen)) && data.file === this.state.currentAudioFile ? 'primary' : 'secondary'}
               className={"chip"}
             />
           );
         })}
       </Paper>



</FormGroup>
}
{false && this.state.isEditing && this.state.photoPaths.length > 0 &&
       <FormGroup controlId="pics">
       <ControlLabel>Photos</ControlLabel>


<Paper className={"recList"}>



         {this.state.photoPaths.map(tile => (
           <div key={tile.file} className={"ibcontainer"}>
           <img src={tile.img} alt={tile.title} onClick={this.handleZoomPhoto(tile)}/>
           <IconButton className={"btn"}
              onClick={this.handleDeletePhoto(tile)}
           >
             <CancelIcon/>
           </IconButton>

           </div>

           ))}



</Paper>
</FormGroup>



}









<Paper className={"noHorizontalLayout"}>
<FormGroup controlId="associatedIds">
<ControlLabel>Assign Observations
<IconButton
   onClick={this.handleAssignObs}
>
  <AddIcon/>
</IconButton></ControlLabel>

<div className={"recList"}>

  {this.state.obs.map(data => {
      const icon = <ObsIcon />;
      let obs = this.state.obsList.find(o => o.key === data);
      if (obs) {
    return (
      <Tooltip key={data} title={<React.Fragment>
              <Typography color="inherit">{obs.locus + " - " + obs.categoryDesc}</Typography>
              {obs.details}
              <span />
            </React.Fragment>}>
      <Chip
        key={data}
        icon={icon}
        label={obs.categoryDesc}
        onDelete={this.handleDeleteObs(data)}
        color={this.state.alertType === 'deleteObs' && this.state.alertOpen && data === this.state.currentData ? 'primary' : 'secondary'}
        className={"chip"}
      />
      </Tooltip>
    );
  }
  })}
</div>
</FormGroup>
</Paper>

<Paper className={"noHorizontalLayout"}>
<FormGroup controlId="keys">
<ControlLabel>Assign Keys
<IconButton
   onClick={this.handleAssignKeys}
>
  <AddIcon/>
</IconButton></ControlLabel>

<div className={"recList"}>

  {this.state.keys.map(data => {
      const icon = <KeyIcon />;
      let key = this.state.keyList.find(o => o.key === data);
      if (key) {
    return (
      <Tooltip key={data} title={<React.Fragment>
              <Typography color="inherit">{key.code}</Typography>
              {key.locus}
              <span />
            </React.Fragment>}>
      <Chip
        key={data}
        icon={icon}
        label={key.code}
        onDelete={this.handleDeleteKey(data)}
        color={this.state.alertType === 'deleteKey' && this.state.alertOpen && data === this.state.currentData ? 'primary' : 'secondary'}
        className={"chip"}
      />
      </Tooltip>
    );
  }
  })}
</div>
</FormGroup>
</Paper>

<Paper className={"noHorizontalLayout"}>
<FormGroup controlId="visitor">
<ControlLabel>Assign Visitor/Contractor*</ControlLabel>
<Dropdown
   placeholder='Select Visitor / Contractor'
   onChange={this.handleDropDownChange}
   fluid
   search
   deburr
   selection
   options={this.state.visitors}
   value={this.state.visitorId}
 />
</FormGroup>
</Paper>



         <FormGroup controlId="notesAdmin">
         <ControlLabel>Admin Notes</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.notesAdmin ? this.state.notesAdmin : ''}
             componentClass="textarea"
           />
         </FormGroup>



         {this.state.isEditing ?
           <div>
                     <LoaderButton

           bsStyle="primary"
           bsSize="large"
           disabled={!this.validateChangesOnly()}
           type="submit"
           isLoading={this.state.isSaving}
           text="Save"
           loadingText="Saving…"
         />

         <LoaderButton


           bsSize="large"
           disabled={!this.validateChangesOnly()}
           isLoading={false}
           text="Cancel"
           onClick={this.handleReset}
           loadingText="Cancelling…"
         />
         </div>
         :
         <LoaderButton
           bsStyle="primary"
           bsSize="large"
           disabled={!this.validateForm()}
           type="submit"
           isLoading={this.state.isSaving}
           text="Create"
           loadingText="Creating new job…"
         />}



         <Sound
            url={this.state.audioPath ? this.state.audioPath : ''}
            playStatus={this.state.audioPath && this.state.isPlaying ? Sound.status.PLAYING : Sound.status.STOPPED}
            onLoading={this.handleLoading}
            onPlaying={this.handlePlaying}
            onFinishedPlaying={this.handleFinishedPlaying}
            />



       </form>
     </div>
  )
}


renderMinFields() {
  let visName = 'unassigned';
  if (this.state.firstName) {
    visName = this.state.firstName + ' ' + this.state.lastName;
    if (this.state.orgName) {
      visName = visName + ' (' + this.state.orgName + ')';
    }
  }
  console.log('KEYZx:');
  console.log(this.state.keys);
  return(
    <div className="NewJob" style={{position: 'absolute', left:80, right:80,top: 110}}>
    <Card className="card">
   <CardContent>
   <Typography gutterBottom variant="h5" component="h2">
 <b>{this.state.title} - {this.state.locus}</b>
</Typography>
<Typography gutterBottom variant="h6" component="h1">
{this.state.categoryDesc}
</Typography>
<Typography component="p">
  {this.state.description}
  <br/><br/>
</Typography>
<Typography  gutterBottom variant="h6" component="h1">
Period: <b>{(new Date(this.state.startDate)).toLocaleString()}</b> to <b>{(new Date(this.state.endDate)).toLocaleString()}</b>
  <br/>
</Typography>
<Typography  gutterBottom variant="h6" component="h1">
Assigned Contractor / Visitor: <b>{visName}</b>
  <br/>
</Typography>



<Typography component="p">
  <b>Associated Keys:</b>
</Typography>
 <Paper elevation={1}  className={"visitor_feedback_paper"}>
  {this.state.keyList.length > 0 &&
<div className={"recList"}>

  {this.state.keys.map(data => {
      const icon = <KeyIcon />;
      let key = this.state.keyList.find(o => o.key === data);
      if (key) {
    return (
      <Tooltip key={data} title={<React.Fragment>
              <Typography color="inherit">{key.code}</Typography>
              {key.locus}
              <span />
            </React.Fragment>}>
      <Chip
        key={data}
        icon={icon}
        label={key.code}
        color={this.state.alertType === 'deleteKey' && this.state.alertOpen && data === this.state.currentData ? 'primary' : 'secondary'}
        className={"chip"}
      />
      </Tooltip>
    );
  }
  })}
</div>
}
</Paper>





<Typography component="p">
  <b>Associated Observations:</b>
</Typography>
 <Paper elevation={1}  className={"visitor_feedback_paper"}>
  {this.state.obsList.length > 0 &&
    <div className={"recList"}>

      {this.state.obs.map(data => {
          const icon = <ObsIcon />;
          let obs = this.state.obsList.find(o => o.key === data);
          if (obs) {
        return (
          <Tooltip key={data} title={<React.Fragment>
                  <Typography color="inherit">{obs.locus + " - " + obs.categoryDesc}</Typography>
                  {obs.details}
                  <span />
                </React.Fragment>}>
          <Chip
            key={data}
            icon={icon}
            label={obs.categoryDesc}
            color={this.state.alertType === 'deleteObs' && this.state.alertOpen && data === this.state.currentData ? 'primary' : 'secondary'}
            className={"chip"}
          />
          </Tooltip>
        );
      }
      })}
    </div>
}
</Paper>





<Typography component="p">
  <b>Contractor / Visitor Feedback:</b>
</Typography>
 <Paper elevation={1}  className={"visitor_feedback_paper"}>

      {this.state.audioPaths.length > 0 &&
            <div className={"recList"}>
               {this.state.audioPaths.map(data => {
                 let icon = <SoundIcon />;
                 return (
                   <Chip
                     key={data.file}
                     icon={icon}
                     label={this.state.isPlaying && data.file === this.state.currentAudioFile ? this.state.playerPosition : (data.duration/1000).toFixed(2) + "s"}
                     onDelete={this.state.isUserEditor && !this.viewOnly ? this.handleDeleteRecording(data) : null}
                     onClick={this.handlePlayRecording(data)}
                     color={(this.state.isPlaying || (this.state.alertType === 'deleteRecording' && this.state.alertOpen)) && data.file === this.state.currentAudioFile ? 'primary' : 'secondary'}
                     className={"chip"}
                   />
                 );
               })}
            </div>
          }


          {this.state.photoPaths.length > 0 &&
            <>
            <hr/>
            <div className={"recList"}>
              {this.state.photoPaths.map(tile => (
                <div key={tile.file} className={"ibcontainer"}>
                  <img src={tile.img} alt={tile.title} onClick={this.handleZoomPhoto(tile)}/>
                </div>
            ))}
          </div>
          </>
         }
         {this.state.notesContractor && this.state.notesContractor != "" &&
         <>
<hr/>
<Typography color="primary" component="span" style={{whiteSpace: 'pre-line', padding: '8px'}}>
  {this.state.notesContractor}
  </Typography>
</>
}







</Paper>






</CardContent>
</Card>




<br/>






<form onSubmit={this.handleSubmit}>


<FormGroup controlId="currentStatus">
<ControlLabel>Status</ControlLabel>
  <FormControl
    componentClass="select"
    placeholder="currentStatus"
    onChange={this.handleChange}
    value={this.state.currentStatus}
    >
      {
        this.state.statuses.map((status, index) => {
          return (<option key={index} value={status.value}>{status.title}</option>)
        })
      }

  </FormControl>
</FormGroup>


         <FormGroup controlId="notesAdmin">
         <ControlLabel>Admin Notes</ControlLabel>
           <FormControl
             onChange={this.handleChange}
             value={this.state.notesAdmin ? this.state.notesAdmin : ''}
             componentClass="textarea"
           />
         </FormGroup>



         {this.state.isEditing ?
           <div>
                     <LoaderButton

           bsStyle="primary"
           bsSize="large"
           disabled={!this.validateChangesOnly()}
           type="submit"
           isLoading={this.state.isSaving}
           text="Save"
           loadingText="Saving…"
         />

         <LoaderButton


           bsSize="large"
           disabled={!this.validateChangesOnly()}
           isLoading={false}
           text="Cancel"
           onClick={this.handleReset}
           loadingText="Cancelling…"
         />
         </div>
         :
         <LoaderButton
           bsStyle="primary"
           bsSize="large"
           disabled={!this.validateForm()}
           type="submit"
           isLoading={this.state.isSaving}
           text="Create"
           loadingText="Creating new job…"
         />}



         <Sound
            url={this.state.audioPath ? this.state.audioPath : ''}
            playStatus={this.state.audioPath && this.state.isPlaying ? Sound.status.PLAYING : Sound.status.STOPPED}
            onLoading={this.handleLoading}
            onPlaying={this.handlePlaying}
            onFinishedPlaying={this.handleFinishedPlaying}
            />



       </form>
     </div>
  )
}


  renderLoader() {
    const label = 'loading...';
    return(

      <div id="splash" style={{paddingTop: 250, zIndex:999}}>

        <center id="splash">
          <img src={loaderIcon}/>
          <Typography style={{padding: '4px'}} variant="caption">{label}</Typography>
        </center>
      </div>



    )
  }

render() {
  console.log(this.state.isUserEditor + " " + this.viewOnly);
  const options = {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
hour: 'numeric',
minute: 'numeric',
second: 'numeric'
}

    return (
      <div>
         <Breadcrumb style={{top: 58, left: 66, right: 0, position: 'fixed', zIndex:999, backgroundColor: '#FFF'}}>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/jobs">Jobs</Breadcrumb.Item>
          <Breadcrumb.Item active>{this.state.isLoading ? '' : this.state.job && this.state.job.code ? 'Edit: ' + this.state.job.code : 'Create new'}</Breadcrumb.Item>
        </Breadcrumb>

        {this.state.isLoading ? this.renderLoader() : this.state.isUserEditor && !this.viewOnly ? this.renderFields() : this.renderMinFields()}







        <Dialog
          open={this.state.isLightBoxOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleLightBoxClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {this.state.title + ' - ' + this.state.locus}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
            {this.state.currentPhoto && new Date(this.state.currentPhoto.timestamp).toLocaleString()}
             {this.state.currentPhoto && this.state.currentPhoto.title && <br/>}
            {this.state.currentPhoto && this.state.currentPhoto.title ? this.state.currentPhoto.title : ''}
            </DialogContentText>
            {this.state.currentPhoto && <img src={this.state.currentPhoto.img} style={{maxWidth: 500, maxHeight:500}}/>}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleLightBoxClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>



        <Dialog
          open={this.state.isSelectorDialogOpen}
          TransitionComponent={Transition}
          fullWidth={this.state.alertType === 'keys'}
          maxWidth = {'md'}


          keepMounted
          onClose={this.handleSelectorClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {this.state.alertTitle}
          </DialogTitle>
          <DialogContent >
            <DialogContentText id="alert-dialog-slide-description">
               {this.state.alertMessage}
            </DialogContentText>



<div >
<br/>

{this.state.isSelectorDialogOpen && this.state.alertType === 'keys' && this.state.keyList.length > 0 &&
<>
                <Dropdown
                   placeholder='Select Key'
                   onChange={this.handleKeyDropDownChange}
                   fluid
                   search
                   deburr
                   selection
                   options={this.state.keyList}
                   value={this.state.currkeyid}
                 />
                 <br/>
                 <br/>
                 <br/>
                 <br/>
                 <br/>
                 <br/>
                 <br/>
                 <br/>
                 <br/>
                 <br/>
                 <br/>
                 </>

}
<List selection divided verticalAlign='middle'>

{this.state.isSelectorDialogOpen && this.state.alertType === 'obs' &&
            this.state.obsList.map(data => {

              return (
                <List.Item key={data.key} name={data.index} onClick={this.handleListClick} active={false}>
                            {data.selected ? <Icon color='green' name='check'/> : <Icon inverted color='grey' name='minus'/>}
                          <List.Content verticalAlign='middle'>
                    <List.Header className={'listheader'}>{data.locus + " - " + data.categoryDesc}</List.Header>
                    <List.Description>
                      {data.details}
                    </List.Description>
                  </List.Content>
                </List.Item>

              );
            })
}


            </List>
</div>



          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSelectorDialogClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.handleSelectorDialogSelect} color='primary'>
              OK
            </Button>
          </DialogActions>
        </Dialog>

      <Dialog
          open={this.state.alertOpen}
          onClose={this.handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.state.alertTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.alertMessage}
            </DialogContentText>
          </DialogContent>


          {this.state.alertType === 'deleteRecording' ?
           <DialogActions>
           <Button onClick={this.handleAlertClose} color='secondary' autoFocus>
             Cancel
           </Button>
           <Button onClick={this.handleAlertDeleteRecording} color='primary'>
             Delete
           </Button>
           </DialogActions>

           : this.state.alertType === 'deletePhoto' ?
           <DialogActions>
           <Button onClick={this.handleAlertClose} color='secondary' autoFocus>
             Cancel
           </Button>
           <Button onClick={this.handleAlertDeletePhoto} color='primary'>
             Delete
           </Button>
           </DialogActions>

           : this.state.alertType === 'deleteKey' || this.state.alertType === 'deleteObs' ?
           <DialogActions>
           <Button onClick={this.handleAlertClose} color='secondary' autoFocus>
             Cancel
           </Button>
           <Button onClick={this.handleAlertDeleteObject} color='primary'>
             Remove
           </Button>
           </DialogActions>

           :<DialogActions><Button onClick={this.handleAlertClose} color='primary'>
             Ok
           </Button></DialogActions>}


        </Dialog>

      </div>
    );
  }
}
