import React, { Component } from "react";
import {  Auth, API, Storage } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel, Breadcrumb, Col} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./Account.css";

export default class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: null,
      isDeleting: null,
      email: "",
      newEmail: "",
      firstName: "",
      newFirstName: "",
      lastName: "",
      newLastName: "",
      isChangePWing: false,
      code: "",
      password: "",
      password2: "",
      changePWData: null,
      isUnverified: false,
      currentAuthUser: null
    };
  }


  async componentDidMount() {
  if (!this.props.isAuthenticated) {
    return;
  }
    try {
      const user = this.props.user;
      console.log("111111");
      console.log(user);
      console.log("333333");
      const { email, firstName, lastName } = user;
      console.log(email, firstName, lastName);

console.log(Auth.credentials);

      this.setState({
        user,
        email,
        newEmail: email,
        firstName,
        newFirstName: firstName,
        lastName,
        newLastName: lastName
      });
      console.log('bbb');

    } catch (e) {
      alert(e);
    }
  }
validateForm() {
  console.log(this.state);
  return this.state.newEmail.length > 0 && this.state.newFirstName.length > 0 && this.state.newLastName.length > 0 && (this.state.newLastName !== this.state.lastName || this.state.newFirstName !== this.state.firstName || this.state.newEmail !== this.state.email);
}
validateChangesOnly() {
  console.log(this.state);
  return this.state.newLastName !== this.state.lastName || this.state.newFirstName !== this.state.firstName || this.state.newEmail !== this.state.email;
}

validateChangePWForm() {
        return this.state.code.length > 0 && this.state.password.length > 0 && this.state.password2.length > 0 && this.state.password == this.state.password2;
  }

validateValidationCode() {
  return this.state.code.length > 0;
}


formatFilename(str) {
  return str.replace(/^\w+-/, "");
}

handleChange = event => {
  this.setState({
    [event.target.id]: event.target.value
  });
}


saveUser(user) {
  return API.put("qkeyz", `/users`, {
    body: user
  });
}
  getUser() {
    return API.get("qkeyz", `/users`);

  }


 handleChangePassword = async event => {
    event.preventDefault();
    this.setState({ isChangePWing: true });
    Auth.forgotPassword(this.state.email)
      .then(data =>
        this.changePasswordSuccess(data))
      .catch(err =>
        this.changePasswordError(err));
  }
  changePasswordError(err) {
    this.setState({ isChangePWing: false });
    alert(err.message);
  }
  changePasswordSuccess(changePWData) {
    this.setState({ isChangePWing: false });
    console.log(changePWData);
    this.setState({
      changePWData
    });
  }
handleCancelPasswordReset= async event => {
  event.preventDefault();
    this.setState({
      changePWData: null
    });
}
handleChangePWSubmit = async event => {
  event.preventDefault();

  this.setState({ isLoading: true });
  Auth.forgotPasswordSubmit(this.state.email, this.state.code, this.state.password)
    .then(data => this.forgotPasswordSubmitSuccess(data))
    .catch(err => this.forgotPasswordSubmitError(err));
}
  forgotPasswordSubmitError(err) {
    this.setState({ isLoading: false });
    alert(err.message);
  }
  forgotPasswordSubmitSuccess(data) {
    this.setState({ isLoading: false });
    console.log(data);
    alert("New password reset successfully!");
    this.setState({
      changePWData: null,
      password: "",
      password2: "",
      code: ""
    });
    //this.props.history.push("/forgot");
  }

handleVerifyEmail = async event => {

  event.preventDefault();

  this.setState({ isLoading: true });
  Auth.verifyUserAttributeSubmit(this.state.currentAuthUser, 'email', this.state.code)
    .then(data => this.validationSubmitSuccess(data))
    .catch(err => this.validationSubmitError(err));
  }
  validationSubmitError(err) {
    alert(err.message);
    this.setState({
      isLoading: false,
      isUnverified: false,
      code: ""
    });
  }
  validationSubmitSuccess(data) {
    this.setState({
      isLoading: false,
      isUnverified: false,
      code: ""
    });
    alert("Successfully changed account details.")
  }

handleSubmit = async event => {

  event.preventDefault();

  this.setState({ isLoading: true });

  try {
    var detailsChangeResult = 'SUCCESS';
    let currentUser = await Auth.currentAuthenticatedUser();
    if (this.state.newEmail !== this.state.email) {
      detailsChangeResult = await Auth.updateUserAttributes(currentUser, {
          'email': this.state.newEmail,
          'custom:firstname': this.state.newFirstName,
          'custom:lastname': this.state.newLastName
      });
    } else {
      detailsChangeResult = await Auth.updateUserAttributes(currentUser, {
          'custom:firstname': this.state.newFirstName,
          'custom:lastname': this.state.newLastName
      });

    }
    console.log(detailsChangeResult);
    if (detailsChangeResult !== 'SUCCESS') {
      alert("Unable to save changes at the moment, please try again later.")
    } else {
      await this.saveUser({
        oldEmail: this.state.email,
        email: this.state.newEmail,
        firstName: this.state.newFirstName,
        lastName: this.state.newLastName,
        category: this.state.user.category,
        categoryDesc: this.state.user.categoryDesc
      });
      const result = await this.getUser();
      this.props.setCurrentUser(result.item);
       if (this.state.email === this.state.newEmail) {
        alert("Successfully changed account details.")
      }
     this.setState({
        isLoading: false,
        email: this.state.newEmail,
        firstName: this.state.newFirstName,
        lastName: this.state.newLastName,
        isUnverified: (this.state.email !== this.state.newEmail),
        currentAuthUser: (this.state.email !== this.state.newEmail ? currentUser : null)
      });



      //
      //let usr = await Auth.currentAuthenticatedUser();
      const cui = await Auth.currentUserInfo();
      console.log(cui);
    }
  } catch (e) {
    this.setState({ isLoading: false });
    alert(e.message);
  }
}

handleOrgsClick = event => {
  event.preventDefault();
  this.props.history.push("/organisations");
}
handleCrumbClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}
handleReset = event => {
  event.preventDefault();
  this.setState({
    newEmail: this.state.email,
    newFirstName: this.state.firstName,
    newLastName: this.state.lastName
  });


}


renderForm() {
  return (

       this.state.user &&
        <form onSubmit={this.handleSubmit}>

          <FormGroup controlId="email">

          <ControlLabel>Email Address</ControlLabel>
             <FormControl
              value={this.state.email}
              disabled={true}
              componentClass="input"
              type="email"
            />
            </FormGroup>

             <FormGroup controlId="newEmail">
         <ControlLabel>New Email Address</ControlLabel>
             <FormControl
              onChange={this.handleChange}
              value={this.state.newEmail}
              componentClass="input"
              type="email"
            />
          </FormGroup>



          <FormGroup controlId="newFirstName">

         <ControlLabel>First Name</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.newFirstName}
              componentClass="input"
              type="text"
            />

          </FormGroup>
          <FormGroup controlId="newLastName">

         <ControlLabel>Last Name</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.newLastName}
              componentClass="input"
              type="text"
            />

          </FormGroup>

          <LoaderButton

            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Save"
            loadingText="Saving…"
          />
           &nbsp;
          <LoaderButton


            bsSize="large"
            disabled={!this.validateChangesOnly()}
            isLoading={false}
            text="Cancel"
            onClick={this.handleReset}
            loadingText="Cancelling…"
          />


        {this.state.user.category === 'SUPER' &&
          <button
            className="btn-builder"
            onClick={this.handleOrgsClick}>
            Manage Organisations
          </button>
        }
        <br/><br/>
          <LoaderButton
            bsStyle="danger"
            block
            bsSize="small"
            disabled={false}
            onClick={this.handleChangePassword}
            isLoading={this.state.isChangePWing}
            text="Change password"
            loadingText="Loading…"
          />



        </form>



  );
}

  renderChangePWForm() {
    return (
      //<div className="ForgotPassword">

        <form onSubmit={this.handleChangePWSubmit}>
              <h1>Change Password</h1>
        <p>An email verification code has been sent to your email address. Please enter it below.</p>
          <FormGroup controlId="code" bsSize="large">
            <ControlLabel>Verification code</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.code}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>New password</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <FormGroup controlId="password2" bsSize="large">
            <ControlLabel>Confirm new password</ControlLabel>
            <FormControl
              value={this.state.password2}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
            <LoaderButton
              bsStyle="primary"
              bsSize="large"
              disabled={!this.validateChangePWForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Submit new password"
              loadingText="Submitting password…"
            />
                       &nbsp;
          <LoaderButton


            bsSize="large"
            disabled={false}
            isLoading={false}
            text="Cancel"
            onClick={this.handleCancelPasswordReset}
            loadingText="Cancelling…"
          />

        </form>
      //</div>
    );
  }

  renderUnverifiedForm() {
    return (

        <form onSubmit={this.handleVerifyEmail}>
          <h1>Verify New Email Address</h1>
          <p>An email verification code has been sent to your email address. Please enter it below.</p>
          <FormGroup controlId="code" bsSize="large">
            <ControlLabel>Verification code</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.code}
              onChange={this.handleChange}
            />
          </FormGroup>

            <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              disabled={!this.validateValidationCode()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Verify Email"
              loadingText="Verifying…"
            />

        </form>
          );
  }


  render() {
    return (
      <div className="Account">
      <Breadcrumb style={{top: 58, left: 66, right: 0, position: 'fixed', zIndex:999, backgroundColor: '#FFF'}}>
            <Breadcrumb.Item onClick={this.handleCrumbClick} href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>
              Account
            </Breadcrumb.Item>

          </Breadcrumb>
          <div className="Account" style={{position: 'absolute', left:80, right:80,top: 110}}>



        {this.state.changePWData === null
          ? (this.state.isUnverified ? this.renderUnverifiedForm() : this.renderForm())
          : this.renderChangePWForm()}
      </div></div>
    );
  }



}
