import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { Glyphicon, InputGroup, FormGroup, FormControl, ControlLabel, ListGroup, ListGroupItem, Breadcrumb } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./Organisation.css";
import { s3Upload } from "../libs/awsLib";
import Paper from '@material-ui/core/Paper';



export default class Organisation extends Component {
  constructor(props) {
    super(props);

    this.file = null;
    this.state = {
      isLoading: null,
      isDeleting: null,
      org: null,
      orgUsers: [],
      name: "",
      contactPhone: "",
      logo: null,
      category: "",
      category_short: "",
      categories: [{value: 'UNI', title: 'University'},
        {value: 'COM', title: 'Commercial'},
        {value: 'NGO', title: 'Non-Government Organisation'},
        {value: 'CHA', title: 'Charity'},
        {value: 'GOV', title: 'Government'}
      ],
      roles: [{value: 'VIEW_ANY', title: 'View Any'},
              {value: 'CREATE', title: 'Create'},
              {value: 'EDIT_ANY', title: 'Edit Any'},
              {value: 'ADMIN', title: 'Admin'}
            ]

    };
  }

  async componentDidMount() {
  if (!this.props.isAuthenticated) {
    return;
  }
    const user = this.props.user;
    const { category } = user;
    if (category != 'SUPER') {
      this.props.history.push("/account");
      return;
    }

    try {
      let attachmentURL;
      // NOTE I SHould not have to getorg for a STANDARD USER (ADMIN) because it will be passed in the props
      const org = await this.getOrg();
      const {binaryImage, orgName, contactPhone, category, categoryDesc } = org;
      const result = await this.getOrgUsers();
      const orgUsers = result.Items;

/*
      if (logo) {
        attachmentURL = await Storage.get(logo);
      }
*/

      //orgUsers.map(this.newRoles = this.roles.slice(0));
      console.log("aaa");



      orgUsers.map(this.cloneRoles);




      console.log("bbb");



      this.setState({
        org,
        orgUsers,
        name: orgName,
        contactPhone: contactPhone,
        logo: binaryImage,
        category_short: category,
        category: categoryDesc
      });
    } catch (e) {
      alert(e);
    }
  }
  cloneRoles = usr => (
    usr.userRoles.sort(),
    usr['newRoles'] = usr.userRoles.slice(0),
    usr['rolesChanged'] = false
  )

  getOrg() {
    return API.get("qkeyz", `/organisations/${this.props.match.params.id}`);
  }
  getOrgUsers() {
    //return API.get("qkeyz", `/organisations/${this.props.match.params.id}/users`);
    return API.get("qkeyz", `/organisations/${this.props.match.params.id}/EMP`);
  }

validateForm() {
  return this.state.name.length > 0;
}

formatFilename(str) {
  return str.replace(/^\w+-/, "");
}

handleChange = event => {
  this.setState({
    [event.target.id]: event.target.value
  });
}

/*
handleFileChange = event => {
  this.file = event.target.files[0];
}
*/

handleFileChange = async event => {
  event.preventDefault();
  this.file = event.target.files[0];
  if (this.file && this.file.type.indexOf("image") == -1) {
    this.file = null;
    this.inputEl.value='';
    this.setState({
      alertOpen: true,
      alertTitle: 'Invalid Image Format',
      alertMessage: 'Please choose a valid image for the property logo.'
    });
  } else {
    if (this.file) {
      const base64 = await this.getReducedImageAsBase64(this);
      this.setState({ logo: base64});
    }
  }
}


saveOrg(org) {
  org.userName = this.props.user.firstName + " " + this.props.user.lastName;
  return API.put("qkeyz", `/organisations/${this.props.match.params.id}`, {
    body: org
  });
}
updateOrgUser(usr) {
  usr.userName = this.props.user.firstName + " " + this.props.user.lastName;
  return API.put("qkeyz", `/organisations/${this.props.match.params.id}/users`, {
    body: usr
  });
}
deleteOrgUser(usr) {
  const body = {ids: [usr]};
  return API.del("qkeyz", `/organisations/${this.props.match.params.id}/EMP`, {
    body: body
  });
}
handleSubmit = async event => {
  let attachment;

  event.preventDefault();

  if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
    alert("Please pick a file smaller than 5MB");
    return;
  }

  this.setState({ isLoading: true });

  try {
    if (this.file) {
      attachment = await s3Upload(this.file);
    }

    await this.saveOrg({
      orgName: this.state.name,
      contactPhone: this.state.contactPhone,
      binaryImage: this.state.logo ? this.state.logo : null,
      category: this.state.category_short,
      categoryDesc: this.state.category
    });
    this.props.history.push("/organisations");
  } catch (e) {
    alert(e);
    this.setState({ isLoading: false });
  }
}

deleteNote() {
  return API.del("qkeyz", `/notes/${this.props.match.params.id}`);
}
getReducedImageAsBase64(context) {
  var reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(null);
    };
    reader.onload = () => {
      var img = new Image();
      img.onload = function() {
          console.log('IMG: ', img.height, img.width);
          let h = 200;
          let w = 200;
          if (img.height > img.width) {
            w = w * (img.width / img.height);
          } else {
            h = h * (img.height / img.width);
          }
          var oc = document.createElement('canvas');
          oc.width = w;
          oc.height = h;
          var ctx = oc.getContext('2d');
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, w, h);
          ctx.drawImage(img, 0, 0, w, h);
          var dataurl = oc.toDataURL('image/jpeg'); //context.file.type
          resolve(dataurl);
        }
      img.src = reader.result
      };
    reader.readAsDataURL(context.file);
  });
}
handleDelete = async event => {
  event.preventDefault();

  const confirmed = window.confirm(
    "Are you sure you want to delete this note?"
  );

  if (!confirmed) {
    return;
  }

  this.setState({ isDeleting: true });

  try {
    await this.deleteNote();
    this.props.history.push("/");
  } catch (e) {
    alert(e);
    this.setState({ isDeleting: false });
  }
}
/*
handleBuilderClick = event => {
  event.preventDefault();
  sessionStorage.setItem('schema_id', this.state.note.noteId);
  this.props.history.push("/builder");
}
*/
handleOrgUserClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}
handleSaveOrgUserClick = async event => {
  event.preventDefault();
  let ou = this.state.orgUsers[event.currentTarget.getAttribute("id")];

  this.setState({ isLoading: true });

  try {
    await this.updateOrgUser({empId: ou.sk,roles: ou.newRoles});
    this.setState({ isLoading: false });
    ou.userRoles = ou.newRoles.slice(0);
    ou.rolesChanged = false;
    //this.state.orgUsers[event.currentTarget.getAttribute("id")] = ou;
    this.setState(({ ou }) => (
      {
        orgUsers: this.state.orgUsers
      }
    ));
  } catch (e) {
    alert(e);
    this.setState({ isLoading: false });
  }




}
handleCancelOrgUserClick = event => {
  event.preventDefault();
  let ou = this.state.orgUsers[event.currentTarget.getAttribute("id")];
  ou.newRoles = ou.userRoles.slice(0);
  ou.rolesChanged = false;
  this.state.orgUsers[event.currentTarget.getAttribute("id")] = ou;
  this.setState(({ ou }) => (
    {
      orgUsers: this.state.orgUsers
    }
  ));

}
handleDeleteOrgUserClick = async event => {
  event.preventDefault();
  let idx = event.currentTarget.getAttribute("id");
  let ou = this.state.orgUsers[idx];
  const confirmed = window.confirm(
    "Are you sure you want to delete this user?"
  );

  if (!confirmed) {
    return;
  }

  this.setState({ isLoading: true });

  try {
    //await this.deleteOrgUser({empId: ou.sk});
    await this.deleteOrgUser(ou.sk);
    this.setState({ isLoading: false });
    this.state.orgUsers.splice(idx, 1);
    this.setState(({ ou }) => (
      {
        orgUsers: this.state.orgUsers
      }
    ));
  } catch (e) {
    alert(e);
    this.setState({ isLoading: false });
  }
}
toggleCheckboxChange = event => {
  var ou = this.state.orgUsers[event.currentTarget.getAttribute("id")];
  let cat = event.currentTarget.getAttribute("value");
  let idxNew = ou.newRoles.indexOf(cat);
  let idx = ou.userRoles.indexOf(cat);
  //console.log(idx);
  //console.log(idxNew);
  //console.log(ou.newRoles);
  if (idxNew > -1) {
    ou.newRoles.splice(idxNew, 1);
  } else {
    ou.newRoles.push(cat);
  }
  ou.newRoles.sort();
  //console.log(ou.userRoles);
  //console.log(ou.newRoles);

  ou.rolesChanged = false;

   if (ou.userRoles === ou.newRoles) {
      ou.rolesChanged = false;
   } else if (ou.userRoles == null || ou.newRoles == null) {
      ou.rolesChanged = true;
   } else if (ou.userRoles.length != ou.newRoles.length) {
      ou.rolesChanged = true;
   } else {
    for (var i = 0; i < ou.userRoles.length; ++i) {
      if (ou.userRoles[i] !== ou.newRoles[i]) {
        ou.rolesChanged = true;
      };
    }
}
  //console.log(ou.rolesChanged);


  this.state.orgUsers[event.currentTarget.getAttribute("id")] = ou;
    this.setState(({ ou }) => (
      {
        orgUsers: this.state.orgUsers
      }
    ));
  }
handleCrumbClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}

renderOrgUsersList(orgUsers) {
  return [{}].concat(orgUsers).map(
    (orgUser, i) =>
      i !== 0
        ? <ListGroupItem
            key={orgUser.sk}
            header={orgUser.firstName + ' ' + orgUser.lastName + ' (' + orgUser.email + ')'}
          >

          <label>
            <input
              type="checkbox"
              value={this.state.roles[0].value}
              id={i - 1}
              checked={orgUser.newRoles.indexOf(this.state.roles[0].value) > -1}
              onChange={this.toggleCheckboxChange}
            />
            &nbsp;{this.state.roles[0].title}
          </label>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <label>
            <input
              type="checkbox"
              value={this.state.roles[1].value}
              id={i - 1}
              checked={orgUser.newRoles.indexOf(this.state.roles[1].value) > -1}
              onChange={this.toggleCheckboxChange}
            />
            &nbsp;{this.state.roles[1].title}
          </label>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <label>
            <input
              type="checkbox"
              value={this.state.roles[2].value}
              id={i - 1}
              checked={orgUser.newRoles.indexOf(this.state.roles[2].value) > -1}
              onChange={this.toggleCheckboxChange}
            />
            &nbsp;{this.state.roles[2].title}
          </label>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <label>
            <input
              type="checkbox"
              value={this.state.roles[3].value}
              id={i - 1}
              checked={orgUser.newRoles.indexOf(this.state.roles[3].value) > -1}
              onChange={this.toggleCheckboxChange}
            />
            &nbsp;{this.state.roles[3].title}
          </label>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          <LoaderButton
          id={i - 1}
            bsStyle="primary"
            bsSize="small"
            disabled={!orgUser.rolesChanged}
             onClick={this.handleSaveOrgUserClick}

            isLoading={this.state.isLoading}
            text="Save"
            loadingText="Saving…"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <LoaderButton
          id={i - 1}
            bsStyle="primary"
            bsSize="small"
            disabled={!orgUser.rolesChanged}
             onClick={this.handleCancelOrgUserClick}

            isLoading={this.state.isLoading}
            text="Cancel"
            loadingText="Cancelling…"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <LoaderButton
          id={i - 1}
            disabled={false}
            bsStyle="danger"
            bsSize="small"
            isLoading={this.state.isDeleting}
            onClick={this.handleDeleteOrgUserClick}
            text="Delete"
            loadingText="Deleting…"
          />

         <br/>
            {"Created: " + new Date(orgUser.created).toLocaleString()}


          </ListGroupItem>
        : <ListGroupItem
            key="new"
            href={`/organisations/${this.props.match.params.id}/users/add`}
            onClick={this.handleOrgUserClick}
          >
            <h4>
              <b>{"\uFF0B"}</b> Add User
            </h4>
          </ListGroupItem>
  );
}
render() {
  return (
    <div className="Organisation">
         <Breadcrumb>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/account">Account</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/organisations">Organisations</Breadcrumb.Item>
          <Breadcrumb.Item active>{this.state.name}</Breadcrumb.Item>
        </Breadcrumb>
     {this.state.org &&
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="name">
          <ControlLabel>Organisation Name</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.name}
              componentClass="input"
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="category_short">
          <ControlLabel>Category</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="category"
              onChange={this.handleChange}
              value={this.state.category_short}
              >
                {
                  this.state.categories.map((cat, index) => {
                    return (<option key={index} value={cat.value}>{cat.title}</option>)
                  })
                }

            </FormControl>
            </FormGroup>

            <FormGroup controlId="contactPhone">
            <ControlLabel>Contact Phone Number</ControlLabel>
              <FormControl
                onChange={this.handleChange}
                value={this.state.contactPhone}
                componentClass="input"
                type="text"
              />
            </FormGroup>


            <FormGroup>
              <ControlLabel>Organisation Logo</ControlLabel>
            </FormGroup>
            <Paper className={"recList"}>
            <FormGroup>
              {this.state.logo &&
              <FormControl.Static >
                <img  src={this.state.logo} style={{maxWidth: '400px'}} height='80px' alt="Visitor photo"/>
              </FormControl.Static>
              }
            </FormGroup>

                     <FormGroup controlId="file">
                     <InputGroup>
                     <label htmlFor='file_input' className='label_as_button' ><Glyphicon glyph="folder-open" /> &nbsp;Choose logo</label>
                       <FormControl id='file_input' inputRef={ el => this.inputEl=el } accept="image/*" onChange={this.handleFileChange} type="file" style={{display: 'none'}}/>
                         </InputGroup>







                         </FormGroup>



            </Paper>






          <hr/>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Save"
            loadingText="Saving…"
          />
          <LoaderButton
            block
            disabled={true}
            bsStyle="danger"
            bsSize="large"
            isLoading={this.state.isDeleting}
            onClick={this.handleDelete}
            text="Delete"
            loadingText="Deleting…"
          />
<p>&nbsp;</p><p>&nbsp;</p>
<div className="users">
                  <FormGroup>

        <ControlLabel>Users</ControlLabel>
          <ListGroup>
            {!this.state.isLoading && this.renderOrgUsersList(this.state.orgUsers)}
          </ListGroup>
        </FormGroup>
</div>
        </form>


      }
    </div>
  );
}
}
