import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel, ListGroup, ListGroupItem, Breadcrumb } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import PropertyCard from "./PropertyCard";
import config from "../config";
import "./PropertyLog.css";
import { s3Upload } from "../libs/awsLib";
import Typography from '@material-ui/core/Typography';
import loaderIcon from'../images/qkeyz.gif';
import EnhancedTable from "./EnhancedTable";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import green from '@material-ui/core/colors/green';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {Dimmer, Loader} from 'semantic-ui-react';


import jsPDF from 'jspdf';
import 'jspdf-autotable';

import QkeyzLogo from "../images/qkeyz_logo.svg";


const rowHeaders = [
  { id: 'event', numeric: false, center: false, disablePadding: false, label: 'Event' },
  { id: 'desc', numeric: false, center: false, disablePadding: false, label: 'Details' },
  { id: 'user', numeric: false, center: false, disablePadding: false, label: 'Created By' },
  { id: 'created', numeric: false, center: false, disablePadding: false, label: 'Timestamp' }
];

const rowLimit = 30;

export default class PropertyLog extends Component {
  constructor(props) {
    super(props);

    this.file = null;
    this.state = {
      mode: "normal",
      alertOpen: false,
      alertType: '',
      isLoading: true,
      isMoreLoading: false,
      isDeleting: false,
      isSaving: false,
      property: null,
      title: "",
      logo: "",
      attachmentURL: null,
      description: "",
      isDeleting: false,
      multiline: 'Controlled',
      esk: null
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated || !this.props.org) {
      this.props.history.push("/");
      return;
    }

    try {
      let attachmentURL;
      const property = await this.getProperty();
      const {binaryImage, title, photo, description } = property;


      const result = await this.getLog(this.getPropertyIdFromSk(property.sk), '?limit=' + rowLimit.toString() + '&asc=false');
      const log = result.Items;
      console.log("LOG");
      console.log(result);




      const rows = await this.getStructuredLogList(log);
      this.setState({
        esk: result.LastEvaluatedKey,
        isLoading: false,
        property,
        title: title,
        logo: binaryImage,
        attachmentURL,
        description: description,
        rows: rows
      });
    } catch (e) {
      alert(e);
    }
  }
  async getStructuredLogList(log) {
    var rows = [];
    for (var i = 0; i < log.length; ++i) {
      let l = log[i];
      const {keyId, endDate, category, categoryDesc, firstName, lastName, orgName, description, locus, created, createdByName} = l;
      const isSignout = (category == "KEY_SIGNOUT");
      let userDetails = "";
      userDetails = firstName + " " + lastName + (orgName ? " - " + orgName : "");
      const desc = description + " - " + locus + (lastName && isSignout ? "\nTo: " + userDetails : '') + (endDate ? "\nDue back: " + (new Date(endDate)).toLocaleString() : '');
      let rowCols = [
        { id: 'event', numeric: false, center: false, disablePadding: false, value: categoryDesc, type: 'text'},
        { id: 'desc', numeric: false, center: false, disablePadding: false, value: desc, type: 'paragraph' },
        { id: 'user', numeric: false, center: false, disablePadding: false, value: createdByName, type: 'text' },
        { id: 'created', numeric: false, center: false, disablePadding: false, value: (new Date(created)).toLocaleString(), type: 'date' }
      ];
      rows.push({id: i + 1, event: categoryDesc, desc: desc, user: userDetails, created, key: l.sk, cols: rowCols})
    }
    return rows
  }
  getProperty() {
    const propertyId = this.props.location.state && this.props.location.state.propertyId;
    return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|NIL/${propertyId}`);
  }
  getLog(propertyId, queryParams) {
    //return API.get("qkeyz", `/properties/${propertyId}/EVT${queryParams}`);
    return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|EVT|NIL|${propertyId}${queryParams}`);
  }
  async getMoreLog() {
    if (this.state.esk) {
      this.setState({
        isMoreLoading: true
      });
      const queryParams = '?limit=' + rowLimit.toString() + '&asc=false' + '&esk=' + JSON.stringify(this.state.esk);
      const result = await this.getLog(this.getPropertyIdFromSk(this.state.property.sk), queryParams);
      const newRows = await this.getStructuredLogList(result.Items);
      const rows = this.state.rows.concat(newRows);
      this.setState({
        esk: result.LastEvaluatedKey,
        rows: rows,
        isMoreLoading: false
      });
    }
  }




  getIdFromSk(sk) {
    return sk.substr(4);
  }

  getPropertyIdFromSk(sk) {
    return sk.substr(8);
  }

handleMapLinkClick = event => {
  if (this.state.property.mapLink && this.state.property.mapLink !== "") {
    window.open(this.state.property.mapLink, "_blank");
  }

}

getData(selected) {
  let data = [];
  console.log(this.state.rows);
  if (this.state.rows) {
    selected.map(key => {
      let o = this.state.rows.find(o => o.key === key);
      data.push([o.event, o.desc, o.user, (new Date(o.created)).toLocaleString()]);
    });
  }
  return data;
}

handlePDFClick = async (event, selected) => {
  event.preventDefault();
  const qkeyzLogoBase64 = this.getImageAsBase64(QkeyzLogo);
  var doc = new jsPDF();
  var totalPagesExp = "{total_pages_count_string}";
  const reportType = "LOG"
  const orgIcon = this.props.org.binaryImage;
  const orgName = this.props.org.orgName;
  const propIcon = this.state.property.binaryImage;
  const propTitle = this.state.property.title + " - " + reportType;
  let datestr = (new Date(Date.now())).toLocaleString();
  console.log(datestr);
  datestr = datestr.replace(/[, ]/g, '_');
  console.log(datestr);
  let fileName = "QKEYZ_" + this.state.property.title.replace(/\s/g, '_') + "_" + reportType + "_" + datestr + ".pdf";
  console.log(fileName);
  doc.setProperties({
  	title: propTitle,
  	subject: '',
  	author: this.props.user.firstName + " " + this.props.user.lastName,
  	keywords: '',
  	creator: this.props.user.firstName + " " + this.props.user.lastName
  });


  /*
  doc.autoTableSetDefaults({
    didDrawCell: (data) => {
      //doc.addImage(orgIcon, 'JPEG', ix, pageHeight - 10 - 4.5, iw, ih);
      doc.setDrawColor(255,0,0);
      doc.setLineWidth(2);
      console.log('cell');
      console.log(data);
      doc.lines(
        [[data.cell.width, 0]],
        data.cell.x,
        data.cell.y
      );
    }
  });
  */

  doc.autoTable({
      head: [
          ['Event', 'Details', 'User', 'Created'],
      ],
      body: this.getData(selected),
      headStyles: {
        fillColor: [198, 0, 0]
      },
      didDrawPage: function (data) {
          // Header
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

          doc.setFontSize(10);
          doc.setTextColor(40);
          doc.setFontStyle('normal');
          let tw = doc.getTextWidth(orgName);
          let tx = 105;
          if (orgIcon) {
            let pr = doc.getImageProperties(orgIcon);
            let r = pr.width / pr.height;
            let ih = 6;
            let iw = 6 * r;
            let ix = 105 - ((iw + tw + 1) / 2);
            tx = tx - ((iw + tw + 1) / 2) + (iw + 1);
            doc.addImage(orgIcon, 'JPEG', ix, pageHeight - 10 - 4.5, iw, ih);
          } else {
            tx = tx - ((tw + 1) / 2);
          }
          doc.text(orgName, tx, pageHeight - 10);


          if (qkeyzLogoBase64) {
            let pr = doc.getImageProperties(qkeyzLogoBase64);
            let r = pr.width / pr.height;
            let ih = 6;
            let iw = 6 * r;
            doc.addImage(qkeyzLogoBase64, 'JPEG', 210 - data.settings.margin.right - iw, pageHeight - 10 - 4.5, iw, ih);
          }

          let iw = 0;
          if (propIcon) {
            let pr = doc.getImageProperties(propIcon);

            let r = pr.width / pr.height;
            let ih = 10;
            iw = 10 * r;

            doc.setFontSize(20);


            doc.addImage(propIcon, 'JPEG', data.settings.margin.left, 14.5, iw, ih);
          }
          doc.text(propTitle, data.settings.margin.left + iw + 3, 22);
          var str = "Page " + doc.internal.getNumberOfPages()
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === 'function') {
              str = str + " of " + totalPagesExp;
          }
          doc.setFontSize(10);

          doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
      margin: {top: 30}
  });
  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }
  doc.save(fileName);
}


getImageAsBase64(image) {
    let img = new Image();
    img.src = image;
    let h = img.height;
    let w = img.width;
    var oc = document.createElement('canvas');
    oc.width = w;
    oc.height = h;
    var ctx = oc.getContext('2d');
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, w, h);
    ctx.drawImage(img, 0, 0, w, h);
    var dataurl = oc.toDataURL('image/jpeg');
    console.log(dataurl);
    return dataurl;
}


handleCrumbClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}
handleAlertClose = () => {
    this.setState({ alertOpen: false, alertType: '' });
};
handleLogClick = (event, keySk) => {
  event.preventDefault();
  if (keySk == 'more') {
    console.log(keySk);
    this.getMoreLog();

  }
}


renderLoader() {
  const label = this.state.isDeleting ? 'deleting...' : this.state.isSaving ? 'saving...' : 'loading...';
  return(

    <div id="splash" style={{paddingTop: 250, zIndex:999}}>

      <center id="splash">
        <img src={loaderIcon}/>
        <Typography style={{padding: '4px'}} variant="caption">{label}</Typography>
      </center>
    </div>



  )
}
renderLog() {
  const menuItems = [
    { id: 0, label: 'Export as PDF...', lt: 1, gt: -1, isOrOperator: false, action: this.handlePDFClick, divider: false, type: 'pdf' }
  ];
    return (
        <div>
          {!this.state.isLoading && <EnhancedTable
                                        logosKey={this.state.key}
                                        title='Property Log'
                                        type='proplog'
                                        tooltip=''
                                        items={this.state.rows}
                                        menuItems={menuItems}
                                        handleItemClick={this.handleLogClick}
                                        rowHeaders={rowHeaders}
                                        orderBy='created'
                                        order='desc'
                                        more={this.state.esk}
                                        />}
          </div>
    );
  }

render() {
  //const { classes } = this.props;
  return (
  <div>
         <Breadcrumb  style={{top: 58, left: 66, right: 0, position: 'absolute', zIndex:999, backgroundColor: '#FFF'}}>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/properties">Properties</Breadcrumb.Item>
          <Breadcrumb.Item active>{this.state.title} Log</Breadcrumb.Item>
        </Breadcrumb>
    <div className="PropertyLog" style={{position: 'absolute', left:80, right:80,top: 104, paddingBottom:50}}>
      {!this.state.isLoading && this.state.property && <PropertyCard
        title={this.state.title}
        description={this.state.description}
        logo={this.state.logo}
        maplink={this.state.property.mapLink}
        contactName={this.state.property.contactName}
        contactNumber={this.state.property.contactPhone}
        onClick={this.handleMapLinkClick}
        colour={'#FAFcFA'}
        />}
        {this.state.isLoading || this.state.isDeleting || this.state.isSaving ? this.renderLoader() : this.renderLog()}
    </div>









      { this.state.isMoreLoading &&
      <Dimmer  style={{zIndex:99999}} disabled={!this.state.isMoreLoading} active inverted><Loader disabled={!this.state.isMoreLoading} size='small'/></Dimmer>

}

    </div>

  );
}
}
