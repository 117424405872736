import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem, Breadcrumb } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Properties.css";
import { API, Storage } from "aws-amplify";
import EnhancedTable from "./EnhancedTable";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';


import loaderIcon from'../images/qkeyz.gif';


import QKeyzLogo from "../images/qkeyz_logo.svg"; //full_logo_40.png
import QRCode from "qrcode.react";
import ReactToPrint from "react-to-print";



const rowHeaders = [
  { id: 'logo', numeric: false, center: true, disablePadding: true, label: '' },
  { id: 'title', numeric: false, center: false, disablePadding: true, label: 'Title' },
  { id: 'category', numeric: false, center: false, disablePadding: true, label: 'Category' },
  { id: 'desc', numeric: false, center: false, disablePadding: false, label: 'Description' },
  { id: 'contactname', numeric: false, center: false, disablePadding: true, label: 'Contact Name' },
  { id: 'contactnumber', numeric: false, center: false, disablePadding: true, label: 'Contact Phone' },
  { id: 'created', numeric: false, center: false, disablePadding: false, label: 'Created' },
  { id: 'updated', numeric: false, center: false, disablePadding: false, label: 'Updated' },
];

export default class Properties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: "normal",
      isLoading: true,
      isDeleting: false,
      alertOpen: false,
      properties: []
    };
  }
async componentDidMount() {
  //console.log(this.props);
  if (!this.props.isAuthenticated || !this.props.org) {
    this.props.history.push("/");
    return;
  }

  try {
    const result = await this.getOrgProperties();
    //console.log(rslt.extra);
    const properties = result.Items;
    //const properties = await this.getOrgProperties();
    const rows = await this.getStructuredPropertyList(properties);

/*
    var key = '';
    if (url) {
      let pos = url.indexOf('?AWSAccessKeyId');
      key = url.substring(pos);
    }
*/


    this.setState({ properties, rows });
  } catch (e) {
    alert(e);
  }

  this.setState({ isLoading: false });
}

async getStructuredPropertyList(properties) {
  var rows = [];
  for (var i = 0; i < properties.length; ++i) {
    let p = properties[i];
    const {binaryImage, title, category, categoryDesc, description, contactName, contactPhone, created, updated} = p;

    let name = '';
    if (contactName) {
      name = contactName;
    }

    let number = '';
    if (contactPhone) {
      number = contactPhone;
    }

    let desc = '';
    if (description) {
      desc = description;
    }
    let rowCols = [
      { id: 'logo', numeric: false, center: true, disablePadding: true, value: binaryImage, type: 'image'},
      { id: 'title', numeric: false, center: false, disablePadding: true, value: title, type: 'text' },
      { id: 'category', numeric: false, center: false, disablePadding: true, value: categoryDesc, type: 'text' },
      { id: 'desc', numeric: false, center: false, disablePadding: false, value: desc, type: 'text' },
      { id: 'contactname', numeric: false, center: false, disablePadding: true, value: name, type: 'text' },
      { id: 'contactnumber', numeric: false, center: false, disablePadding: true, value: number, type: 'text' },
      { id: 'created', numeric: false, center: false, disablePadding: false, value: (new Date(p.created)).toLocaleString(), type: 'date' },
      { id: 'updated', numeric: false, center: false, disablePadding: false, value: (new Date(p.updated)).toLocaleString(), type: 'date' },
    ];
    rows.push({id: i + 1, logo: binaryImage, title: title, category: categoryDesc, desc, contactname: name, contactnumber: number, created, updated, key: p.sk, cols: rowCols})
  }
  return rows
}


getOrgProperties() {
    return API.get("qkeyz", `/organisations/${this.props.org.pk}/PRP|NIL`); // ?ids=['KEY|aaa','KEY|bbb','KEY|ccc']
}
deleteProperties() {
  //console.log(this.state);
  const idList = this.state.selected;
  if (idList.length > 0) {
    const body = {ids: idList};
    console.log(body);
    return API.del("qkeyz", `/organisations/${this.props.org.pk}/PRP`, {body: body});
  }
}

handleCrumbClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}

getPropertyIdFromSk(sk) {
  return sk.substr(8);
}

 renderPropertiesList(properties) {
  return [{}].concat(properties).map(
    (property, i) =>
      i !== 0
        ? <ListGroupItem
            key={property.sk}
            href={`/properties/${this.getPropertyIdFromSk(property.sk)}`}
            onClick={this.handlePropertyClick}
            header={property.sk}
          >
            {"Created: " + new Date(property.created).toLocaleString()}
          </ListGroupItem>
        : <ListGroupItem
            key="new"
            href={"/properties/new"}
            onClick={this.handlePropertyClick}
          >
            <h4>
              <b>{"\uFF0B"}</b> New Property
            </h4>
          </ListGroupItem>
  );
}
handleAlertClose = () => {
    this.setState({ alertOpen: false });
};
handlePropertyClick = (event, propertySk) => {
  event.preventDefault();
  this.props.history.push({
    pathname: '/properties/property/keys',
    state: { propertyId: this.getPropertyIdFromSk(propertySk) }
  });
}


handleViewLogClick = (event, selected) => {
  event.preventDefault();
  this.props.history.push({
    pathname: '/properties/property/log',
    state: { propertyId: this.getPropertyIdFromSk(selected[0]) }
  });
}


handlePropertyEditClick = (event, selected) => {
  event.preventDefault();
  console.log(selected);
  this.props.history.push({
  pathname: '/properties/edit',
  state: { propertyId: this.getPropertyIdFromSk(selected[0]) }
  });
}
handleCreatePropertyClick = event => {
  event.preventDefault();
  this.props.history.push("/properties/new");
}
handleDeletePropertyClick = async (event, selected) => {
  event.preventDefault();
  let count = selected.length;
  if (count > 0) {
    this.setState({
      alertOpen: true,
      selected: selected
    });
  }
}
 handleDeleteProperties = async (event) => {
   console.log(this.state);
   console.log('LEN0: ', this.state.rows.length);
  this.setState({ isDeleting: true, alertOpen: false });
  console.log('aaa');
  let isSuccess = false
  try {
    const result = await this.deleteProperties();
    console.log(result);
    isSuccess = true;
  } catch (e) {
    alert(e);
  }

  console.log('ddd');
  const properties = await this.getOrgProperties();
  //console.log(rslt);
  //const properties = rslt.items;

  const rows = await this.getStructuredPropertyList(properties);
  console.log('LEN: ', rows.length);
  this.setState({ isDeleting: false, properties: properties, rows: rows });
}
handleCloseQRCodes= (event, selected) => {
  event.preventDefault();
  this.setState({
    mode: "normal"
  });
}
handleQRCodesClick = (event, selected) => {
  event.preventDefault();
  this.setState({
    mode: "qrcodes",
    selected
  });
}




/*
<MenuItem disabled={numSelected > 0} onClick={handleCreateItemClick}>Create new...</MenuItem>
<MenuItem disabled={numSelected != 1} onClick={event => handleEditItemClick(event, selected)}>Edit...</MenuItem>
<MenuItem disabled={numSelected == 0} onClick={event => actionsItemClickEvent(event, selected, handleDeleteItemClick)}>Delete</MenuItem>
*/


/*
async deleteProjects() {
  console.log('bbb');
  try {
    await this.deleteProjects();
  } catch (e) {
    alert(e);
  }
  console.log('ccc');

}
*/
renderLander() {
    return (
      <div className="lander">
        <h1>Properties</h1>
        <p>Create your first property here</p>
          <LoaderButton
            bsStyle="primary"
            bsSize="large"
            isLoading={this.state.isLoading}
            onClick={this.handleCreatePropertyClick}
            text="Create Property"
            loadingText="Create property"
          />


      </div>
    );
}


/*

      <div className="items">
        <PageHeader>{this.props.org ? this.props.org.orgName + ':' : 'My'} Projects</PageHeader>

*/
/*

handleEditItemClick={this.handleProjectEditClick}
handleCreateItemClick={this.handleCreateProjectClick}
handleDeleteItemClick={this.handleDeleteProjectClick}



*/

renderProperties() {
  const menuItems = [
    { id: 0, label: 'Create new...', lt: Number.MAX_VALUE, gt: 0, isOrOperator: false, action: this.handleCreatePropertyClick, divider: false, type: 'create'},
    { id: 1, label: 'Edit...', lt: 1, gt: 1, isOrOperator: true, action: this.handlePropertyEditClick, divider: false, type: 'edit' },
    { id: 2, label: 'Delete', lt: 1, gt: -1, isOrOperator: false, action: this.handleDeletePropertyClick, divider: true, type: 'delete' },
    { id: 3, label: 'View Log...', lt: 1, gt: 1, isOrOperator: true, action: this.handleViewLogClick, divider: true, type: 'log' },
    { id: 4, label: 'QRCodes...', lt: 1, gt: -1, isOrOperator: false, action: this.handleQRCodesClick, divider: false, type: 'qrcodes' }
  ];
    return (
        <div>
          {!this.state.isLoading && <EnhancedTable
                                        logosKey={this.state.key}
                                        title='Properties'
                                        tooltip=''
                                        items={this.state.rows}
                                        menuItems={menuItems}
                                        handleItemClick={this.handlePropertyClick}
                                        rowHeaders={rowHeaders}
                                        orderBy='title'
                                        order='asc'
                                        />}
          </div>
    );
  }

  renderLoader() {
    const label = this.state.isDeleting ? 'deleting...' : 'loading...';
    return(

      <div id="splash" style={{paddingTop: 250, zIndex:999}}>

    		<center id="splash">
    			<img src={loaderIcon}/>
          <Typography style={{padding: '4px'}} variant="caption">{label}</Typography>
    		</center>
    	</div>



    )
  }

  renderQRCodes() {

    console.log(this.state.selected);
    console.log(this.state.rows);
    var selectedRows = [];
    //var cols = [];
    for (var i2 = 0; i2 < this.state.selected.length; ++i2) {

      for (var i = 0; i < this.state.rows.length; ++i) {
        let rowKeyId = this.state.rows[i].key;
        if (rowKeyId === this.state.selected[i2]) {
          //cols.push(this.state.rows[i]);
          selectedRows.push(this.state.rows[i]);
          //if (cols.length == 2) {
          //  selectedRows.push(cols);
          //  cols = [];
          //}
          break;
        }
      }
    }

//console.log(cols);

    //if (cols.length > 0) {
    //  cols.push({id: 999, code: 'blank', locus: 'blank', created: null, updated: null, signedout: false, key: '', cols: null});
    //  selectedRows.push(cols);
    //}


    return(<div padding="16px" style={{marginTop: 40}}

  >

    <ReactToPrint
       trigger={() => <Button color="primary" autoFocus>
         {'Print'}
       </Button>}
       content={() => this.componentRef}
     />
     <Button onClick={this.handleCloseQRCodes} color="secondary" autoFocus>
       {'Close'}
     </Button>
     <ComponentToPrint properties={selectedRows} org={this.props.org} ref={el => (this.componentRef = el)} />
  </div>
  )

  }

  render() {
    //{this.state.isLoading || this.state.isDeleting ? this.renderLoader() : this.state.properties.length > 0 ? this.renderProperties() : this.renderLander()}
    return (
    <div>
        <Breadcrumb className="breadcrumb" style={{top: 58, left: 66, right: 0, position: 'absolute', zIndex:999, backgroundColor: '#FFF'}}>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Properties</Breadcrumb.Item>
        </Breadcrumb>

      <div className="Properties" style={{position: 'absolute', left:80, right:80,top: 80, paddingBottom:50}}>
        {this.state.isLoading || this.state.isDeleting || this.state.isSaving ? this.renderLoader() : this.state.mode === 'qrcodes' ? this.renderQRCodes() : this.state.properties.length > 0 ? this.renderProperties() : this.renderLander()}
      </div>

      <Dialog
          open={this.state.alertOpen}
          onClose={this.handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Properties"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you wish to delete the selected properties? <br/><br/>This cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAlertClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.handleDeleteProperties} color="primary" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}



class ComponentToPrint extends React.Component {
  render() {
  return (
    <div className="qrcodes">

      <center><h1>{this.props.org.orgName} - Property QRCodes</h1></center>
      <p>&nbsp;</p>
      <table className="a">
      {this.props.properties.map(property => {
        let qrCode0 = "p|" + this.props.org.pk + "|" + property.key.substr(8);
          return (
            <tbody key={property.key}><tr><td>&nbsp;</td></tr><tr><td className="x">
              <table className="b">
                <tbody><tr>
                  <td><center><p className="f"><b>{property.title}</b></p></center></td>
                  </tr></tbody>
                  <tbody><tr>
                    <td className="y"><center><span><center><QRCode renderAs="svg" size={600} value={qrCode0} /></center></span></center></td>
                    </tr></tbody>
                    <tbody><tr>
                      <td align="right"  ><img className="uuu" src={QKeyzLogo}/></td>
                      </tr></tbody>
              </table>
             </td>
             </tr></tbody>
        )
}   )}
  </table>
      </div>
    );
  }
}
