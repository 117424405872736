import uuid from "uuid";
import { Storage } from "aws-amplify";

export async function s3Upload(file) {
  var ext = "";
  var lastIndex = file.name.lastIndexOf(".");
  if (lastIndex > 0) {
    ext = file.name.substr(lastIndex);
  }
  const filename = `${uuid.v1()}${ext}`





  const stored = await Storage.put(filename, file, {
    contentType: file.type
  });

  return stored.key;
}
export async function s3UploadBlob(orgId, file, blob, folderName) {
  var ext = "";
  var lastIndex = file.name.lastIndexOf(".");
  if (lastIndex > 0) {
    ext = file.name.substr(lastIndex);
  }
  const folder = '/' + folderName + '/';
  // '/icons/'
  const filename = `${uuid.v1()}${ext}`;
  const fullPath = `${orgId}${folder}${filename}`;
  const stored = await Storage.put(fullPath, blob, {
    contentType: file.type, ACL: 'public-read'
  });
  return stored.key;
}
export async function s3UploadBlobAsPng(orgId, blob, folderName) {
  const ext = ".png";
  const folder = '/' + folderName + '/';
  const filename = `${uuid.v1()}${ext}`
  const fullPath = `${orgId}${folder}${filename}`
  const stored = await Storage.put(fullPath, blob, {
    contentType: 'image/png', ACL: 'public-read'
  });
  return stored.key;
}
export async function s3UploadBlobAsJpeg(orgId, blob, folderName) {
  const ext = ".jpg";
  const folder = '/' + folderName + '/';
  const filename = `${uuid.v1()}${ext}`
  const fullPath = `${orgId}${folder}${filename}`
  const stored = await Storage.put(fullPath, blob, {
    contentType: 'image/jpeg', ACL: 'public-read'
  });
  return stored.key;
}
