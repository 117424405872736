import React, { Component } from "react";
import "./Signup.css";

export default class Privacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }


  render() {
    return (
      <div className="Signup">
      <center><h1>Privacy Policy</h1></center>
<p>
<br/>
QKeyz.com is committed to protect the privacy of its users while providing means for convenient exchange of information.
<br/>
<br/>

QKeyz.com asks its users to enter their email address and name when creating an account within the website or mobile application. The email address is then used to initially authenticate the user and, if required, restore access when the password is forgotten. Secure authentication, authorisation and user management including password encryption and storage is handled by the Amazon Web Services Cognito service. SSL (secure Sockets Layer) is used to encrypt all communications between the users device (browser and /or mobile application) and the servers.
<br/>
<br/>

Once registered, users can then be subscribed to 3rd party organisations as visitors or employees. The 3rd party organisation that the user is subscribed to may optionally store extra personal details that the user submits to them eg address, phone number and photo. Only the 3rd party organisation that the user is subscribed to as an employee or visitor has access to those personal details they have collated and stored, they are not publicly viewable.
<br/>
<br/>

Users subscribed to a 3rd party organisation have their interactions with the organisation stored on the server, for example; key sign-out/sign-in events, submission of observations (including optional voice recordings and photos). Only the 3rd party organisation that the user is subscribed to as an employee or visitor has access to this data, it is not publicly viewable.
<br/>
<br/>

The 3rd party organisations that the users are subscribed to have their own privacy policies with regards to the data they collect and store for the users.
<br/>
<br/>

QKeyz.com uses cookies to allow its visitors access to their accounts without typing the password each time.
<br/>
<br/>


QKeyz.com will not share users personal details with any 3rd parties except where the user is subscribed to a 3rd party organisation as a visitor or employee of that organisation by mutual agreement. The details shared comprise only the users email address and name.
<br/>
<br/>

QKeyz.com does not host any advertising. Pages of QKeyz.com website and mobile applications contain analytics tools hosted by Google Analytics which has its own privacy policies with regard to utilizing its cookies and tracking user behavior.

</p>
      </div>
    );
  }

}
