import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel, ListGroup, ListGroupItem, Breadcrumb } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./Organisation.css";
import { s3Upload } from "../libs/awsLib";

export default class OrgUser extends Component {
  constructor(props) {
    super(props);

    this.file = null;
    this.state = {
      isLoading: null,
      isDeleting: null,
      org: null,
      orgUsers: [],
      name: "",
      attachmentURL: null,
      category: "",
      category_short: "",
      categories: [{value: 'UNI', title: 'University'},
        {value: 'COM', title: 'Commercial'},
        {value: 'NGO', title: 'Non-Government Organisation'},
        {value: 'CHA', title: 'Charity'},
        {value: 'GOV', title: 'Government'}
      ]

    };
  }

  async componentDidMount() {
  if (!this.props.isAuthenticated) {
    return;
  }
    const user = this.props.user;
    const { category } = user;
    if (category != 'SUPER') {
      this.props.history.push("/account");
      return;
    }

    try {
      let attachmentURL;
      const org = await this.getOrg();
      const { orgName, logo, category, categoryDesc } = org;

      if (logo) {
        attachmentURL = await Storage.get(logo);
      }

      this.setState({
        org,
        name: orgName,
        attachmentURL,
        category_short: category,
        category: categoryDesc
      });
    } catch (e) {
      alert(e);
    }
  }

  getOrg() {
    return API.get("qkeyz", `/organisations/${this.props.match.params.id}`);
  }

validateForm() {
  return this.state.name.length > 0;
}

formatFilename(str) {
  return str.replace(/^\w+-/, "");
}

handleChange = event => {
  this.setState({
    [event.target.id]: event.target.value
  });
}

handleFileChange = event => {
  this.file = event.target.files[0];
}

saveOrg(org) {
  org.userName = this.props.user.firstName + " " + this.props.user.lastName;
  return API.put("qkeyz", `/organisations/${this.props.match.params.id}`, {
    body: org
  });
}

handleSubmit = async event => {
  let attachment;

  event.preventDefault();

  if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
    alert("Please pick a file smaller than 5MB");
    return;
  }

  this.setState({ isLoading: true });

  try {
    if (this.file) {
      attachment = await s3Upload(this.file);
    }

    await this.saveOrg({
      orgName: this.state.name,
      logo: attachment || this.state.org.logo,
      category: this.state.category_short,
      categoryDesc: this.state.category
    });
    this.props.history.push("/organisations");
  } catch (e) {
    alert(e);
    this.setState({ isLoading: false });
  }
}

deleteNote() {
  return API.del("qkeyz", `/notes/${this.props.match.params.id}`);
}

handleDelete = async event => {
  event.preventDefault();

  const confirmed = window.confirm(
    "Are you sure you want to delete this note?"
  );

  if (!confirmed) {
    return;
  }

  this.setState({ isDeleting: true });

  try {
    await this.deleteNote();
    this.props.history.push("/");
  } catch (e) {
    alert(e);
    this.setState({ isDeleting: false });
  }
}

handleBuilderClick = event => {
  event.preventDefault();
  sessionStorage.setItem('schema_id', this.state.note.noteId);
  this.props.history.push("/builder");
}
handleCrumbClick = event => {
  event.preventDefault();
  this.props.history.push(event.currentTarget.getAttribute("href"));
}
renderOrgUsersList(orgUsers) {
  return [{}].concat(orgUsers).map(
    (orgUser, i) =>
      i !== 0
        ? <ListGroupItem
            key={orgUser.pk}
            href={`/organisations/users/${orgUser.pk}`}
            onClick={this.handleOrgUserClick}
            header={orgUser.firstName + ' ' + orgUser.lastName}
          >
            {orgUser.role + "   Created: " + new Date(orgUser.created).toLocaleString()}
          </ListGroupItem>
        : <ListGroupItem
            key="new"
            href={"/organisations/users/new"}
            onClick={this.handleOrgUserClick}
          >
            <h4>
              <b>{"\uFF0B"}</b> Add User
            </h4>
          </ListGroupItem>
  );
}
render() {
  return (
    <div className="Organisation">
         <Breadcrumb>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/account">Account</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} href="/organisations">Organisations</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.handleCrumbClick} >{this.state.name}</Breadcrumb.Item>
          <Breadcrumb.Item active>Users</Breadcrumb.Item>
        </Breadcrumb>
     {this.state.org &&
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="name">
          <ControlLabel>Organisation Name</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.name}
              componentClass="input"
              type="text"
            />
          </FormGroup>
          <FormGroup controlId="category_short">
          <ControlLabel>Category</ControlLabel>
            <FormControl
              componentClass="select"
              placeholder="category"
              onChange={this.handleChange}
              value={this.state.category_short}
              >
                {
                  this.state.categories.map((cat, index) => {
                    return (<option key={index} value={cat.value}>{cat.title}</option>)
                  })
                }

            </FormControl>
            </FormGroup>
          {this.state.org.logo &&
            <FormGroup>
              <ControlLabel>Logo</ControlLabel>
              <FormControl.Static>
                <img src={this.state.attachmentURL} alt="Organisation Logo"/>
              </FormControl.Static>
            </FormGroup>
          }
          <FormGroup controlId="file">
            {!this.state.org.logo &&
              <ControlLabel>Attachment</ControlLabel>}
            <FormControl onChange={this.handleFileChange} type="file" />
          </FormGroup>
          <hr/>




          <hr/>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Save"
            loadingText="Saving…"
          />
          <LoaderButton
            block
            disabled="true"
            bsStyle="danger"
            bsSize="large"
            isLoading={this.state.isDeleting}
            onClick={this.handleDelete}
            text="Delete"
            loadingText="Deleting…"
          />

        </form>


      }
    </div>
  );
}
}
